import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 2',
    id: 'SB7-U2-P21-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page25/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: '<b>Match questions 1–4 with answers a–d. Then choose the correct words in rules 1–2.</b>',
        color: '#1dbfd7',
      },
    ],
    question: {
      Write: {
        underlineStyle: { border: '1px solid' },
        inputStyle: {
          width: 39,
          textAlign: 'center',
          fontSize: 26,
          color: 'black',
          maxLength: 1,
        },
        answers: ['d', 'c', 'b', 'a'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          fontStyle: 'italic',
          fontWeight: 'bold',
          fontSize: 30,
        },
        selectWordStyle: {
          padding: 3,
          border: 'solid 2px',
          borderColor: '#1dbfd7',
        },
        limitSelect: 1,
        listWords: [
          'be / do / have.', //0
          'be / do / have.', //1
        ],
        answers: ['0-0', '1-0'],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div style="position: relative; font-size: 26px;">
          <div style='margin-left: 20px; position: absolute; top: 0px; left: 0px; width: 550px; z-index: 1;'>
            <div><b>1</b> Are the boys watching TV? <input id='0' /></div>
            <div><b>2</b> What are they chatting about? <input id='1' /></div>
            <div><b>3</b> Is Becky answering questions? <input id='2' /></div>
            <div><b>4</b> SWhat’s she doing with her mum? <input id='3' /></div>

            <div><b>a.</b> She’s cooking.</div>
            <div><b>b.</b> Yes, she is.</div>
            <div><b>c.</b> They’re chatting about sport.</div>
            <div><b>d.</b> No, they aren’t.</div>
          </div>
          <div style="position: absolute; top: 100px; left: 0px;"> <img src='img/FriendsPlus/Page25/E1/1.jpg' /> </div>
          <div style="position: absolute; top: 445px; left: 245px; width: 250px;"><input id='0' type='Circle' /></div>
          <div style="position: absolute; top: 520px; left: 340px; width: 250px;"><input id='1' type='Circle' /></div> 
        </div>
      `,
    },
  },
  2: {
    unit: 'Unit 2',
    id: 'SB7-U2-P21-E2',
    audio: 'Audios/1-21 Friends Plus 7.mp3',
    video: '',
    hideBtnFooter: true,
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page25/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title:
          "<img src='img/FriendsPlus/Page25/E2/1.jpg' style='margin-bottom: 5px;' /> <span style='color: #1dbfd7;'><b>PRONUNCIATION</b> Diphthongs</span> Listen. Choose the words whose underlined part is pronounced differently from that of the others.",
        color: '#1dbfd7',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          marginRight: 25,
          borderRadius: '50%',
          fontSize: 24,
        },
        selectWordStyle: {
          padding: 3,
          border: 'solid 2px',
          borderColor: '#1dbfd7',
        },
        limitSelect: 1,
        listWords: [
          'br<u>ea</u>k st<u>ea</u>k gr<u>ea</u>t p<u>ea</u>r', //0
          's<u>ou</u>nd sh<u>ou</u>t gr<u>ou</u>nd sh<u>ou</u>lder', //1
          'b<u>ea</u>r cl<u>ea</u>r h<u>ea</u>r n<u>ea</u>r', //2
          'ch<u>oi</u>r ch<u>oi</u>ce n<u>oi</u>se <u>oi</u>l', //3
          'h<u>ai</u>r f<u>ai</u>l l<u>ai</u>r f<u>ai</u>r', //4
        ],
        answers: [],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div><b>1</b> <input id='0' type='Circle' /></div>
        <div><b>2</b> <input id='1' type='Circle' /></div>
        <div><b>3</b> <input id='2' type='Circle' /></div>
        <div><b>4</b> <input id='3' type='Circle' /></div>
        <div><b>5</b> <input id='4' type='Circle' /></div>
      `,
    },
  },
  3: {
    unit: 'Unit 2',
    id: 'SB7-U2-P21-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 150,
    textAlign: 'center',
    hintBox: [
      {
        src: ['hardly any', 'most', 'much less', 'much more'],
        borderColor: '#1567a1',
        width: 400,
      },
    ],
    titleQuestion: [
      {
        num: '3',
        title: 'Read the fact file. Complete the statements about communication habits with the words in the box.',
        color: '#1567a1',
      },
    ],
    stylesTextInput: {
      borderBottom: 'dotted 1px',
    },
    maxlength: 10,
    exerciseKey: 'img/FriendsPlus/Page21/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div>
              <div style="margin-left: 80px"><hintBox id='0'></hintBox></div>
              <img src='img/FriendsPlus/Page21/E3/1.jpg'>
            </div>
            <div style='margin-top: 50px; width: 14cm'>
              <div style='margin-bottom: 20px;'><b>1</b>&emsp;Adults spend#time using email to communicate than young people.</div>
              <div style='margin-bottom: 20px;'><b>2</b>&emsp;Compared to adults, young people spend#communication time talking on the phone.</div>
              <div style='margin-bottom: 20px;'><b>3</b>&emsp;Young people spend#communication time on emails.</div>
              <div><b>4</b>&emsp;Young people spend#of their communication time on social media.</div>
            </div>
          </div>
          `,
        answer: ['much more', 'much less', 'hardly any', 'most'],
      },
    ],
  },
  4: {
    unit: 'Unit 2',
    id: 'SB7-U2-P21-E4',
    video: 'https://cdn.sachso.edu.vn/videos/7sb/Talking Heads/U2-1.15-Trang 21.mp4',
    audio: 'Audios/1-15 Friends Plus 7.mp3',
    component: UI,
    titleQuestion: [
      {
        num: '4',
        title:
          "<videoimage></videoimage><audioimage name='1.15'></audioimage> Watch or listen. Who meets their friends the most: Rebecca and Harry, or Steve?",
        color: '#1567a1',
        // prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    recorder: true,

    questionImage: [],
  },
  5: {
    unit: 'Unit 2',
    id: 'SB7-U2-P21-E5',
    audio: 'Audios/1-15 Friends Plus 7.mp3',
    video: 'https://cdn.sachso.edu.vn/videos/7sb/Talking Heads/U2-1.15-Trang 21.mp4',
    component: T6,
    inputSize: 40,
    isHiddenCheck: true,
    stylesTextInput: {
      border: '2px solid',
      borderBottom: 'dash',
      height: 35,
      // fontSize: 30,
    },
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page21/E5/Key/answerKey.png',
    titleImage: '',
    questionImage: [],
    titleQuestion: [
      {
        num: '5',
        title:
          "<videoimage></videoimage><audioimage name='1.15'></audioimage> Watch or listen again and put the key phrases in the order you hear them. Which phrases do you <u>not</u> hear?",
        color: '#1567a1',
        // prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    maxLength: 1,
    questions: [
      {
        title: `
          <b style='color:white;border-radius:15px;background:rgb(108, 157, 211);padding:10px 451px 10px 10px; font-size: 27px'>KEY PHRASES</b>
          <div style='background: rgb(214, 226, 242); border-radius: 15px; padding: 10px;margin:-10px 0px'>
          <b style='color: rgb(68, 139, 202)'>Expressing likes and preferences</b>
            <div style='line-height:45px; display: flex'>
                <div> 
                  <b>a.</b> What about you? <span style='margin-left:15px'>#</span><br>
                  <b>b.</b> Of course. <span style='margin-left:84px'>#</span><br>
                  <b>c.</b> Of course not. <span style='margin-left:45px'>#</span><br>
                  <b>d.</b> Really? <span style='margin-left:114px'>#</span><br>
                </div>
                <div style='margin-left: 20px;'> 
                  <b>e.</b> Not really <span style='margin-left:102px'>#</span><br>
                  <b>f.</b> I’m suprised! <span style='margin-left:73px'>#</span><br>
                  <b>g.</b> Me, too. <span style='margin-left:116px'>#</span><br>
                  <b>h.</b> I’m not surprised. <span style='margin-left:19px'>#</span><br>
                  <b>i.</b> Neither am I. <span style='margin-left:74px'>#</span><br>
                </div>
            </div>
        </div>
        `,
        answer: ['1', '1', '1', '2', '4', '3', '4', '2', '2'],
      },
    ],
  },
  6: {
    unit: 'Unit 2',
    id: 'SB7-U2-P21-E6',
    video: '',
    audio: '',
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: ' Work in pairs. Take turns discussing the fact file. Use the key phrases and questions below.',
        color: '#1567a1',
        prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
      },
    ],
    recorder: true,

    questionImage: [[{ url: '/img/FriendsPlus/Page21/E6/1.jpg' }]],
  },
  7: {
    unit: 'Unit 2',
    id: 'SB7-U2-P21-E7',
    audio: '',
    video: '',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700 },
    hideBtnFooter: true,
    finished: {
      text: 'Write a paragraph describing the time you and your family spend communicating.',
    },
    questions: [
      {
        title: `
        <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
