import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Reading',
		id: 'SB7-R-P94-E1',
		exerciseKey: 'img/FriendsPlus/Page94/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '1',
				title: `
                    Look at the pictures, then read the text quickly. 
                    What is the main idea? Circle one of the options below.
                `,
				color: '#d72e3b',
			},
		],

		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					borderColor: '#00aeef',
				},
				limitSelect: 1,
				listWords: ['A_Fossil_fuels <br/> B_Green_sources_of_energy'],
				answers: ['0-4'],
				initialValue: [],
			},
			Layout: `
                <div style="width: 600px; margin-top: 20px;">
                    <div style="display: flex; width: 400px">
                        <img src="img/FriendsPlus/Page94/E1/1.jpg" style="max-width: 100%;" />
                        <img src="img/FriendsPlus/Page94/E1/2.jpg" style="max-width: 100%;" />
                    </div>        
                    
                    <div style="margin-top: 20px;"><input id='0' type='Circle' />    </div>
                </div>
          `,
		},
	},

	2: {
		unit: 'Reading',
		id: 'SB7-R-P94-E2',
		exerciseKey: 'img/FriendsPlus/Page94/E2/Key/answerKey.png',
		audio: 'Audios/2-26 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 130,
		maxLength: 9,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
                    <audioimage name='2.26'></audioimage>
                    Listen and read the text. Complete each sentence with ONE word.
                `,
				color: '#d72e3b',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
                        <ol style="margin-top: 20px;">
                            <li>
                                # fuels are limited, and they cause pollution.
                            </li>
                            <li>
                                Solar energy is # because the sun never stops producing sunlight.
                            </li>
                            <li>
                                They install giant wind # to get much more power all at once.
                            </li>
                            <li>
                                Our planet will be # if we stop using fossil fuels.
                            </li>
                            <li>
                                There is no # to solar energy and wind power.
                            </li>
                        </ol>
					</div>
                `,
				answer: ['Fossil', 'renewable', 'turbines', 'greener', 'limit'],
			},
		],
	},

	3: {
		unit: 'Reading',
		id: 'SB7-R-P94-E3',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 120,
		maxLength: 8,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '3',
				title: `
                    <span style="color: #d72e3b;">VOCABULARY PLUS</span> Use a dictionary to check the meaning 
                    of the words in <span style="color: #29b2df">blue</span> in the text.
                `,
				color: '#d72e3b',
			},
		],

		questions: [
			{
				title: `
                <div style="width: 600px; margin-top: 20px;">
                    <div style="display: flex; width: 400px">
                        <img src="img/FriendsPlus/Page94/E1/1.jpg" style="max-width: 100%;" />
                        <img src="img/FriendsPlus/Page94/E1/2.jpg" style="max-width: 100%;" />
                    </div>     
                </div>
                `,
				answer: [],
			},
		],
	},

	4: {
		unit: 'Reading',
		id: 'SB7-R-P94-E4',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 620 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
                    Work in groups. Ask and answer the questions. 
                    Which do you prefer, solar energy or wind power? Why / Why not?
                `,
				color: '#d72e3b',
				prefix: 'default',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; width: 600px;">
						<div style="margin-top: 30px;">
							<textarea id="8" rows="10"></textarea>
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
