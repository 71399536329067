// TEMPLATE
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	6: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P103-E6',
		exerciseKey: 'img/FriendsPlus/Page103/E6/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 300,
		maxLength: 28,

		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '6',
				title: `
                    Write questions with <i>be going to.</i>
                `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <ol>
                            <li>'# (you / play) basketball after school?'</li>
                            'Yes, I am. We've got a match.'
                            <li>'# (your brother / get) tickets for that hip-hop band?'</li>
                            'No, he isn't.'
                            <li>'# (we / listen to) that song again?''</li>
                            'Yes, we are. It's fantastic!'
                            <li>'# (Emily / sell) her violin?'</li>
                            'Yes, she is. She doesn't play it now.'
                            <li>'What book # (you / read) this summer?'</li>
                            'The Hunger Games.'
                            <li>Where '# (Anya / stay) on holiday?'</li>
                            'In a nice hotel in Turkey.'
                        <ol>
                    </div>
                `,
				answer: [
					'Are you going to play',
					'Is your brother going to get',
					'Are we going to listen to',
					'Is Emily going to sell',
					'are you going to read',
					'is Anya going to stay',
				],
			},
		],
	},

	7: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P103-E7',
		exerciseKey: 'img/FriendsPlus/Page103/E7/Key/answerKey.png',

		audio: '',
		video: '',

		component: T6,
		inputSize: 240,
		maxLength: 16,

		stylesTextInput: {
			fontSize: 23,
		},

		titleQuestion: [
			{
				num: '7',
				title: `
                    Complete the dialogue about future
                    arrangements using the present continuous.
                `,
				color: '#4c3d9b',
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 600px;">
						<div>
							<div style="display: flex;">
								<span style="margin-right: 51px">Betty</span>
								<span>What <sup>1</sup># (you / do) this weekend?</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 19px">Caroline</span>
								<span><sup>2</sup># (I / not do) anything special. What about you?</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 51px">Betty</span>
								<span><sup>3</sup># (I / travel) to London to see a show with my cousins. <br/><sup>4</sup># (my mum / come), too.</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 19px">Caroline</span>
								<span>Where <sup>5</sup># (you / meet) them?</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 51px">Betty</span>
								<span>Outside the theatre. <sup>6</sup># (we / spend) the evening with them and <br/><sup>7</sup># (we / have) dinner together after the show. Then <br/><sup>8</sup># (they / drive) us home later.</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 19px">Caroline</span>
								<span>That sounds great.</span>
							</div>
						</div>

						<div style="margin-top: 20px; display: flex; justify-content: center;"><img src="img/FriendsPlus/Page103/E7/1.jpg" style="max-width: 100%;" /></div>
                    </div>
                `,
				answer: [
					'are you doing',
					"I'm not doing",
					"I'm travelling",
					'My mum is coming',
					'are you meeting',
					"We're spending",
					"we're having",
					"they're driving",
				],
			},
		],
	},

	8: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P103-E8',
		exerciseKey: 'img/FriendsPlus/Page103/E8/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 350,
		maxLength: 31,
		stylesTextInput: {
			fontSize: 23,
		},
		hintBox: [
			{
				src: [
					'we need help with',
					'What are you doing?',
					'Do you want me to do that?',
					"There's going to be",
					'I can do that.',
					'Can I do anything else to help?',
				],
				borderColor: '#4c3d9b',
				width: 525,
			},
		],
		titleQuestion: [
			{
				num: '8',
				title: `
					Complete the dialogue then practise with your partner.
                `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px;">
						<div style="display: flex; justify-content: center;"><hintbox id='0'></hintbox></div>

						<div>
							<div style="margin-top: 5px; display: flex;">
								<span style="margin-right: 18px">Jack</span>
								<span>Hello, Lily.</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 32px">Lily</span>
								<span>Hi, Jack. You look very busy. <br/><sup>1</sup>#</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 18px">Jack</span>
								<span><sup>2</sup># a concert at school and I'm helping with it. There's a lot of work. I've got all these tickets to sell.</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 32px">Lily</span>
								<span><sup>3</sup>#</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 18px">Jack</span>
								<span>That would be great.</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 32px">Lily</span>
								<span><sup>4</sup>#</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 18px">Jack</span>
								<span>Well, <sup>5</sup># the posters. If I give you some posters, can you put them up?</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 32px">Lily</span>
								<span>Sure, no problem. <br/><sup>6</sup>#</span>
							</div>
						</div>
						
						<div style="margin-top: 20px; display: flex; justify-content: center;"><img src="img/FriendsPlus/Page103/E8/1.jpg" style="max-width: 100%;" /></div>
                    </div>
                `,
				answer: [
					'What are you doing?',
					"There's going to be",
					'Do you want me to do that?',
					'Can I do anything else to help?',
					'we need help with',
					'I can do that.',
				],
			},
		],
	},

	// TODO: How to break down the input '#' tag?
	9: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P103-E9',
		exerciseKey: 'img/FriendsPlus/Page103/E9/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 950,
		maxLength: 97,
		stylesTextInput: {
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '9',
				title: `
					Order the words to make sentences.
                `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
            <div style="width: 600px;">
						<div style="background-color: rgba(232, 230, 243, 1); padding: 10px; padding-left: 30px; border-radius: 10px; font-size: 22px">
							<strong>Title / Band: 'Rather Be' by Clean Bandit (feat. Jess Glynn)</strong>
							<ol>
								<li>you're / If / catchy / into / pop music, you'll / song. / love / this</li>
								<li>you / like / If / it, / you'll / dance to / want / to / it! / definitely</li>
								<li>are / The / all / love. / lyrics / about</li>
								<li>The / amazing voice. / song / Jess Glynn's / off with / starts</li>
								<li>because / It / includes / doesn't sound / it / other pop music / classical music, / too. / the same as</li>
								<li>for some people / doesn't work / the fusion of / I think / classical and pop music, but / really / it. / like / I</li>
							</ol>
						</div>
						<ol style="margin-top: 10px">
							<li>#</li>
							<li>#</li>
							<li>#</li>
							<li>#</li>
							<li>#</li>
							<li>#</li>
						</ol>
                    </div>
                `,
				answer: [
					"If you're into catchy pop music, you'll love this song.",
					"If you like it, you'll definitely want to dance to it!",
					'The lyrics are all about love.',
					"The song starts off with Jess Glynn's amazing voice.",
					"It doesn't sound the same as other pop or dance music because it includes classical music, too.",
					"I think for some people the fusion of classical and pop music doesn't work, but I really like it.",
				],
			},
		],
	},
};

export default json;
