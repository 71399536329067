import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  3: {
    unit: 'Unit 1',
    id: 'SB7-U1-P11-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page11/E3/Key/answerKey.png',
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title:
          "Complete the <i>Time of your life</i> quiz with words from exercise 1. Then choose the correct option in <span style='color: #00acef;'>blue</span> and compare your answers.",
        color: '#0269b4',
      },
    ],
    question: {
      Write: {
        inputStyle: { borderBottom: 'none', fontSize: 18, width: 120 },
        answers: [
          'in bed',
          'in the car',
          'at school',
          'in the playground',
          'in fast-food restaurants',
          'at the shops',
          'in front of the TV',
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#00aeef',
          fontSize: 18,
        },
        selectWordStyle: {
          padding: 3,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['5-6 / 7-9 / 10-11', '5 / 20/ 40', '5 / 6 / 7', '7 / 12 / 14', '17 / 67 / 127', '50 / 100 / 150'],
        answers: ['0-4', '1-6', '2-8', '3-8', '4-4', '5-0'],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative;'>
          <div><img src='img/FriendsPlus/Page11/E3/1.jpg' /></div>
          
          <div style='position: absolute; top: 207px; left: 25px;'><input id='0' type='Circle' /></div>
          <div style='position: absolute; top: 224px; left: 31px;'><input id='0' /></div>
          
          <div style='position: absolute; top: 709px; left: 120px;'><input id='1' type='Circle' /></div>
          <div style='position: absolute; top: 727px; left: 32px;'><input id='1' /></div>
          
          <div style='position: absolute; top: 177px; left: 313px;'><input id='2' /></div>
          <div style='position: absolute; top: 280px; left: 300px;'><input id='2' type='Circle' /></div>
          
          <div style='position: absolute; top: 320px; left: 391px;'><input id='3' width='150px' /></div>
          <div style='position: absolute; top: 375px; left: 360px;'><input id='3' type='Circle' /></div>
         
          <div style='position: absolute; top: 564px; left: 445px;'><input id='4' type='Circle' /></div>
          <div style='position: absolute; top: 653px; left: 356px;'><input id='4' width='195px' /></div>
          
          <div style='position: absolute; top: 367px; left: 783px;'><input id='5' /></div>
          
          <div style='position: absolute; top: 645px; left: 852px;'><input id='6' width='150px' /></div>
          <div style='position: absolute; top: 724px; left: 796px;'><input id='5' type='Circle' /></div>
        </div>
      `,
    },
  },
  4: {
    unit: 'Unit 1',
    id: 'SB7-U1-P11-E4',
    audio: 'Audios/1-05 Friends Plus 7.wav',
    video: 'https://cdn.sachso.edu.vn/videos/7sb/Talking Heads/U1-1.05-Trang 11.mp4',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page11/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title:
          '<videoimage></videoimage><audioimage name="1.05"></audioimage> Watch or listen. Who likes seeing friends at the weekend: Amelia, Elijah, or Renee? Who goes to school by bus: Harry, Lily, or Darius?',
        color: '#0066bf',
      },
    ],
    inputSize: 370,
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div>Who likes seeing friends at the weekend: Amelia, Elijah, or Renee? #</div>
          <div>Who goes to school by bus: Harry, Lily, or Darius? #</div>
        `,
        answer: ['Renee likes seeing friends.', 'Darius goes to school by bus.'],
      },
    ],
  },
  5: {
    unit: 'Unit 1',
    id: 'SB7-U1-P11-E5',
    audio: 'Audios/1-05 Friends Plus 7.wav',
    video: 'https://cdn.sachso.edu.vn/videos/7sb/Talking Heads/U1-1.05-Trang 11.mp4',
    exerciseKey: 'img/FriendsPlus/Page11/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title:
          '<videoimage></videoimage><audioimage name="1.05"></audioimage> Read the key phrases. Watch or listen again and complete them.',
        color: '#0066bf',
      },
    ],
    component: T6,
    inputSize: 300,
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='background-color: #d8e0f3; margin-top: 20px;'>
            <div><img src='img/FriendsPlus/Page11/E5/1.jpg' /></div>
            <div>I spend all of my time <sup>1</sup> #.</div>
            <div>I spend too much time <sup>2</sup> #.</div>
            <div>We spend most of our time <sup>3</sup> #.</div>
            <div>I spend a bit of time <sup>4</sup> #.</div>
            <div>I don’t spend a lot of time <sup>5</sup> #.</div>
            <div>Do you spend much time <sup>6</sup> #?</div>
            <div>I don’t spend more than <sup>7</sup> #.</div>
            <div>I don’t spend any time <sup>8</sup> #.</div>
          </div>
        `,
        answer: [
          'on my phone',
          'in front of screens',
          'at the shops',
          'in my room',
          'in bed',
          'on your homework',
          'an hour',
          'in bed',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 1',
    id: 'SB7-U1-P11-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page11/E6/Key/answerKey.png',
    textareaStyle: { width: 900 },
    questionImage: [],
    hideBtnFooter: true,
    finished: {
      text: 'Write sentences about your screen time: what your parents let you do, and what they don’t allow you to do.',
    },
    hintBox: [
      {
        src: ['I don’t spend much time travelling to school. Only about fifteen minutes.'],
        borderColor: '#0066bf',
        arrow: true,
        position: 1,
        width: 500,
      },
    ],
    titleQuestion: [
      {
        num: '6',
        title: 'Ask and answer the questions. Use some of the key phrases.',
        color: '#0066bf',
        prefix: 'default',
      },
    ],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page11/E6/1.jpg' />
          <div style='margin-block: 30px;'>
            <hintbox id='0'></hintbox>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [''],
      },
    ],
  },
  7: {
    unit: 'Unit 1',
    id: 'SB7-U1-P11-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page11/E7/Key/answerKey.png',
    textareaStyle: { width: 740 },
    questionImage: [],
    finished: {
      text: 'Write sentences about your screen time: what your parents let you do, and what they don’t allow you to do.',
    },
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
