import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 1',
    id: 'SB7-U1-P10-E1',
    audio: 'Audios/1-04 Friends Plus 7.mp3',
    video: '',
    component: T6,
    selectStyle: { color: '#00ADFE', width: 215, textAlign: 'center', fontSize: 17 },
    selectOptionRandom: true,
    selectOptionValues: [
      'in the park',
      'at the shops',
      'in the countryside',
      'in the car',
      'in fast food restaurants',
      'in bed',
      'in your room',
      'on the bus',
      'in front of the TV',
      'on the phone',
      'at school',
      'in the playground',
    ],
    exerciseKey: 'img/FriendsPlus/Page10/E1/Key/answerKey.png',
    questionImage: [],
    questions: [
      {
        title: `
					<div style=' position: relative; '>
            <div><img src='img/FriendsPlus/Page10/E1/1.jpg' /></div>
            <div style=' position: absolute; top: 575px; left: 45px; '><select id='0'></select></div>
            <div style=' position: absolute; top: 505px; left: 515px; '><select id='1'></select></div>
            <div style=' position: absolute; top: 85px; left: 890px; '><select id='2'></select></div>
            <div style=' position: absolute; top: 730px; left: 0px; '><select id='3'></select></div>
            <div style=' position: absolute; top: 665px; left: 290px; '><select id='4'></select></div>
            <div style=' position: absolute; top: 613px; left: 795px; '><select id='5'></select></div>
            <div style=' position: absolute; top: 677px; left: 945px; '><select id='6'></select></div>
            <div style=' position: absolute; top: 830px; left: 330px; '><select id='7'></select></div>
            <div style=' position: absolute; top: 890px; left: 645px; '><select id='8'></select></div>
            <div style=' position: absolute; top: 935px; left: 825px; '><select id='9'></select></div>
            <div style=' position: absolute; top: 975px; left: 110px; '><select id='10'></select></div>
            <div style=' position: absolute; top: 1057px; left: 570px; '><select id='11'></select></div>          
					</div>
				`,
        answer: [
          'in the park',
          'at the shops',
          'in the countryside',
          'in the car',
          'in fast food restaurants',
          'in bed',
          'in your room',
          'on the bus',
          'in front of the TV',
          'on the phone',
          'at school',
          'in the playground',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 1',
    id: 'SB7-U1-P10-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page10/E2/Key/answerKey.png',
    stylesTextInput: { borderBottom: '1px dashed' },
    inputSize: 250,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the sentences below so that they are true for you. Use words from exercise 1.',
        color: '#0067b4',
      },
    ],
    hintBox: [
      {
        src: [
          'in the park',
          'at the shops',
          'in the countryside',
          'in the car',
          'in fast food restaurants',
          'in bed',
          'in your room',
          'on the bus',
          'in front of the TV',
          'on the phone',
          'at school',
          'in the playground',
        ],
        borderColor: '#4687cb',
      },
    ],
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
					<hintbox id='0'></hintbox>
          <div>I love being <span style='color: gray; font-style: italic;'>in bed.</span></div>
					<div style='display: flex; justify-content: space-between;'><div><b>1</b> I like being #.</div><div><b>3</b> I don’t always like being #.</div></div>
					<div style='display: flex; justify-content: space-between;'><div><b>2</b> I don’t mind being #.</div><div><b>4</b> I sometimes hate being #.</div></div>
        `,
        answer: ['', '', '', ''],
      },
    ],
  },
};

export default json;
