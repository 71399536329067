import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: {
    unit: "Unit 5",
    id: "SB7-U5-P67-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: {
      borderBottom: "dash",
      border: "2px solid",
      background: "white",
      height: "35px",
    },
    textAlign: "center",
    inputSize: 130,
    maxLength: 8,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page67/E1/Key/answerKey.png",
    checkUppercase: true,

    titleQuestion: [
      {
        num: "1",
        title: "Order the letters to find times, numbers and measurements. ",
        color: "#db2f3a",
      },
    ],

    questionImage: [],
    questions: [
      {
        title: `
        <div style='background: rgb(246 161 180);border-radius:50px;position: relative; padding:20px  100px 50px 160px;'>
           <img src='img/FriendsPlus/Page67/E1/1.jpg'>   
          
            
            <div style=" position: absolute; top: 100px; left: 67px;">
                #
            </div>
            <div style=" position: absolute; top: 100px; left: 568px;">
                #
            </div>
            <div style=" position: absolute; top: 245px; left: 67px;">
                #
            </div>
            <div style=" position: absolute; top: 245px; left: 568px;">
                #
            </div>
            <div style=" position: absolute; top: 475px; left: 163px;">
                #
            </div>
            <div style=" position: absolute; top: 475px; left: 335px;display: flex">
              #
            </div>
            <div style=" position: absolute; top: 475px; left: 505px;display: flex">
              #
            </div>
            
					
        </div>
          `,
        answer: [
          "quarter",
          "decade",
          "kilo",
          "thousand",
          "billion",
          "metre",
          "second",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB7-U5-P67-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    imageStyle: {
      width: "80%",
    },
    titleQuestion: [
      {
        num: "2",
        title: "SENTENCE RACES. Work in two groups. Follow the instructions.",
        color: "black",
        // prefix: { icons: [], text: "VOCABULARY PLUS" },
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page67/E2/1.jpg" }]],
  },

  3: {
    unit: "Unit 5",
    id: "SB7-U5-P67-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 190,
    maxLength: 13,
    textAlign: "center",
    checkDuplicated: true,
    exerciseKey: "img/FriendsPlus/Page67/E3/Key/answerKey.png",
    // checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title: "Join the puzzle pieces to find the jobs and skills. ",
        color: "BLACK",
      },
    ],

    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page67/E3/1.jpg' style='margin-right:20px'>         
          <div style='display: flex'>
                    <div style='margin-left:100px'>
                      #<br>#<br>#
                    </div>
                    <div style='margin:0px 160px'>
                      #<br> <br>#
                    </div>
                    <div >
                      #<br>#<br>#
                    </div>
          </div>

          `,
        answer: [
          "scientist / champion / genius / professor / musician / composer / inventor / mathematician",
          "scientist / champion / genius / professor / musician / composer / inventor / mathematician",
          "scientist / champion / genius / professor / musician / composer / inventor / mathematician",
          "scientist / champion / genius / professor / musician / composer / inventor / mathematician",
          "scientist / champion / genius / professor / musician / composer / inventor / mathematician",
          "scientist / champion / genius / professor / musician / composer / inventor / mathematician",
          "scientist / champion / genius / professor / musician / composer / inventor / mathematician",
          "scientist / champion / genius / professor / musician / composer / inventor / mathematician",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 5",
    id: "SB7-U5-P67-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // imageStyle: {
    //   width: "80%",
    // },
    titleQuestion: [
      {
        num: "4",
        title:
          "TALK ABOUT ... Work in group of four. You need a dice. Take turns rolling the die and answering the question with the same number. If you get the same number twice, roll again.",

        color: "black",
        // prefix: { icons: [], text: "VOCABULARY PLUS" },
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page67/E4/1.jpg" }]],
  },

  5: {
    unit: "Unit 5",
    id: "SB7-U5-P67-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // imageStyle: {
    //   width: "80%",
    // },
    titleQuestion: [
      {
        num: "5",
        title: "TEAM QUIZ. Work in two groups. Follow the instructions. ",

        color: "black",
        // prefix: { icons: [], text: "VOCABULARY PLUS" },
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page67/E5/1.jpg" }]],
  },
};
export default json;
