import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'SB7-U6-P77-E1',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page77/E1/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '1',
        title:
          'Find seven verbs in the grid. Each verb is in a different colour. Then use the verbs to complete the phrases about survival. ',
        color: '#000',
      },
    ],
    component: T6,
    inputSize: 120,
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style='display:flex; justify-content: space-between; gap:10px;'>
          <div>
            <div style='margin-left: 40px;'><b><i>run</i></b> away</div>
            <div><b>1 </b> # drinking water </div>
            <div><b>2 </b> # cool</div>
            <div><b>3 </b> # a shelter</div>
            <div><b>4 </b> # a fire </div>
            <div><b>5 </b> # fruit</div>
            <div><b>6 </b> # still</div>
            <div><b>7 </b> # the river</div>
          </div>
          <div><img src='img/FriendsPlus/Page77/E1/1.png' alt='' /></div>
        </div>
        `,
        answer: ['find', 'keep', 'build', 'light', 'pick', 'stand', 'follow'],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 6',
    id: 'SB7-U6-P77-E2',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page77/E2/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '2',
        title:
          'WHAT’S IN THE TENT? Work in groups. Add vowels (<i>a, e, i</i> and <i>o</i>) to find the survival equipment. The first group to find all the equipment wins.',
        color: '#000',
      },
    ],
    component: T6,
    inputSize: 180,
    checkUppercase: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='display:flex; justify-content: space-between; gap:10px;'>
          <div>
            <div><b>mp </b> --> # </div>
            <div><b>trch </b> --> # </div>
            <div><b>lghtr </b> --> # </div>
            <div><b>frst-d kt </b> --> # </div>
            <div><b>wtr bttl </b> --> # </div>
            <div><b>cmpss </b> --> # </div>
            <div><b>knf </b> --> # </div>
            <div><b>rp </b> --> # </div>
            <div><b>mrrr </b> --> # </div>
            <div><b>mrrr </b> --> # </div>
          </div>
          <div><img src='img/FriendsPlus/Page77/E2/1.png' alt='' /></div>
        </div>
        `,
        answer: [
          'map',
          'torch',
          'lighter',
          'first-aid kit',
          'water bottle',
          'compass',
          'knife',
          'rope',
          'mirror',
          'sleeping bag',
        ],
      },
    ],
  },

  3: {
    // Exercise num
    unit: 'Unit 6',
    id: 'SB7-U6-P77-E3',
    audio: '',
    video: '',
    padding: 0,
    questionImage: [],
    hideBtnFooter: true,
    isDisabledSubmit: false,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '#000',
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div><img src='img/FriendsPlus/Page77/E3/1.png' alt='' style='border-radius: 31px;'/></div>
        `,
        answer: [],
      },
    ],
  },

  4: {
    // Exercise num
    unit: 'Unit 6',
    id: 'SB7-U6-P77-E4',
    audio: '',
    video: '',
    padding: 0,
    questionImage: [],
    hideBtnFooter: true,
    isDisabledSubmit: false,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div><img src='img/FriendsPlus/Page77/E4/1.png' alt='' style='border-radius: 31px; width: 60%; margin-left: 170px;'/></div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 6',
    id: 'SB7-U6-P77-E5',
    audio: '',
    video: '',
    padding: 0,
    questionImage: [],
    hideBtnFooter: true,
    isDisabledSubmit: false,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div><img src='img/FriendsPlus/Page77/E5/1.jpg' alt='' style='border-radius: 31px; width: 60%; margin-left: 170px;'/></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
