import TB1 from '../../components/ExcerciseTypes/Table/TB1';
import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Starter',
    id: 'SB7-S-P7-E1',
    audio: '',
    video: '',
    component: TB1,
    exerciseKey: 'img/FriendsPlus/Page7/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title:
          'Look for examples of the verb <i>be</i> in the text on page 6. How many examples can you find? Compare with your partner.',
        color: '#1dbfd7',
      },
    ],
    data: [
      {
        title: 'Affirmative ✔',
        content: "I'm the one...",
        type: 'textarea',
        answers: [
          "My name's Lucy",
          'these are',
          'This is me',
          "We're twins",
          "I'm the one",
          'My older sister is 22',
          'her husband is Italian',
          'This is her cute baby son',
          'His new grandson',
          'Nico is a bit smelly',
          'This is my dad',
          "They're Star Wars fans",
          "she's a real star!",
        ],
      },
      // {
      //   title: "Negative ✘",
      //   content: "Tony isn't very happy.",
      //   type: "text",
      // },
      {
        title: 'Negative ✘',
        content: "Tony isn't very happy.",
        type: 'textarea',
        answers: ["Tony isn't very happy", "They aren't crazy"],
      },
    ],
    outterStyle: {},
    innerStyle: {},
  },
  2: {
    // Exercise num
    unit: 'Starter',
    id: 'SB7-S-P7-E2',
    audio: '',
    video: '',
    component: T6,
    inputSize: 150,
    textAlign: 'center',
    titleImage: '',
    titleQuestion: [
      {
        num: '2',
        title: 'Write true sentences using affirmative and negative forms of <i>be</i>.',
        color: '#1dbfd7',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page7/E2/Key/answerKey.png',
    questions: [
      {
        title: `    
          <div><b>1</b> I # from Nha Trang.</div>
          <div><b>2</b> We # in Việt Nam.</div>
          <div><b>3</b> It # Monday morning.</div>
          <div><b>4</b> Lucy’s photos # funny.</div>
          <div><b>5</b> These sentences # difficult.</div>
          <div><b>6</b> I # good at photography.</div>
        `,
        answer: ["'m/'m not", "are/aren't", "is/isn't", "are/aren't", "are/aren't", "'m/'m not"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Starter',
    id: 'SB7-S-P7-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 150,
    textAlign: 'center',
    titleImage: '',
    checkUppercase: true,
    titleQuestion: [
      {
        num: '3',
        title: 'Complete the questions and answers.',
        color: '#1dbfd7',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page7/E3/Key/answerKey.png',
    questions: [
      {
        title: `    
          <div><span style='color: darkgrey;'>Are</span> you a <i>Star Wars</i> fan?</div>
          <div>Yes, <span style='color: darkgrey;'>I am</span>. No, <span style='color: darkgrey;'>I’m not</span>.</div>
          <div><b>1</b> # <span style='color: rgb(29, 191, 215);'>blue</span> your favourite colour? </div>
          <div>Yes, #. No, #.</div>
          <div><b>2</b> # your friends interested in <span style='color: rgb(29, 191, 215);'>football</span>? </div>
          <div>Yes, #. No, #.</div>
          <div><b>3</b> # your dad a <span style='color: rgb(29, 191, 215);'>teacher</span>? </div>
          <div>Yes, #. No, #.</div>
        `,
        answer: ['Is', 'it is', "it isn't", 'Are', 'they are', "they aren't", 'Is', 'he is', "he isn't"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Starter',
    id: 'SB7-S-P7-E4',
    audio: '',
    video: '',
    component: T6,
    inputSize: 400,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page7/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title:
          "Make new questions. Change the words in <span style='color: #1dbfd7;'>blue</span> in exercise 3. Ask your partner.",
        color: '#1dbfd7',
        prefix: { icons: ['far fa-comment'] },
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div><span style='color: darkgrey;'>Are</span> you a <i>Star Wars</i> fan?</div>
          <div>Yes, <span style='color: darkgrey;'>I am</span>. No, <span style='color: darkgrey;'>I’m not</span>.</div>
          <div><b>1</b> __________ <span style='color: rgb(29, 191, 215);'>blue</span> your favourite colour? </div>
          <div>Yes, __________. No, __________.</div>
          <div><b>2</b> __________ your friends interested in <span style='color: rgb(29, 191, 215);'>football</span>? </div>
          <div>Yes, __________. No, __________.</div>
          <div><b>3</b> __________ your dad a <span style='color: rgb(29, 191, 215);'>teacher</span>? </div>
          <div>Yes, __________. No, __________.</div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Starter',
    id: 'SB7-S-P7-E5',
    audio: '',
    video: '',
    component: TB2,
    titleQuestion: [
      {
        num: '5',
        title: 'Complete the table with possessive adjectives and possessive pronouns.',
        color: '#1dbfd7',
      },
    ],
    questions: [],
    tb2Style: { width: 700 },
    exerciseKey: 'img/FriendsPlus/Page7/E5/Key/answerKey.png',
    type: 1,
    data: [
      {
        title: 'Subject pronouns',
        content: ['I', 'you', 'he', 'she', 'we', 'they'],
        answers: [],
        commonStyle: { backgroundColor: '#e5e3f1' },
        titleStyle: {},
        contentStyle: {},
      },
      {
        title: 'Possessive adjectives',
        content: ['#', '#', '#', '#', '#', '#'],
        answers: ['my', 'your', 'his', 'her', 'our', 'their'],
        commonStyle: { backgroundColor: '#d7eff6' },
        titleStyle: {},
        contentStyle: {},
      },
      {
        title: 'Possessive pronouns',
        content: ['#', '#', '#', '#', '#', '#'],
        answers: ['mine', 'yours', 'his', 'hers', 'ours', 'theirs'],
        commonStyle: { backgroundColor: '#eef5d5' },
        titleStyle: {},
        contentStyle: {},
      },
    ],
  },

  6: {
    // Exercise num
    unit: 'Starter',
    id: 'SB7-S-P7-E6',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '6',
        title:
          'Complete the dialogue with the correct subject pronouns, possessive adjectives and possessive pronouns.',
        color: '#1dbfd7',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page7/E6/Key/answerKey.png',
    inputSize: 100,
    textAlign: 'center',
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style="font-size: 21px">
          <div style="display: flex;">
            <div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Mr Watts</b></div>
                <div style='flex: 3;'>Hi. What’s <sup>1</sup> # name?</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Sophie</b></div>
                <div style='flex: 3;'>I’m Sophie.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Mr Watts</b></div>
                <div style='flex: 3;'>Sophie, <sup>2</sup> # ’m your teacher. <sup>3</sup> # name is Watts and these are two of your new classmates. <sup>4</sup> # names are Eva and Rick.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Sophie</b></div>
                <div style='flex: 3;'>Hi.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Sophie</b></div>
                <div style='flex: 3;'>Hi, Sophie. Come with us. You see, the other classes are on the first floor but <sup>5</sup> # is on the second floor.</div>
              </div>
            </div>
            <div><img style="width: 90%" src='img/FriendsPlus/Page7/E6/1.png'/></div>
          </div>
          <div style="text-align: center"><img style="width: 50%" src='img/FriendsPlus/Page7/E6/2.png'/></div>
          <div>
            <div style="display: flex;">
              <div style='flex: 1;'><b>Rick</b></div>
              <div style='flex: 6;'>This is your first day, right? Don’t worry about Mr. Watts. <sup>6</sup> # ’s OK but his wife is our geography teacher, and she’s very strict.</div>
            </div>
            <div style="display: flex;">
              <div style='flex: 1;'><b>Eva</b></div>
              <div style='flex: 6;'>Here we are! Sophie, this is your desk. <sup>7</sup> # is next to yours. We can be best friends.</div>
            </div>
            <div style="display: flex;">
              <div style='flex: 1;'><b>Rick</b></div>
              <div style='flex: 6;'>Hey! <sup>8</sup> # isn’t only yours. She can be my best friend, too.</div>
            </div>
            <div style="display: flex;">
              <div style='flex: 1;'><b>Sophie</b></div>
              <div style='flex: 6;'>Thanks so much for being nice to me.</div>
            </div>
          </div>
        </div>
        `,
        answer: ['your', 'I', 'My', 'Their', 'ours', 'He', 'Mine', 'She'],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Starter',
    id: 'SB7-S-P7-E7',
    audio: '',
    video: '',
    component: T6,
    inputSize: 70,
    textAlign: 'center',
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page7/E7/Key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: 'Fill in the blanks with <i>a, an, the</i> or zero article.',
        color: '#1dbfd7',
      },
    ],
    selectStyle: { color: '#00ADFE', width: 148, textAlign: 'center', fontSize: 20, marginInline: 20 },
    selectOptionRandom: true,
    selectOptionValues: ['a', 'an', 'the', 'zero article'],
    questions: [
      {
        title: `
              <div style="display: flex"><b>1 &emsp;</b> <select id='0'></select> principal of my school is very friendly.</div>
              <div style="display: flex"><b>2 &emsp;</b> We are going to have <select id='1'></select> English test tomorrow.</div>
              <div style="display: flex"><b>3 &emsp;</b> Are you interested in <select id='2'></select> physics or <select id='3'></select> English?</div>
              <div style="display: flex"><b>4 &emsp;</b> Danny wanted <select id='4'></select> bicycle for his last birthday.</div>
              <div style="display: flex"><b>5 &emsp;</b> It takes Olivia <select id='5'></select> hour to get to work every day.</div>
              <div style="display: flex"><b>6 &emsp;</b> What’s <select id='6'></select> highest mountain in your country?</div>
        `,
        answer: ['the', 'an', 'zero article', 'zero article', 'a', 'an', 'the'],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Starter',
    id: 'SB7-S-P7-E8',
    audio: '',
    video: '',
    component: T6,
    inputSize: 70,
    textAlign: 'center',
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page7/E7/Key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title: 'Ask and answer questions about your classroom, using articles (<i>a, an, the</i> or zero articles).',
        color: '#1dbfd7',
        prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
      },
    ],
    hintBox: [
      {
        src: ["What's that on the wall?"],
        borderColor: '#1CBED7',
        arrow: true,
        position: 1,
        width: 300,
      },
      {
        src: ["It's a clock."],
        borderColor: '#8BD5E5',
        arrow: true,
        position: 2,
        width: 200,
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex; justify-content: space-between; width: 550px;'>
            <hintbox id='0'></hintbox>
            <div style="margin-top: 10px"><hintbox id='1'></hintbox>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
