import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB7-U4-P47-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "1px dotted", fontSize: 24, textAlign: "center" },
    hintBox: [
      {
        src: [
          "was",
          "wasn't",
          "were",
          "weren't",
        ],
        borderColor: '#2eb6e1',
        width: 550,
      },
    ],
    inputSize: 110,
    exerciseKey: "img/FriendsPlus/Page47/E1/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the sentences with the words in the box. Then check your answers in the text on page 46.",
        color: "#2eb6e1",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display: flex; justify-content: center; width: 900px"><hintbox id='0'></hintbox></div>
          <div><b>1</b> The little girl # smiling nicely.</div>
          <div><b>2</b> She # playing with the toy.</div>
          <div><b>3</b> She # making toys with ice-cream sticks.</div>
          <div><b>4</b> Some people # singing.</div>
          <div><b>5</b> They # making decoration from tyres and bottles.</div>
        `,
        answer: [
          "was",
          "wasn't",
          "was",
          "were ",
          "weren't ",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "SB7-U4-P47-E2",
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page47/E2/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0 5px",
          fontWeight: 600,
          color: "#20c1d8",
          fontSize: 24,
        },
        selectWordStyle: { border: "2px solid #20c1d8" },
        limitSelect: 1,
        listWords: [
          "a_finished_action / an_action_in_progress",
          "did / was_(were)",
          "ing / base",
          "wasn't(weren't) / didn't",
          "base / ing",
        ],
        answers: ['0-4', '1-4', '3-0', '2-0', '4-4'],
        initialValue: [],
      },
      Layout: `
        <div>
          <b>
            <div style="display: flex; align-items: center; max-height: 150px">
              <div style="color: rgb(28, 190, 215); font-size: 40px;" >2 &nbsp</div>
              <div style="font-size: 25px;">Study the sentences in exercise 1. Then choose
              the correct words in the Rules.</div>
            </div>
          </b>
        </div>
        <img style="width: 44%" src='img/FriendsPlus/Page47/E2/1.jpg'/ style='margin: 0 1mm 1cm 5cm '>
        <div><b>1.</b>  We use the past continous to talk about <input id='0'  type='Circle' /> in the past.</div>
        <div><b>2.</b> We form the past continuous with <input id='1' type='Circle' /> and the <input id='2' type='Circle' /> form of the verb.</div>
        <div><b>3.</b> We form the negative of the past continuous with <input id='3' type='Circle' /> and the <input id='4' type='Circle' /> form of the verb.</div>
      `,
    },
  },
  3: {
    unit: "Unit 4",
    id: "SB7-U4-P47-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 250,
    maxLength: 60,
    exerciseKey: "img/FriendsPlus/Page47/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the text using the past continuous form of the verbs in brackets.",
        color: "#2eb6e1",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="display: flex">
          <div style="flex: 1"><b></b> Photographer David Slater<sup>1</sup>#(visit) Sulawesi, an island in Indonesia. He<sup>2</sup>#(photograph) the monkeys there and they<sup>3</sup>#(jump)
           all over his camera and camera bag. One of the bravest monkey<sup>4</sup>#(play) with camera, and it took a photo. The monkey took over 100 photos that day, but
           the one that became a classic was this photo: the monkey<sup>5</sup>#(smile)when he took the selfie.</div>
          <div style="flex: 2"><img src='img/FriendsPlus/Page47/E3/1.png'  style='margin: 4mm 2cm; width: 50%'/></div>
        </div>
        `,
        answer: [
          "was visiting",
          "was photographing",
          "were jumping",
          "was playing",
          "was smiling",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB7-U4-P47-E4",
    audio: "",
    video: "",
    component: T6,
    inputSize: 550,
    maxLength: 100,
    exerciseKey: "img/FriendsPlus/Page47/E4/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write true sentences about today. Use the affirmative <br/>or negative form of the past continuous",
        color: "#2eb6e1",
      },
    ],
    questions: [
      {
        title: `
          <div><b>1</b> My dad / work / 9 a.m. <br> # </div>
          <div><b>2</b> My friends / walk / to school at 8 a.m .<br> #</div>
          <div><b>3</b> It / rain / early this morning.<br> # </div>
          <div><b>4</b> I / ride / my bike / at 8.15 a.m .<br> # </div>
          <div><b>5</b> My family / sleep / at 5 a.m .<br> # </div>
        `,
        answer: [
          "My dad was working at 9 a.m. / My dad wasn't working at 9 a.m.",
          "My friends were walking to school at 8 a.m. / My friends weren't walking to school at 8 a.m.",
          "It was raining early this morning. / It wasn't raining early this morning. ",
          "I was riding my bike at 8.15 a.m. / I wasn't riding my bike at 8.15 a.m.",
          "My family was sleeping at 5 a.m. / My family wasn't sleeping at 5 a.m.  ",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB7-U4-P47-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 800,
    maxLength: 200,
    checkUppercase: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          " Work in pairs. Look at the photo and imagine you saw the event. Describe what was happening. Use the questions to help you.",
        color: "#2eb6e1",
        prefix: 'default'
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page47/E5/2.jpg" }]],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page47/E5/1.jpg'  style='margin: 4mm 2cm; width: 74%'/>
          <div><b>1</b> Where did the event happen?. <br>  #  </div>
          <div><b>2</b> What were you doing when you saw this? <br> #</div>
          <div><b>3</b> What were the children doing? <br> #</div>
          <div><b>4</b> What was the girl in blue T-shirt doing? <br> #</div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: 'SB7-U4-P47-E6',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1050 },
    hideBtnFooter: true,
    finished: {
      text: "Finish the monkey’s story: ‘I was sitting in a tree in Indonesia one day …’"
    },
    questions: [
      {
        title: `
          <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: []
      },
    ]
  },
};

export default json;
