import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Option 5",
    id: "SB7-O5-P116-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page116/E1/Key/answerKey.png",
    component: T6,
    inputSize: 200,
    maxLength: 16,
    textAlign: "center",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the people in the pictures. <br /> What are their professions?",
        color: "#dd2d38",
      },
    ],

    questions: [
      {
        title: `
            <div style='display: flex; justify-content: space-between;'>
              <div>
                <img src='img/FriendsPlus/Page116/E1/1.jpg'>
                <p style="">1 a professor</p>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page116/E1/2.jpg'>
                <p>2 #</p>
              </div>
            </div>

            <div style='display: flex;'>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page116/E1/3.jpg'>
                <p>3 #</p>
              </div>
              <div style='margin-left: 70px'>
                <img  style='width:80%'src='img/FriendsPlus/Page116/E1/4.jpg'>
                <p>4 #</p>
              </div>
            </div>

            <div style='display: flex;'>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page116/E1/5.jpg'>
                <p>5 #</p>
              </div>
              <div style='margin-left: 70px'>
                <img  style='width:80%'src='img/FriendsPlus/Page116/E1/6.jpg'>
                <p>6 #</p>
              </div>
            </div>

            <div style='display: flex;'>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page116/E1/7.jpg'>
                <p>7 #</p>
              </div>
              <div style='margin-left: 70px'>
                <img  style='width:80%'src='img/FriendsPlus/Page116/E1/8.jpg'>
                <p>8 #</p>
              </div>
            </div>
            `,
        answer: [
          'a writer',
          'a composer',
          'a dancer',
          'a scientist',
          'an artist',
          'a rapper',
          'a police officer'
        ],
      },
    ],
  },

  2: {
    unit: "Option 5",
    id: "SB7-O5-P116-E2",
    audio: "Audios/3-27 Friends Plus 7.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page116/E2/Key/answerKey.png",

    component: T6,
    inputSize: 39,
    stylesTextInput: {
      border: "1px solid",
      height: 25,
      fontSize: 20
    },
    textAlign: "center",
    maxLength: 1,

    titleQuestion: [
      {
        num: "2",
        title: "<audioimage name='3.27'></audioimage> Listen to the song. Put the verses in the correct order.",
        color: "#dd2d38",
      },
    ],

    questions: [
      {
        title: `
          <div style='position: relative'>
          <div><img src='img/FriendsPlus/Page116/E3/1.jpg'/></div>
          
          <div style='position: absolute; top: 100px; left: 50px; font-size: 1rem'>
            <strong style='color:rgba(70, 138, 204, 1); font-size: 1.5rem'>How long?</strong> <br /> 
            <div>
                <strong>A</strong> # I could climb the highest mountain <br />
                <span style='margin-left: 67px;'>Be the best in the world at chess</span> <br />
                <span style='margin-left: 67px;'>Who’s the most intelligent scientist?</span> <br />
                <span style='margin-left: 67px;'>Come on - I’ll give you one guess.</span> <br />
            </div>
            <div>
                <strong>B</strong> # Yeah, it’s me, it’s me <br />
                <span style='margin-left: 67px;'>I’m a winner.</span> <br />
                <span style='margin-left: 67px;'>I can do anything I want</span> <br />
                <span style='margin-left: 67px;'>Be a professor, a composer, or an artist</span> <br />
                <span style='margin-left: 67px;'>I’m better than the rest</span> <br />
                <span style='margin-left: 67px;'>I’m the number one.</span> <br />
            </div>
            <div>
            <strong>C</strong> # How long does it take to be an expert? <br />
                <span style='margin-left: 67px;'>What do you reckon?</span> <br />
                <span style='margin-left: 67px;'>I’ve no idea</span> <br />
                <span style='margin-left: 67px;'>Could I become a genius in a month</span> <br />
                <span style='margin-left: 67px;'>Or never in a million years?</span> <br />
            </div>
            <div>
                <strong>D</strong> # Yeah, it’s me, it’s me <br />
                <span style='margin-left: 67px;'>I’m a winner</span> <br />
                <span style='margin-left: 67px;'>can do anything I want</span> <br />
                <span style='margin-left: 67px;'>An inventor, a programmer, a writer</span> <br />
                <span style='margin-left: 67px;'>I’m better than the rest</span> <br />
                <span style='margin-left: 67px;'>I’m the number one</span> <br />
            </div>
            <div>
                <strong>E</strong> # Could I run 100 metres <br />
                <span style='margin-left: 67px;'>In ten seconds or less?</span> <br />
                <span style='margin-left: 67px;'>Who’s the best rapper</span> <br />
                <span style='margin-left: 67px;'>and an ever better dancer?</span> <br />
                <span style='margin-left: 67px;'>Come on – have a guess.</span> <br />
            </div>
            <div>
                <strong>F</strong> # How long does it take to be a champion? <br />
                <span style='margin-left: 67px;'>What do you reckon?</span> <br />
                <span style='margin-left: 67px;'>Have you any idea?</span> <br />
                <span style='margin-left: 67px;'>Could I become a winner in a decade</span> <br />
                <span style='margin-left: 67px;'>Or can I do it in half a year?</span> <br />
            </div>
          </div>
        </div>
            `,
        answer: ["5", "3", "1", "6", "2", "4"],
      },
    ],
  },

  3: {
    unit: "Option 5",
    id: "SB7-O5-P116-E3",
    audio: "Audios/3-27 Friends Plus 7.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page116/E3/Key/answerKey.png",
    inputSize: 150,
    maxLength: 16,
    stylesTextInput: { fontSize: 18 },
    // checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title: "<audioimage name='3.27'></audioimage> Listen to the song again. Which profession from exercise 1 is not in the song?",
        color: "#dd2d38",
      },
    ],
    questions: [
      {
        title: `
            <div style='position: relative'>
              <div><img src='img/FriendsPlus/Page116/E3/1.jpg'/></div>
              
              <div style='position: absolute; top: 100px; left: 50px; font-size: 1rem'>
                <strong style='color:rgba(70, 138, 204, 1); font-size: 1.5rem'>How long?</strong> <br /> 
                <ol style="list-style-type: upper-alpha;">
                  <li>
                      I could climb the highest mountain <br />
                      Be the best in the world at chess <br />
                      Who’s the most intelligent scientist? <br />
                      Come on - I’ll give you one guess. <br />
                  </li>
                  <li>
                      Yeah, it’s me, it’s me <br />
                      I’m a winner. <br />
                      I can do anything I want <br />
                      Be a professor, a composer, or an artist <br />
                      I’m better than the rest <br />
                      I’m the number one. <br />
                  </li>
                  <li>
                      How long does it take to be an expert? <br />
                      What do you reckon? <br />
                      I’ve no idea <br />
                      Could I become a genius in a month <br />
                      Or never in a million years? <br />
                  </li>
                  <li>
                      Yeah, it’s me, it’s me <br />
                      I’m a winner. <br />
                      I can do anything I want <br />
                      An inventor, a programmer, a writer <br />
                      I’m better than the rest <br />
                      I’m the number one. <br />
                  </li>
                  <li>
                      Could I run 100 metres <br />
                      In ten seconds or less? <br />
                      Who’s the best rapper <br />
                      and an ever better dancer? <br />
                      Come on – have a guess. <br />
                  </li>
                  <li>
                      How long does it take to be a champion? <br />
                      What do you reckon? <br />
                      Have you any idea? <br />
                      Could I become a winner in a decade <br />
                      Or can I do it in half a year? <br />
                  </li>
                </ol>
              </div>

              <div style='position: absolute; top: 100px; left: 600px;'>Answer: #</div> 
            </div>
          `,
        answer: ["A police officer"],
      },
    ],
  },

  4: {
    unit: "Option 5",
    id: "SB7-O5-P116-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page116/E4/Key/answerKey.png",

    component: T6,
    inputSize: 160,
    maxLength: 11,
    titleQuestion: [
      {
        num: "4",
        title: "Complete the sentences with words from the song.",
        color: "#dd2d38",
      },
    ],
    questions: [
      {
        title: `
              <ol style="margin-top: 20px; width: 550px;">
                <li>An # is someone who knows a lot about a subject.</li>
                <li># means ‘clever’.</li>
                <li>A # is ten years.</li>
                <li>A # writes computer programs.</li>
                <li>An # creates new things.</li>
                <li># is a game for two people with black and white pieces.</li>
              </ol>
          `,

        answer: ['expert', 'Intelligent', 'decade', 'programmer', 'inventor', 'Chess'],
      },
    ],
  },

  5: {
    unit: "Unit 5",
    id: "SB7-U5-P116-E5",

    audio: "",
    video: "",

    component: T6,
    inputSize: 550,
    maxLength: 100,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

    titleQuestion: [
      {
        num: "5",
        title: "Work in pairs. Ask and answer the questions.",
        color: "#dd2d38",
        prefix: "default",
      },
    ],
    questions: [
      {
        title: `
            <div style="margin-top: 20px; width: 550px;">
              <ol>
                <li>Can you play chess / compose music / dance?</li>
                <li>What could you do when you were six?</li>
                <li>How fast can you run 100 metres?</li>
              </ol>

              <div style="margin-top: 20px;">
                <strong>Answer:</strong> <br/>
                # <br/> # <br/> # <br/> # <br/> # <br/> 
              </div>
            </div>
          `,
        answer: [],
      },
    ],
  },
};

export default json;