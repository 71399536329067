import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
    1: {
        unit: "Option 3",
        id: "SB7-O3-P115-E1",
        exerciseKey: "img/FriendsPlus/Page115/E1/Key/answerKey.png",

        audio: "Audios/3-26 Friends Plus 7.mp3",
        video: "",

        component: T6,
        inputSize: 110,
        maxLength: 9,
        // checkDuplicated: true,

        titleQuestion: [
            {
                num: "1",
                title: "<audioimage name='3.26'></audioimage> Look at the picture and listen to the song. Which verse - A, B, or C - does the picture match?",
                color: "#dd2d38",
            },
        ],

        questions: [
            {
                title: `
                <div style="display: flex;">

                    <div style='position: relative'>
                        <div>
                            <img src='img/FriendsPlus/Page115/E1/songFrame.jpg'/>
                        </div>

                        <div style='position: absolute; top: 80px; left: 50px; font-size: 1rem'>
                            <strong style='color:rgba(70, 138, 204, 1); font-size: 1.5rem'>Before I met you</strong> <br /> 

                            Here is my history in a song <br/>
                            Where I _____________ , what I found <br/>
                            What went right and what went wrong <br/>
                            It’s my story in a song, before I met you. <br/>

                            <div style="margin-top: 20px">
                                <strong>A</strong> When I was young it was exciting <br/>
                                <div style="margin-left: 16px">
                                    We _____________ round the world <br/>
                                    We found amazing places <br/>
                                    And I _____________ brave when we explored. <br/>
                                    Some were small, some were big <br/>
                                    Some were lonely, a few _____________ horrible<br/>
                                    But when my dad said ‘Let’s go!’ <br/>
                                    I was never bored.<br/>
                                    <br/>
                                    Here is my history in a song <br/>
                                    Where I went, what I _____________ <br/>
                                    What went right and what went wrong <br/>
                                    It’s my story in a song, before I met you. <br/>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                            <strong>B</strong> In the year 2010, <br/>
                                <div style="margin-left: 16px">
                                    We _____________ some friends <br/>
                                    We _____________ in an ancient castle <br/>
                                    Built by a rich and cruel queen <br/>
                                    We _____________ some scary rooms<br/>
                                    We learned some awful secrets <br/>
                                    But I wasn’t scared, I was happy <br/>
                                    We _____________ some exciting things. <br/>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                            <strong>C</strong> But the best day of my life <br/>
                                <div style="margin-left: 16px">
                                    Was last summer when I _____________ you <br/>
                                    There are lots of brilliant places <br/>
                                    I want to take you to.<br/>
                                    It was the best day, the best day<br/>
                                    The best day I ever knew.<br/>
                                    The story of my life, when I met you.<br/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-left: 20px; margin-top: 20px">
                        <img style="max-width: 100%" src='img/FriendsPlus/Page115/E1/1.jpg'/> <br/><br/>
                        Answer: #
                    </div>
            </div>
            `,
                answer: ['Verse B / B'],
            },
        ],
    },

    2: {
        unit: "Option 3",
        id: "SB7-O3-P115-E2",
        exerciseKey: "img/FriendsPlus/Page115/E2/Key/answerKey.png",

        audio: "Audios/3-26 Friends Plus 7.mp3",
        video: "",

        component: T6,
        inputSize: 150,
        maxLength: 9,
        // checkDuplicated: true,

        stylesTextInput: {
            fontSize: 18,
            // textAlign: "center",
            // borderBottom: "none",
            // backgroundColor: "transparent",
        },

        hintBox: [{
            src: ['explored', 'felt', 'found', 'met', 'saw', 'stayed', 'travelled', 'visited', 'went', 'were'],
            borderColor: "#e3828a",
            width: 525,
        },],

        titleQuestion: [
            {
                num: "2",
                title: "<audioimage name='3.26'></audioimage> Complete the song with the words in the box. Then listen again and check.",
                color: "#dd2d38",
            },
        ],

        questions: [
            {
                title: `
                <hintbox id='0'></hintbox>
                <div style="display: flex; margin-top: 20px">

                    <div style='position: relative'>
                        <div>
                            <img src='img/FriendsPlus/Page115/E1/songFrame.jpg'/>
                        </div>

                        <div style='position: absolute; top: 80px; left: 50px; font-size: 1rem'>
                            <strong style='color:rgba(70, 138, 204, 1); font-size: 1.5rem'>Before I met you</strong> <br /> 

                            Here is my history in a song <br/>
                            Where I # , what I found <br/>
                            What went right and what went wrong <br/>
                            It’s my story in a song, before I met you. <br/>

                            <div style="margin-top: 20px">
                                <strong>A</strong> When I was young it was exciting <br/>
                                <div style="margin-left: 16px">
                                    We # round the world <br/>
                                    We found amazing places <br/>
                                    And I # brave when we explored. <br/>
                                    Some were small, some were big <br/>
                                    Some were lonely, a few # horrible<br/>
                                    But when my dad said ‘Let’s go!’ <br/>
                                    I was never bored.<br/>
                                    <br/>
                                    Here is my history in a song <br/>
                                    Where I went, what I # <br/>
                                    What went right and what went wrong <br/>
                                    It’s my story in a song, before I met you. <br/>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                            <strong>B</strong> In the year 2010, <br/>
                                <div style="margin-left: 16px">
                                    We # some friends <br/>
                                    We # in an ancient castle <br/>
                                    Built by a rich and cruel queen <br/>
                                    We # some scary rooms<br/>
                                    We learned some awful secrets <br/>
                                    But I wasn’t scared, I was happy <br/>
                                    We # some exciting things. <br/>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                            <strong>C</strong> But the best day of my life <br/>
                                <div style="margin-left: 16px">
                                    Was last summer when I # you <br/>
                                    There are lots of brilliant places <br/>
                                    I want to take you to.<br/>
                                    It was the best day, the best day<br/>
                                    The best day I ever knew.<br/>
                                    The story of my life, when I met you.<br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    

            </div>
            `,
                answer: ['went', 'travelled', 'felt', 'were', 'found', 'visited', 'stayed', 'explored', 'saw', 'met'],
            },
        ],
    },

    3: {
        unit: "Option 3",
        id: "SB7-O3-P115-E3",
        exerciseKey: "img/FriendsPlus/Page115/E3/Key/answerKey.png",

        audio: "",
        video: "",

        component: T6,
        inputSize: 90,
        maxLength: 5,
        // stylesTextInput: {fontSize: 18},

        titleQuestion: [
            {
                num: "3",
                title: "Complete the sentences with words from the song.",
                color: "#dd2d38",
            },
        ],

        questions: [
            {
                title: `
                <div style="display: flex;">

                    <div style='position: relative'>
                        <div>
                            <img src='img/FriendsPlus/Page115/E1/songFrame.jpg'/>
                        </div>

                        <div style='position: absolute; top: 80px; left: 50px; font-size: 1rem'>
                            <strong style='color:rgba(70, 138, 204, 1); font-size: 1.5rem'>Before I met you</strong> <br /> 

                            Here is my history in a song <br/>
                            Where I _____________ , what I found <br/>
                            What went right and what went wrong <br/>
                            It’s my story in a song, before I met you. <br/>

                            <div style="margin-top: 20px">
                                <strong>A</strong> When I was young it was exciting <br/>
                                <div style="margin-left: 16px">
                                    We _____________ round the world <br/>
                                    We found amazing places <br/>
                                    And I _____________ brave when we explored. <br/>
                                    Some were small, some were big <br/>
                                    Some were lonely, a few _____________ horrible<br/>
                                    But when my dad said ‘Let’s go!’ <br/>
                                    I was never bored.<br/>
                                    <br/>
                                    Here is my history in a song <br/>
                                    Where I went, what I _____________ <br/>
                                    What went right and what went wrong <br/>
                                    It’s my story in a song, before I met you. <br/>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                            <strong>B</strong> In the year 2010, <br/>
                                <div style="margin-left: 16px">
                                    We _____________ some friends <br/>
                                    We _____________ in an ancient castle <br/>
                                    Built by a rich and cruel queen <br/>
                                    We _____________ some scary rooms<br/>
                                    We learned some awful secrets <br/>
                                    But I wasn’t scared, I was happy <br/>
                                    We _____________ some exciting things. <br/>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                            <strong>C</strong> But the best day of my life <br/>
                                <div style="margin-left: 16px">
                                    Was last summer when I _____________ you <br/>
                                    There are lots of brilliant places <br/>
                                    I want to take you to.<br/>
                                    It was the best day, the best day<br/>
                                    The best day I ever knew.<br/>
                                    The story of my life, when I met you.<br/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-left: 20px; margin-top: 20px">
                        <ol>
                            <li>When I was #, travelled around the world.</li>
                            <li>We visited amazing places and I was never #</li>
                            <li>In 2010, I stayed in a # with some friends.</li>
                            <li>A rich and cruel # built the castle.</li>
                            <li>We explored some scary #</li>
                            <li>The # day of my life was when I met you.</li>
                        </ol>
                    </div>

            </div>
            `,
                answer: ['young', 'bored', 'castle', 'queen', 'rooms', 'best'],
            },
        ],
    },

    4: {
        unit: "Option 3",
        id: "SB7-O3-P116-E4",
        exerciseKey: "img/FriendsPlus/Page115/E4/Key/answerKey.png",

        audio: "",
        video: "",

        component: T6,
        inputSize: 110,
        maxLength: 8,
        // stylesTextInput: {fontSize: 18},

        titleQuestion: [
            {
                num: "4",
                title: "Find words in the song with opposite meanings to these words.",
                color: "#dd2d38",
            },
        ],

        questions: [
            {
                title: `
                <div style="display: flex;">

                    <div style='position: relative'>
                        <div>
                            <img src='img/FriendsPlus/Page115/E1/songFrame.jpg'/>
                        </div>

                        <div style='position: absolute; top: 80px; left: 50px; font-size: 1rem'>
                            <strong style='color:rgba(70, 138, 204, 1); font-size: 1.5rem'>Before I met you</strong> <br /> 

                            Here is my history in a song <br/>
                            Where I _____________ , what I found <br/>
                            What went right and what went wrong <br/>
                            It’s my story in a song, before I met you. <br/>

                            <div style="margin-top: 20px">
                                <strong>A</strong> When I was young it was exciting <br/>
                                <div style="margin-left: 16px">
                                    We _____________ round the world <br/>
                                    We found amazing places <br/>
                                    And I _____________ brave when we explored. <br/>
                                    Some were small, some were big <br/>
                                    Some were lonely, a few _____________ horrible<br/>
                                    But when my dad said ‘Let’s go!’ <br/>
                                    I was never bored.<br/>
                                    <br/>
                                    Here is my history in a song <br/>
                                    Where I went, what I _____________ <br/>
                                    What went right and what went wrong <br/>
                                    It’s my story in a song, before I met you. <br/>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <strong>B</strong> In the year 2010, <br/>
                                <div style="margin-left: 16px">
                                    We _____________ some friends <br/>
                                    We _____________ in an ancient castle <br/>
                                    Built by a rich and cruel queen <br/>
                                    We _____________ some scary rooms<br/>
                                    We learned some awful secrets <br/>
                                    But I wasn’t scared, I was happy <br/>
                                    We _____________ some exciting things. <br/>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <strong>C</strong> But the best day of my life <br/>
                                <div style="margin-left: 16px">
                                    Was last summer when I _____________ you <br/>
                                    There are lots of brilliant places <br/>
                                    I want to take you to.<br/>
                                    It was the best day, the best day<br/>
                                    The best day I ever knew.<br/>
                                    The story of my life, when I met you.<br/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-left: 50px; margin-top: 20px">
                        <ol>
                            <li>kind #</li>
                            <li>boring #</li>
                            <li>unhappy #</li>
                            <li>poor #</li>
                            <li>wrong #</li>
                            <li>old #</li>
                        </ol>
                    </div>

            </div>
            `,
                answer: ['cruel', 'exciting', 'happy', 'rich', 'right', 'young'],
            },
        ],
    },

    5: {
        unit: "Unit 3",
        id: "SB7-U3-P116-E5",

        audio: "",
        video: "",

        component: T6,
        inputSize: 550,
        maxLength: 100,
        hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

        titleQuestion: [
            {
                num: "5",
                title: "Work in pairs. Ask and answer the questions.",
                color: "#dd2d38",
                prefix: "default",
            },
        ],

        questions: [
            {
                title: `
            <div style="width: 500px">
                <ol>
                    <li>What amazing places did you visit when you were younger?</li>
                    <li>What exciting things did you do or see last summer?</li>
                    <li>What was the best day of your life? Why?</li>
                </ol>
                <div style="margin-top: 20px">
                    <strong>Answer:</strong> <br/>
                    # <br> # <br> # <br> # <br> # <br> # <br> 
                </div>
            </div>
          `,
                answer: [],
            },
        ],
    },
};

export default json;
