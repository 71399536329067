import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB7-U2-P23-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page23/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the sentences with words in the box. Check your answers in <br/>the text on page 22. Then choose the correct words in the Rules.",
        color: "#1dbfd7",
      },
    ],
    hintBox: [
      {
        src: ["are becoming", "aren't speaking", "is sending", "isn't using"],
        width: 500,
        borderColor: "#1dbfd7",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 210, color: "#000", textAlign: "center" },
        answers: [
          "is sending",
          "isn't using",
          "are becoming",
          "aren't speaking",
        ],
        initialValue: [],

        textAlign: "center",
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#000",
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "things_happening_now / habits", //1
          "do / be", //2
          "ing / base", //3
          "be / live",
          "make / have_(possession)",
        ],
        answers: ["0-0", "1-4", "2-0", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
        <hintBox id='0'></hintBox>
        <div style='margin-top:15px'>
          <b>1</b>  Anna <input id='0'></input>  Lucy an instant message.<br>
          <b>2</b>  Anna <input id='1'></input>  real words.<br>
          <b>3</b>  They <input id='2'></input> a  truly international language.<br>
          <b>4</b>  We <input id='3'></input>  face-to-face.<br>
        </div>
        <div style='margin:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 15cm'>
          <div style='font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>RULES</div>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:20px'>We use the present countinuous to talk about <input id='0' type='Circle'/></div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>We make the present continuous with the verb <input id='1' type='Circle'/> and the <input id='2' type='Circle'/> form of a verb. </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:20px'>We don’t usually use some verbs in the present continuous. Two of these are <input id='3' type='Circle'/> and <input id='4' type='Circle'/></div>
          </div>
        </div>
        
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "SB7-U2-P23-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page23/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the Study Strategy. Then write the <i>-ing</i> form of the verbs. <br/>Check your answers in the text on page 22.",
        color: "#1dbfd7",
      },
    ],
    maxLength: 15,
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page23/E2/1.jpg'>
        
        <div style='display:flex'>
            <div style='line-height:50px'>
              <b>1</b>&emsp;become#<br>            
              <b>2</b>&emsp;use#<br>            
              <b>3</b>&emsp;send#<br>            
            </div>
            <div style='margin-left:50px;line-height:50px'>
              <b>4</b>&emsp;speak#<br>            
              <b>5</b>&emsp;sit#<br>            
              <b>6</b>&emsp;write#<br>            
            </div>
        </div>
        `,
        answer: [
          "becoming",
          "having",
          "sending",
          "speaking",
          "sitting",
          "writing",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB7-U2-P23-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 200,
    exerciseKey: "img/FriendsPlus/Page23/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Look at picture A. Complete sentences 1–5 using affirmative and negative forms of the present continuous",
        color: "#1dbfd7",
      },
    ],
    // hintBox: [
    //   {
    //     src: ["are becoming", "aren’t speaking", "is sending", "isn’t using"],
    //     width: 500,
    //     color: "#1dbfd7",
    //   },
    // ],
    maxLength: 15,
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img style='width:10cm' src='img/FriendsPlus/Page23/E3/1.jpg'>

          <div style='margin:40px 0px 0px 10px;line-height:50px'>
            <b>1.</b> A man, a woman and a dog#(sit) in a boat.<br>
            <b>2.</b> The man#(have) a conversation.<br>
            <b>3.</b> The woman#(listen).<br>
            <b>4.</b> She#(sleep).<br>
            <b>5.</b> The dog#(point) to a sign.<br>
          </div>
        </div>
        `,
        answer: [
          "are sitting",
          "is having",
          "isn't listening",
          "is sleeping",
          "is pointing",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB7-U2-P23-E4",
    audio: "Audios/1-17 Friends Plus 7.mp3",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 150,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='1.17'></audioimage> <span style='color:rgb(93 200 220);font-size:27px'><b>PRONUNCIATION</b> sentence stress</span>",
        color: "#1dbfd7",
      },
    ],
    // hintBox: [
    //   {
    //     src: ["are becoming", "aren’t speaking", "is sending", "isn’t using"],
    //     width: 500,
    //     color: "#1dbfd7",
    //   },
    // ],
    maxLength: 15,
    questionImage: [],
    questions: [
      {
        title: `
        <div style='width:20cm; line-height:40px'>
            When we speak, we often put stress on
            content words. Content words carry important
            information<br>
            <span style='color:gray; font-weight:800'>LINDA is DANCING. </span><br>
            <span style='color:gray; font-weight:800'>PETER is READING a STORY.</span><br>
            We don't put stress on structure words.
            Structure words make a sentence
            grammatically correct. For example, am -
            is - are are structure words in the present
            continuous tense<br>
        
            <b>Read and highlight the words we put stress
            on. then listen and check.</b><br>
            <b>1</b>  They  aren’t having a fantastic holiday!!<br>
            <b>2</b>  The man is helping the dog.<br>
            <b>3</b>  The  dog is swimming.<br>
            <b>4</b>  The woman isn’t sleeping.<br>
            <b>5</b>  The man is speaking on the phone.<br>
        </div>
        `,
        answer: [
          "are sitting",
          "is having",
          "isn't listening",
          "is sleeping",
          "+",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB7-U2-P23-E5",
    video: "",
    audio: "",
    component: UI,
    titleQuestion: [
      {
        num: "5",
        title:
          "Look at picture B. Read the sentences from exercise 4. Say if the sentences are True or False. ",
        color: "#1dbfd7",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    recorder: true,

    questionImage: [[{ url: "/img/FriendsPlus/Page23/E5/1.jpg" }]],
  },
  6: {
    unit: "Unit 2",
    id: "SB7-U2-P23-E6",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700 },
    hideBtnFooter: true,
    finished: {
      text: "Find another picture in this book and describe it to your partner.",
    },
    questions: [
      {
        title: `
        <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
