import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  12: {
    unit: "Unit 2",
    id: "SB7-U2-P33-E12",
    audio: "",
    video: "",
    component: T6,
    inputSize: 170,
    checkUppercase: true,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page33/E12/Key/answerKey.png",
    titleQuestion: [
      {
        num: "12",
        title:
          "<b>Complete the sentences using the present simple or present continuous form of the verbs.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
					<div><b>1</b> We # (not have) homework on Tuesdays.</div>
					<div><b>2</b> Jo and Clare # (do) the exam now.</div>
					<div><b>3</b> Where’s Ilya? # he # (listen) to some music in his room?</div>
					<div><b>4</b> I always # (go) to bed at 9:30.</div>
					<div><b>5</b> Hoa is on her laptop now. She # (not help) with the housework.</div>
					<div><b>6</b> What time # you # (start) school every morning?</div>
        `,
        answer: [
          "don't have",
          "are doing",
          "Is",
          "listening ",
          "go",
          "isn't helping",
          "do",
          "start",
        ],
      },
    ],
  },
  13: {
    unit: "Unit 2",
    id: "SB7-U2-P33-E13",
    audio: "",
    video: "",
    component: T6,
    inputSize: 320,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page33/E13/Key/answerKey.png",
    titleQuestion: [
      {
        num: "13",
        title:
          "<b>Complete the phone conversation then practise with your partner.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='border-width: 2px; border-style: solid; border-radius: 8px; border-color: rgb(136,137,139); text-align: center; padding: 0 20px; margin-top: 20px; width: 75%;'>
            Are you interested?<br />How are things?<br />Text me when you arrive.<br />What time is the match?<br />What are you doing?
          </div>
          <div style='display: flex;'>
            <div>
              <div><b>Mark</b>&emsp;</div>
              <div><b>Andy</b>&emsp;</div>
              <div><b>Mark</b>&emsp;</div>
              <div><b>Andy</b>&emsp;</div>
              <div><b>Mark</b>&emsp;</div>
              <br />
              <div><b>Andy</b>&emsp;</div>
              <div><b>Mark</b>&emsp;</div>
              <div><b>Andy</b>&emsp;</div>
              <div><b>Mark</b>&emsp;</div>
              <div><b>Andy</b>&emsp;</div>
              <div><b>Mark</b>&emsp;</div>
            </div>
            <div>
              <div>Hi, Andy. It’s Mark.</div>
              <div>Oh, hi! <sup>1</sup> #.</div>
              <div>Good, thanks. Are you at home?</div>
              <div>Yes. Why?</div>
              <div>I’m walking into town with Ben. We are thinking of going to that football match.<br /><sup>2</sup> #</div>
              <div>I can’t right now.</div>
              <div><sup>3</sup> #</div>
              <div>Nothing special. I’m helping my dad clean the car. <sup>4</sup> #</div>
              <div>At 3:00.</div>
              <div>Oh, 3.00? That’s OK. The car is nearly finished.</div>
              <div>OK! <sup>5</sup> #</div>
            </div>
          </div>
        `,
        answer: [
          "How are things?",
          "Are you interested?",
          "What are you doing?",
          "What time is the match?",
          "Text me when you arrive.",
        ],
      },
    ],
  },
  14: {
    unit: "Unit 2",
    id: "SB7-U2-P33-E14",
    audio: "",
    video: "",
    component: T6,
    inputSize: 39,
    maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page33/E14/Key/answerKey.png",
    titleQuestion: [
      {
        num: "14",
        title: "<b>Put the paragraphs in the correct order.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display:flex">
            <div><img style="width: 74%" src='img/FriendsPlus/Page33/E14/1.jpg' /></div>
            <div>
              <div><b>1</b> #</div>
              <div><b>2</b> #</div>
              <div><b>3</b> #</div>
              <div><b>4</b> #</div>
              <div><b>5</b> #</div>
            </div>
          </div>
        `,
        answer: ["E", "A", "C", "B", "D"],
      },
    ],
  },
};

export default json;
