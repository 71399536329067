// TEMPLATE
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Option 2",
    id: "SB7-O2-P107-E1",
    exerciseKey: "img/FriendsPlus/Page107/E1/Key/answerKey.png",
    audio: "",
    video: "",
    component: MatchDots,
    // titleQuestion: [{num: "3", title: "Match 1–5 with a–e to make sentences about the person in the song.", color: "#dd2d38",}],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "78px",
              left: "207px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "114px",
              left: "276px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // b

          {
            boxMatchStyle: {
              position: "absolute",
              top: "114px",
              left: "207px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "155px",
              left: "276px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // c

          {
            boxMatchStyle: {
              position: "absolute",
              top: "155px",
              left: "207px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "195px",
              left: "276px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // d

          {
            boxMatchStyle: {
              position: "absolute",
              top: "195px",
              left: "207px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "232px",
              left: "276px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // e

          {
            boxMatchStyle: {
              position: "absolute",
              top: "232px",
              left: "207px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "78px",
              left: "276px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // a

          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "275px",
          //     left: "430px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #F58024",
          //     background: "white",
          //   },
          // }, // 6
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "275px",
          //     left: "530px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #F58024",
          //     background: "white",
          //   },
          // }, // f
        ],
        answers: ["0-1", "2-3", "4-5", "6-7", "8-9"],
        initialValue: ["0-1"],
        // answers: ["0-1", "2-3", "4-5", "6-7", "8-9", "10-11"],
        // initialValue: ["0-1"],
      },
      Layout: `
            <div style="width: 700px;">
              <div style="font-size: 26px;">
                <strong>
                  <span style="color: #dd2d38;">1</span>
                  Match types of information 1-5 with details a-e.

                </strong>
              </div>
  
              <div style="font-size: 26px; margin-top: 20px; display: flex;">
                <div>
                  <div><strong>1</strong> phone number</div>
                  <div><strong>2</strong> time</div>
                  <div><strong>3</strong> date</div>
                  <div><strong>4</strong> price</div>
                  <div><strong>5</strong> address</div>
                </div>
  
                <div>
                  <input id='0' type= 'boxMatch' />
                  <input id='1' type= 'boxMatch' />
                  <input id='2' type= 'boxMatch' />
                  <input id='3' type= 'boxMatch' />
                  <input id='4' type= 'boxMatch' />
                </div>
  
                <div style="margin-left: 104px">
                  <div><strong>a.</strong> 34 Eastfield Road</div>
                  <div><strong>b.</strong> 0791324058</div>
                  <div><strong>c.</strong> 1.40 p.m.</div>
                  <div><strong>d.</strong> 23 March</div>
                  <div><strong>e.</strong> £46.50</div>
                </div>
  
                <div>
                  <input id='5' type= 'boxMatch' />
                  <input id='6' type= 'boxMatch' />
                  <input id='7' type= 'boxMatch' />
                  <input id='8' type= 'boxMatch' />
                  <input id='9' type= 'boxMatch' />
                </div>
              </div>
            </div>
            <br/><br/><br/>
          `,

    },
  },

  2: {
    unit: "Option 2",
    id: "SB7-O2-P107-E2",
    exerciseKey: "img/FriendsPlus/Page107/E2/Key/answerKey.png",
    audio: "Audios/3-05 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 320,
    maxLength: 24,
    titleQuestion: [
      {
        num: "2",
        title: `
                <audioimage name='3.05'></audioimage>
                Listen and write what you hear.
                Then listen again and repeat.
            `,
        color: "#f77e1d",
      },
    ],
    questions: [
      {
        title: `
                <div style="margin-top: 20px; width: 700px">
                    <ol>
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                    </ol>
                </div>
            `,

        answer: [
          'a quarter past nine',
          'four pounds seventy-five',
          '183, West Bridge Street',
          'ninety-nine pence',
          'the twentieth of June',
          '0909 870413',
        ],

      },
    ],

  },

  3: {
    unit: "Option 2",
    id: "SB7-O2-P107-E3",
    exerciseKey: "img/FriendsPlus/Page107/E3/Key/answerKey.png",
    audio: "Audios/3-06 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 150,
    maxLength: 10,
    stylesTextInput: {
      fontSize: 23,
    },
    titleQuestion: [
      {
        num: "3",
        title: `
                <audioimage name='3.06'></audioimage> 
                Listen to a telephone conversation and message. 
                Which numbers from exercise 1 do you hear?
              `,
        color: "#f77e1d",
      },
    ],
    questions: [
      {
        title: `
                    <div style="width: 600px; margin-top: 30px;">
                        <img src="img/FriendsPlus/Page107/E3/1.jpg" style="max-width: 100%" />
                        
                        <p style="margin-top: 30px">Answer: #</p>
                    </div>
                `,
        answer: [
          '0791324058',
        ],
      },
    ],
  },

  4: {
    unit: "Option 2",
    id: "SB7-O2-P107-E4",
    exerciseKey: "img/FriendsPlus/Page107/E4/Key/answerKey.png",
    audio: "Audios/3-06 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 450,
    maxLength: 40,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 24,
    },
    titleQuestion: [
      {
        num: "4",
        title: `
                <audioimage name='3.06'></audioimage> 
                Listen to the telephone conversation
                and message again and answer the questions.
              `,
        color: "#f77e1d",
      },
    ],
    questions: [
      {
        title: `
                    <div style="width: 680px">
                        <ol style='margin-top: 30px'>
                            <li>Where is Sophie's dad when Dave phones? #</li>
                            <li>What message does Sophie take? #</li>
                            <li>Where are Dave and Alex going this afternoon? #</li>
                            <li>What time does Alex need to be at Oliver's house? #</li>
                        </ol>
                    </div>  
                `,

        answer: [
          "He's at the supermarket.",
          "Can her dad call Dave on his mobile.",
          "They're going to a football match.",
          "He needs to be at Oliver's house at two.",
        ],

      },
    ],

  },

  5: {
    unit: "Option 2",
    id: "SB7-O2-P107-E5",
    exerciseKey: "img/FriendsPlus/Page107/E5/Key/answerKey.png",
    audio: "Audios/3-06 Friends Plus 7.mp3",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "5",
        title: `
                <audioimage name='3.06'></audioimage>
                Listen again and choose the correct words in the Key Phrases.
            `,
        color: "#dd2d38",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#00aeef",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          'speak / talk',
          'away / out',
          'leave / take',
          'call / phone',
        ],
        answers: ["0-0", "1-4", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
            <div style="width: 600px">
                <div style="margin-top: 20px; width: 480px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                    <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                        Key Phrases
                    </div>
                    
                    <div style="margin-top: 10px; padding-left: 20px;">
                        <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                            Leaving a message
                        </div>

                        <div>
                            Can I <strong><input id='0' type='Circle' /></strong> to your dad, please? <br/>
                            He's <strong><input id='1' type='Circle' /></strong> at the moment. <br/>
                            Can I <strong><input id='2' type='Circle' /></strong> a message? <br/>
                            Can you ask him to <strong><input id='3' type='Circle' /></strong> me? <br/>
                        </div>
                    </div>
                </div>
            </div>
          `,

    },
  },

  6: {
    unit: "Option 2",
    id: "SB7-O2-P107-E6",
    exerciseKey: "img/FriendsPlus/Page107/E6/Key/answerKey.png",
    audio: "Audios/3-07 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 340,
    maxLength: 31,
    stylesTextInput: {
      fontSize: 23,
    },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "6",
        title: `
                    <audioimage name='3.07'></audioimage>
                    Read the message and listen to the dialogue. 
                    Then practise the dialogue with a partner.
                `,
        color: "#f77e1d",
      },
    ],
    questions: [
      {
        title: `
                    <div style="width: 600px;">
                        <div style="width: 600px; display: flex; justify-content: center; align-items: center;">
                            <img src="img/FriendsPlus/Page107/E6/1.jpg" style="width: 60%;" />
                        </div>

                        <div style="margin-top: 5px;">
                            <div style="display: flex;">
                                <span style="margin-right: 25px">Luke's mum</span>
                                <span>Hello.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 87px">Daniel</span>
                                <span>
                                    Hi, it's Daniel here. Can I speak to Luke, please?
                                </span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Luke's mum</span>
                                <span>Sorry, he's out at the moment. <br/>Can I take a message?</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 87px">Daniel</span>
                                <span>
                                    Yes, please. Can you tell him that 
                                    I'm meeting the girls at eight
                                    o'clock at the cinema?
                                </span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Luke's mum</span>
                                <span>
                                    OK, meeting the girls … eight o'clock …<br/> cinema.
                                </span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 87px">Daniel</span>
                                <span>That's right.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Luke's mum</span>
                                <span>OK, I'll give him the message.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 87px">Daniel</span>
                                <span>Thanks. Bye.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Luke's mum</span>
                                <span>Bye.</span>
                            </div>
                        </div>
                    </div>
                `,
        answer: [
          "How can I help you?",
          "I'd like to make a complaint",
          "there's something wrong with it",
          "I'm terribly sorry about that",
          "I'm afraid we can't change it",
          "it doesn't work.",
        ],
      },
    ],

  },

  7: {
    unit: "Option 2",
    id: "SB7-O2-P107-E7",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    inputSize: 600,
    maxLength: 100,
    textareaStyle: { width: 1000 },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "7",
        title: `
                <audioimage name='3.06'></audioimage>
                Work in pairs. Prepare telephone conversations using the 
                information in the messages below and the Key Phrases. 
                Then practise your conversations with your partner.
            `,
        color: "#f77e1d",
        prefix: "default",
      },
    ],
    questions: [
      {
        title: `
                <div style="margin-top: 20px; width: 600px;">
                    <div style=" display: flex; justify-content: center; align-items: center;">
                        <img src="img/FriendsPlus/Page107/E7/1.jpg" style="width: 70%;" />
                    </div>
                </div>
            `,
        answer: [],
      },
    ],
  },

};

export default json;