import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 4',
    id: 'SB7-U4-P44-E1',
    audio: 'Audios/1-39 Friends Plus 7.mp3',
    video: '',
    component: T6,
    inputSize: 140,
    exerciseKey: 'img/FriendsPlus/Page44/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title:
          `<audioimage name='1.39'></audioimage> Match pictures 1–10 with the verbs in the box. Then listen and check.`,
        color: '#0067b4',
      },
    ],
    isHiddenCheck: true,
    hintBox: [
      {
        src: [
          'climb up',
          'hang from',
          'hold',
          'jump up',
          'kick',
          'lie on',
          'sit on',
          'stand in',
          'pick up',
          'walk on',
        ],
        borderColor: '#468dcb',
        width: 550,
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; justify-content: center;"><hintbox id='0'></hintbox></div>
          <div style='display: flex; gap: 50px'>
            <div>
              <img src='img/FriendsPlus/Page44/E1/1.jpg' />
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page44/E1/2.jpg' />
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page44/E1/3.jpg' />
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page44/E1/4.jpg' />
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page44/E1/5.jpg' />
              <div>#</div>
            </div>
          </div>
          <div style='display: flex; gap: 50px'>
            <div>
              <img src='img/FriendsPlus/Page44/E1/6.jpg' />
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page44/E1/7.jpg' />
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page44/E1/8.jpg' />
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page44/E1/9.jpg' />
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page44/E1/10.jpg' />
              <div>#</div>
            </div>
          </div>
        `,
        answer: [
          'climb up',
          'jump up',
          'lie on',
          'kick',
          'pick up',
          'stand in',
          'sit on',
          'walk on',
          'hold',
          'hang from',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'SB7-U4-P44-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page44/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title:
          'Read the descriptions of four photos in the observation test and match descriptions 1–4 with four photos from A–F. Then choose the correct verbs in <span style="color: #00aeef;">blue</span>.',
        color: '#0067b4',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: ['D', 'B', 'C', 'A'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#00aeef',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: [
          'picking_up / sitting_on', //1
          'hanging_from / lying_on', //2
          'standing_in / walking_on', //3
          'climbing_up / kicking',
          'climbing_up / holding',
        ],
        answers: ['0-0', '1-4', '2-4', '3-4', '4-4'],
        initialValue: [],
      },
      Layout: `
        <h1>OBSERVATION  TEST</h1>
        <div style='display: flex;'>
          <b>1. </b>
          <div>This is brilliant. The boy is on the beach and he's <input id='0' type='Circle' /> his head, which is <input id='1' type='Circle' /> the sand. <input id='0'/></div>
        </div>
        <div style='display: flex;'>
          <b>2. </b>
          <div>They’re in the desert, or somewhere very hot. The woman is wearing a hat and there’s a tiny man on it. He’s <input id='2' type='Circle' /> the hat. <input id='1'/></div>
        </div>
        <div style='display: flex;'>
          <b>3. </b>
          <div>The man is <input id='3' type='Circle' /> the dinosaur. He’s very brave because he’s really small and the dinosaur is enormous and very angry! <input id='2'/>
          </div>
        </div>
        <div style='display: flex;'>
          <b>4. </b>
          <div>The man is in a park and he’s <input id='4' type='Circle' /> a really small woman in his hand. She’s wearing a white dress. Are they getting married? <input id='3'/>
          </div>
        </div>
        <div style='display: flex; width: 60%'>
          <img src='img/FriendsPlus/Page44/E2/1.jpg' />
          <img src='img/FriendsPlus/Page44/E2/2.jpg' />
        </div>
      `,
    },
  },
};

export default json;
