import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  3: { // Exercise num
    unit: "Unit 4",
    id: 'SB5-U4-P45-E3',
    audio: "Audios/1-40 Friends Plus 7.mp3",
    video: "https://cdn.sachso.edu.vn/videos/7sb/Talking Heads/U4-1.40- Trang 45.mp4",
    exerciseKey: 'img/FriendsPlus/Page45/E3/Key/answerKey.png',
    inputSize: 74,
    maxLength: 1,
    stylesTextInput: { textTransform: 'uppercase' },
    component: T6,
    titleQuestion: [{
      num: '3',
      title: "<videoimage></videoimage><audioimage name='1.40'></audioimage> Watch or listen.Which photos are the speakers describing?",
      color: "#0067b4"
    }],
    questions: [{
      title: `
        &emsp;1. # &emsp;2. # &emsp;3. # &emsp;4. #
      `,
      answer: ['F', 'E', 'F', 'E']
    }]
  },
  4: {
    // Exercise num
    unit: "Unit 4",
    id: "SB7-U4-P45-E4",
    audio: "Audios/1-40 Friends Plus 7.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page45/E4/Key/answerKey.png",
    inputSize: 120,
    maxLength: 36,
    stylesTextInput: {
      borderBottom: "1px dotted",
      textAlign: "center",
      fontSize: 23
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: "<videoimage></videoimage><audioimage name='1.40'></audioimage>Watch or listen again. Which of the key phrases do you hear? Who says them?",
        color: "#0067b4",
      },
    ],
    questionImage: [],
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style='background: rgb(214, 226, 242); border-radius: 15px; max-width: 550px'>
          <div style='color:white;border-radius:15px;background:rgb(108, 157, 211);padding:10px; font-size: 27px'><b>KEY PHRASES</b></div>
          <div style='margin-right: 10px; padding: 10px 10px 20px'>
            <div style="margin-right: 10px; color: rgb(68, 139, 202); font-weight: bold">Describing a photo</div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>In the middle <img style="width: 10%" src='img/FriendsPlus/Page45/E4/1.jpg' /><input id='0'/> </div></div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>In front of <img style="width: 10%" src='img/FriendsPlus/Page45/E4/2.jpg' /><input id='1'/></div></div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>Behind <img style="width: 15%" src='img/FriendsPlus/Page45/E4/3.jpg' /><input id='2'/> </div></div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>On the left / On the right <img style="width: 20%" src='img/FriendsPlus/Page45/E4/4.jpg' /><input id='3'/> </div></div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>At the top / At the bottom <img style="width: 20%" src='img/FriendsPlus/Page45/E4/5.jpg' /><input id='4'/> </div></div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>In the foreground / In the background <img style="width: 20%" src='img/FriendsPlus/Page45/E4/6.jpg' /><input id='5'/> </div></div>
          </div>
        </div>
          `,
        answer: [
          "Lilly",
          "Elijah",
          "Rebecca",
          "Lilly",
          "none",
          "Rebecca",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 4",
    id: 'SB7-U4-P45-E5',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1080 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          " Work in a group.Use the Key Phrases to create your own photograph.",
        color: "#0067b4",
        prefix: "default"
      },
    ],
    hintBox: [
      {
        src: ["Maria, stand here in the foreground. Hold this cup. David and Juan, sit on the chairs at the back, so it looks like you’re standing in the cup."],
        borderColor: "#0067b4",
        arrow: true,
        position: 1,
        width: 620,
      },
    ],
    questions: [
      {
        title: `
            <div style="text-align: center"><hintBox id='0'></hintBox>
			  		<div style="margin-top:30px"><textarea id="0" rows="7" ></textarea></div>
			`,
        answer: []
      },
    ]
  },

  6: {
    unit: "Unit 4",
    id: 'SB7-U4-P45-E6',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1050 },
    hideBtnFooter: true,
    finished: {
      text: "Think of your favourite photo on your phone. Describe it to your group."
    },
    questions: [
      {
        title: `
          <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: []
      },
    ]
  },



}

export default json;
