import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-U78-E1',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page78/E1/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '1',
        title: 'Complete the imperative sentences with the verbs in the box. ',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 150,
    checkUppercase: true,
    hintBox: [
      {
        src: [
          'century',
          'fraction',
          'million',
          'kilo',
          'kilometre',
          'metres',
          'month',
          'quarter',
          'seconds',
          'millennium',
        ],
        borderColor: '#333',
        width: 600,
      },
    ],
    questions: [
      {
        title: `
        <div style='margin: 20px 0 20px 0;'><hintbox id= '0' ></hintbox></div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div>‘What is the population of Việt Nam?’ </div>
            <div>‘I guess it’s more than 98 # people.’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div>‘What’s the hottest # in your country?’</div>
            <div>‘I know that one. It’s July, or perhaps August.’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div>‘What is 25% as a # ?’</div>
            <div> ‘We think it’s a # .’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
            <div>‘How far is the station from here?’</div>
            <div>‘It isn’t very far. I’d say it’s only about a #.’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>
            <div>‘Why was the year 2000 important?’</div>
            <div>‘It was the start of the new # .’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>
            <div>‘What do we need to make this big cake?’</div>
            <div>‘I reckon we need about a # of sugar.’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 7 </b></div>
          <div>
            <div>‘How fast can you run 100 # ?’</div>
            <div>‘I can usually run it in 17 # .’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 8 </b></div>
          <div>
            <div>‘When was the artist Vincent Van Gogh born?’</div>
            <div>‘Maybe it was in the 19th #, but I’m not sure.’</div>
          </div>
        </div>

        `,
        answer: [
          'million',
          'month',
          'fraction',
          'quarter',
          'kilometre',
          'millennium',
          'kilo',
          'metres',
          'seconds',
          'century',
        ],
      },
    ],
  },

  2: {
    // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P78-E2',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page78/E2/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the words.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 40,
    //hiện icon đáp án đúng
    isHiddenCheck: true,
    // chữ hoa khác chữ thường
    checkUppercase: true,

    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div><b>Bo</b>##<b>bu</b>##### should have a good diet.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div><b>E</b>### should be in a body builder's breakfast and snacks.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>Many <b>ca</b>###### should be in a basketballer's meals.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>They often eat bananas be cause of their good <b>nu</b>####### .</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>Bananas have potassium and it <b>re</b> ##### a sudden pain in muscles.</div>
        </div>
        `,
        answer: [
          'd',
          'y',
          'i',
          'l',
          'd',
          'e',
          'r',
          'g',
          'g',
          's',
          'l',
          'o',
          'r',
          'i',
          'e',
          's',
          't',
          'r',
          'i',
          'e',
          'n',
          't',
          's',
          'd',
          'u',
          'c',
          'e',
          's',
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P78-E3',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page78/E3/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '3',
        title: 'Complete the sentences by making comparisons.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 240,
    checkUppercase: true,

    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>This book about football stars is similar # the book I bought last week.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>That volleyball player was amazing. He was # (fast) as a tiger.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>The seats in this stadium are great. They are # (comfortable) than our chairs.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>Robbie gets up late and he never does any sport. He’s # (lazy) person I know.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>The new swimming pool is very large. It’s different # the old pool.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>This race is very difficult. It’s not # (easy) as the long race last year.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 7 </b></div>
          <div>That match is bad! It doesn't attract # (many) viewers as the one I saw.</div>
        </div>
        `,
        answer: ['to', 'as fast', 'more comfortable', 'the laziest', 'from', 'as easy', 'as many'],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P78-E4',
    audio: 'Audios/2-12 Friends Plus 7.mp3',
    video: '',
    component: T6,
    checkUppercase: true,
    selectStyle: { color: '#00ADFE', width: 80, textAlign: 'center' },
    selectOptionValues: ['✓', '✗'],
    exerciseKey: 'img/FriendsPlus/Page78/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title:
          "<audioimage name='2.12'></audioimage>Listen to the radio programme. Then put a check (✓) or a cross (✗) the box.",
        color: '#50419c',
      },
    ],

    questions: [
      {
        title: `
        <div style='display: flex;'>
            <div>
              <div>What was Jacques Cousteau?</div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <select id='0'></select>
                <div style='margin-left: 10px;'> an expert in the underwater world</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <select id='1'></select>
                <div style='margin-left: 10px;'> a mathematician</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 </b></div>
                <select id='2'></select>
                <div style='margin-left: 10px;'> an inventor</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 4 </b></div>
                <select id='3'></select>
                <div style='margin-left: 10px;'> a scientist</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 5 </b></div>
                <select id='4'></select>
                <div style='margin-left: 10px;'> a composer</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 6 </b></div>
                <select id='5'></select>
                <div style='margin-left: 10px;'> a writer</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 7 </b></div>
                <select id='6'></select>
                <div style='margin-left: 10px;'> an artist</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 8 </b></div>
                <select id='7'></select>
                <div style='margin-left: 10px;'> a photographer</div>
              </div>
            </div>
            <div><img src='img/FriendsPlus/Page78/E4/1.png'/></div>
        </div>
        
        
				`,
        answer: ['✓', '✗', '✓', '✓', '✗', '✓', '✗', '✓'],
      },
    ],
  },
};
export default json;
