import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'SB7-U3-P36-E1',
    component: T6,
    inputSize: '440px',
    exerciseKey: 'img/FriendsPlus/Page36/E1/Key/answerKey.png', 
    titleQuestion: [
      {
        num: "1",
        title:
          `Look at the photos on this page. Answer the questions. Then read the reports <br/>and check your answers.`,
        color: "#da2c37",
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div><img style="width: 120%" src="img/FriendsPlus/Page36/E1/1.jpg"/></div>
          <div><b>1.</b> What are the objects in the photos?</div>
          <div>#</div>
          <div><b>2.</b> How old do you think the Bluebird is?</div>
          <div>#</div>
          <div><b>3.</b> Can you think of words to describe the objects in the photos?</div>
          <div>#</div>
        </div>
        `,
        answer: ['clothes, dinosaur bones, cars', 'car from 1964', '']
      }
    ]
  },
  2: {
    unit: 'Unit 3',
    id: 'SB7-U3-P36-E2',
    component: T6,
    inputSize: '245px',
    stylesTextInput: { textAlign: 'center' },
    exerciseKey: 'img/FriendsPlus/Page36/E2/Key/answerKey.png',
    audio: 'Audios/1-29 Friends Plus 7.wav',
    titleQuestion: [
      {
        num: "2",
        title:
          `<audioimage name='1.29'></audioimage> Read and listen to the reports. Complete the sentences with the information <br/>from the text.`,
        color: "#da2c37",
      },
    ],
    questions: [
      {
        title: `
        <div style="font-size: 20px">
          <div style=""><img style="width: 90%" src="img/FriendsPlus/Page36/E2/1.jpg"/></div>
          <div><b>1.</b> The exhibition of # was in the History Museum of Hồ Chí Minh City.</div>
          <div><b>2.</b> The clothes were expensive but #.</div>
          <div><b>3.</b> Tim was happy with the food in # at the #.</div>
          <div><b>4.</b> # and # are both types of dinosaur.</div>
          <div><b>5.</b> The # wasn't a very interesting place</div>
          <div><b>6.</b> # was a very fast car in # Museum.</div>
        </div>`,
        answer: ['royal clothing', 'uncomfortable', 'café', 'Natural History Museum', 'Diplodocus', 'Tyrannosaurus Rex', 'farm', 'Bluebird', 'National Motor']
      }
    ]
  },
  3: {
    unit: 'Unit 3',
    id: 'SB7-U3-P36-E3',
    component: T6,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "3",
        title:
          `
          <b style="color: #da2c37">VOCABULARY PLUS</b> Use a dictionary to check 
the meaning of the words in <span style="color: #40a9ff">blue</span> in the text
`,
        color: "#da2c37",
      },
    ],
    questions: [
      {
        title: `
        <div style="text-align: center"><img style="width: 85%" src="img/FriendsPlus/Page36/E3/1.jpg"/></div>
      `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'SB7-U3-P36-E4',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1080 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          " <b><span style='color: #da2c37'>USE IT!</b></span> Work in pairs. Which of the museums on the website do you think is the most interesting? Where is it? What is in it?",
        color: "#da2c37",
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-top: 20px"><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: []
      },
    ]
  },


}

export default json;