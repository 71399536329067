import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB7-U1-P19-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 230,
    maxLength: 17,
    exerciseKey: "img/FriendsPlus/Page19/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "FIND THE PREPOSITION. Work in groups. Look at the pictures and say where the person is. Use <i>at, in</i> and <i>on</i>. Find the four pictures that use the same preposition.",
        color: "#1dbfd7",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;margin-bottom: 10px;justify-content: space-between;'>
            <div>
              <img src='img/FriendsPlus/Page19/E1/1.jpg'>
              <div>#</div>
            </div>
            <div style='margin:0px 70px'>
              <img src='img/FriendsPlus/Page19/E1/2.jpg'>
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page19/E1/3.jpg'>
              <div>#</div>
            </div>
          </div>
          <div style='margin-bottom: 10px;display: flex;justify-content: space-between;'>
            <div>
              <img src='img/FriendsPlus/Page19/E1/4.jpg'>
              <div>#</div>
            </div>
            <div style='margin:0px 70px'>
              <img src='img/FriendsPlus/Page19/E1/5.jpg'>
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page19/E1/6.jpg'>
              <div>#</div>
            </div>
          </div>
          <div style='margin-bottom: 10px;display: flex;justify-content: space-between;'>
            <div>
              <img src='img/FriendsPlus/Page19/E1/7.jpg'>
              <div>#</div>
            </div>
            <div style='margin:0px 70px'>
              <img src='img/FriendsPlus/Page19/E1/8.jpg'>
              <div>#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page19/E1/9.jpg'>
              <div>#</div>
            </div>
          </div>
            <div style='width:65mm'>
              <img src='img/FriendsPlus/Page19/E1/10.jpg'>
              <div>#</div>
            </div>
        `,
        answer: [
          "at home",
          "on the beach",
          "on the bus",
          "in the playground",
          "at school",
          "in bed",
          "at the shops",
          "in the cars",
          "on the sofa",
          "in the park",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB7-U2-P19-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "GUESS THE FAMOUS PERSON. Work in pairs. Follow the instructions. ",
        color: "black",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page19/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 1",
    id: "SB7-U1-P19-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 190,
    maxLength: 17,
    checkDuplicated: true,
    exerciseKey: "img/FriendsPlus/Page19/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Find nine more free time activities in the puzzle. Use two squares for each one.",
        color: "black",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <img style='margin-left:0px' src='img/FriendsPlus/Page19/E3/1.jpg'>
          <div style='margin-top:10px; line-height:50px'>
          <b>go shopping</b>
            <div style='display:flex; justify-content: space-between;'>#&emsp;#&emsp;#</div>
            <div style='display:flex; justify-content: space-between;'>#&emsp;#&emsp;#</div>
            <div style='display:flex; justify-content: space-between;'>#&emsp;#&emsp;#</div>
          </div>
        `,
        answer: [
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
          "meet friends / go dancing / listen to music / do sport / bake cakes / write stories / paint a picture / go online / watch TV",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB7-U2-P19-E4",
    audio: "Audios/1-17 Friends Plus 7.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "MAKE SENTENCES. Work in group. Follow the instructions.",
        color: "black",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page19/E4/1.jpg" }]],
  },
  5: {
    unit: "Unit 1",
    id: "SB7-U1-P19-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 153,
    maxLength: 17,
    checkDuplicated: true,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page19/E5/Key/answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title:
          "WORDSNAKE. Work in pairs. Find the question words and complete the questions. Ask and answer the questions.",
        color: "black",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='margin:10px 50px; line-height:50px; background:rgb(123 209 232); padding:20px 30px; border-radius:40px'>
          <img src='img/FriendsPlus/Page19/E5/1.jpg'>
          <div><u style="color: gray;font-style: italic;text-decoration: underline">&emsp;How many&emsp;</u>does your mother speak?</div>
          <div><b>1</b> #time do you get up on Saturdays?</div>
          <div><b>2</b> #do you go to the cinema?</div>
          <div><b>3</b> #does your uncle live?</div>
          <div><b>4</b> #do you do your homework?</div>
          <div><b>5</b> #do you talk to on the phone most often?</div>
        </div>
        `,
        answer: ["What", "How often", "Where", "When", "Who"],
      },
    ],
  },
};

export default json;
