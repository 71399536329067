import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P64-E1",
    audio: "Audios/1-59 Friends Plus 7.mp3",
    video: "/Videos/Speaking/U5-1.59-Trang 64.mp4",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page64/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "<videoimage></videoimage><audioimage name='1.59'></audioimage> Choose the correct words in the dialogue. Then watch or listen and check. What does Kim want to learn?",

        color: "",
      },
    ],

    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0 5px",
          fontWeight: 600,
          color: "black",
          fontSize: 24,
          borderRadius: "50%",
        },
        selectWordStyle: { border: "2px solid #da1f73" },
        limitSelect: 1,
        listWords: [
          "awful /  amazing",
          "salsa / guitar",
          "leave / have",
          "money / time",
          "teacher / friend",
          "fun / weird",
        ],
        answers: ["0-6", "1-0", "2-4", "3-0", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div>
      <div style='display: flex; margin-top: 20px; justify-content: space-between; background-color: rgb(212, 227, 233); padding: 30px; border-radius: 50px'>
      <div>
        <div style='display: flex;'>
          <div><b>Kim</b></div>
          <div style='margin-left: 26px;'>That’s <sup>1</sup><input id='0' type='Circle' />, Joe. When did you learn how to dance like that?  </div>
        </div>
        <div style='display: flex;'>
          <div><b>Joe</b></div>
          <div style='margin-left: 30px;'>I had lesson at my old school. Do you like dancing?</div>
        </div>
        <div style='display: flex;'>
          <div><b>Kim</b></div>
          <div style='margin-left: 26px;'>I love it, but I’m no good. I really want to learn to dance <sup>2</sup><input id='1' type='Circle' />  </div>
        </div>
        <div style='display: flex;'>
          <div><b>Joe</b></div>
          <div style='margin-left: 30px;'>Yeah?</div>
        </div>
        <div style='display: flex;'>
          <div><b>Kim</b></div>
          <div style='margin-left: 26px;'>What’s the best way to learn?</div>
        </div>
        <div style='display: flex;'>
          <div><b>Joe</b></div>
          <div style='margin-left: 30px;'>It’s probably best to <sup>3</sup><input id='2' type='Circle' />lesson. I’m sure there are lots of salsa classes.</div>
        </div>
        <div style='display: flex;'>
          <div><b>Kim</b></div>
          <div style='margin-left: 26px;'>Yeah, but i haven’t got any <sup>4</sup><input id='3' type='Circle' /> for lessons</div>
        </div>
        <div style='display: flex;'>
          <div><b>Joe</b></div>
          <div style='margin-left: 30px;'>Well, you can watch videos on YouTube.</div>
        </div>
        <div style='display: flex;'>
          <div><b>Kim</b></div>
          <div style='margin-left: 26px;'>I suppose so. But I need people to dance with. You can’t dance salsa alone!</div>
        </div>
        <div style='display: flex;'>
          <div><b>Joe</b></div>
          <div style='margin-left: 30px;'>Well, you cam find a <sup>5</sup><input id='4' type='Circle' /> to learn with you.</div>
        </div>
        <div style='display: flex;'>
          <div><b>Kim</b></div>
          <div style='margin-left: 26px;'>That’s a good idea ...</div>
        </div>
        <div style='display: flex;'>
          <div><b>Joe</b></div>
          <div style='margin-left: 30px;'>I’d like to learn salsa.</div>
        </div>
        <div style='display: flex;'>
          <div><b>Kim</b></div>
          <div style='margin-left: 26px;'>Really?</div>
        </div>
        <div style='display: flex;'>
          <div><b>Joe</b></div>
          <div style='margin-left: 30px;'>Yeah, It looks <sup>6</sup><input id='5' type='Circle' />.</div>
        </div>
        <div style='display: flex;'>
          <div><b>Kim</b></div>
          <div style='margin-left: 26px;'>Great!</div>
        </div>
      </div>
      <div>
        <img src='img/FriendsPlus/Page64/E1/1.jpg' />
      </div>
    </div>
       
      `,
    },
  },

  2: {
    unit: "Unit 4",
    id: "SB7-U4-P64-E2",
    audio: "Audios/1-59 Friends Plus 7.mp3",
    video: "/Videos/Speaking/U5-1.59-Trang 64.mp4",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 440,
    maxLength: 36,
    exerciseKey: "img/FriendsPlus/Page64/E2/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "<videoimage></videoimage><audioimage name='1.59'></audioimage> Cover the dialogue and complete the Key Phrases. Watch or listen again and check.",
        color: "#da1f73",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='padding:8mm  3mm;width:20cm; margin-top: 50px; border-radius: 30px; background:rgb(246 211 218)'>
            
            <div style='display:flex; color: white; background:rgb(226 115 148); padding: 10px; margin: -1cm -11px 5mm -11px; border-radius: 20px'>
              <b style='font-size:30px'> KEY PHRASES </b> 

            </div>
            <b style='color:rgb(220 45 119)'>Making and responding to suggestions(2)</b>
             <div style='line-height:50px'>
               What’s the best way <sup>1</sup>#?   <br>    
               It’s probably best to <sup>2</sup>#?   <br>    
               You can <sup>3</sup>#   <br>    
               Yeah, but  <sup>4</sup>#   <img style='width:1cm' src='img/FriendsPlus/Page64/E2/3.jpg'><br>    
               I suppose <sup>5</sup>#    <img style='width:1cm' src='img/FriendsPlus/Page64/E2/4.jpg'><br>    
               that’s a<sup>6</sup>#   <img style='width:1cm' src='img/FriendsPlus/Page64/E2/5.jpg'><br>    
               Great! <img style='width:1cm' src='img/FriendsPlus/Page64/E2/6.jpg'<br>    
             </div>
          </div>
        
      
          `,
        answer: [
          "to learn",
          "have lessons",
          "watch videos on YouTube",
          "I haven't got any money for lessons",
          "so",
          "good idea",
        ],
      },
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P64-E3",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    record: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Work in pairs. Practise the dialogue.",
        color: "#da1f73",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questions: [
      {
        title: `
          <div>
        <div style='display: flex; margin-top: 20px; justify-content: space-between; background-color: rgb(212, 227, 233); padding: 30px; border-radius: 50px'>
          <div>
            <div style='display: flex;'>
              <div><b>Kim</b></div>
              <div style='margin-left: 26px;'>That’s <b><sup>1</sup>awful / amazing</b>, Joe. When did you learn how to dance like that?  </div>
            </div>
            <div style='display: flex;'>
              <div><b>Joe</b></div>
              <div style='margin-left: 30px;'>I had lesson at my old school. Do you like dancing?</div>
            </div>
            <div style='display: flex;'>
              <div><b>Kim</b></div>
              <div style='margin-left: 26px;'>I love it, but I’m no good. I really want to learn to dance <b><sup>2</sup>salsa / guitar</b>  </div>
            </div>
            <div style='display: flex;'>
              <div><b>Joe</b></div>
              <div style='margin-left: 30px;'>Yeah?</div>
            </div>
            <div style='display: flex;'>
              <div><b>Kim</b></div>
              <div style='margin-left: 26px;'>What’s the best way to learn?</div>
            </div>
            <div style='display: flex;'>
              <div><b>Joe</b></div>
              <div style='margin-left: 30px;'>It’s probably best to <b><sup>3</sup>leave / have</b> lesson. I’m sure there are lots of salsa classes.</div>
            </div>
            <div style='display: flex;'>
              <div><b>Kim</b></div>
              <div style='margin-left: 26px;'>Yeah, but i haven’t got any <b><sup>4</sup>money / time</b> for lessons</div>
            </div>
            <div style='display: flex;'>
              <div><b>Joe</b></div>
              <div style='margin-left: 30px;'>Well, you can watch videos on YouTube.</div>
            </div>
            <div style='display: flex;'>
              <div><b>Kim</b></div>
              <div style='margin-left: 26px;'>I suppose so. But I need people to dance with. You can’t dance salsa alone!</div>
            </div>
            <div style='display: flex;'>
              <div><b>Joe</b></div>
              <div style='margin-left: 30px;'>Well, you cam find a <b><sup>5</sup>teacher / friend</b> to learn with you.</div>
            </div>
            <div style='display: flex;'>
              <div><b>Kim</b></div>
              <div style='margin-left: 26px;'>That’s a good idea ...</div>
            </div>
            <div style='display: flex;'>
              <div><b>Joe</b></div>
              <div style='margin-left: 30px;'>I’d like to learn salsa.</div>
            </div>
            <div style='display: flex;'>
              <div><b>Kim</b></div>
              <div style='margin-left: 26px;'>Really?</div>
            </div>
            <div style='display: flex;'>
              <div><b>Joe</b></div>
              <div style='margin-left: 30px;'>Yeah, It looks <b><sup>6</sup>fun / weird</b>.</div>
            </div>
            <div style='display: flex;'>
              <div><b>Kim</b></div>
              <div style='margin-left: 26px;'>Great!</div>
            </div>
          </div>
          <div>
            <img src='img/FriendsPlus/Page64/E1/1.jpg' />
          </div>
        </div>
          `,
        answer: [],
      },
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P64-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look at ideas 1-5. Take turns giving and responding to advice for each situation.",
        color: "#da1f73",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page64/E4/1.jpg" }],
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB7-U5-P64-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 200,
    maxLength: 50,
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    hideBtnFooter: true,
    record: true,
    titleQuestion: [
      {
        num: "5",
        title:
          ` Work in pairs. Read the situation.Practise a new dialogue using the Key Phrases and the dialogue in exercise 1 to help you. `,
        color: "#da1f73",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='width: 18cm; margin: 30px 100px; background:rgb(246 211 218); padding: 10px 20px; border-radius: 40px'>
              <div style='border: 3px solid rgb(218,31,115); padding: 10px; margin: 10px'>
                <b>Student A:</b> You want to make some money so that you can buy something cool. Ask student B for advice - he / she recently saved money and bought a new tennis racket.
             
              </div>
              <div style='border: 3px solid rgb(218,31,115); padding: 10px; margin: 10px'>
                <b>Student B:</b> You saved money and bought a new tennis racket. Student A want to know the best way to save money. Give student A some advice and ask him / her what he / she wants to buy.

              
              </div>
              

          </div>
				
          `,
        answer: [],
      },
    ],
  },
};

export default json;
