import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB7-U2-P27-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", marginLeft: 15 },
    inputSize: 750,
    exerciseKey: "img/FriendsPlus/Page27/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the language survey report. How many people are there in the class? How many people watch TV programmes in another language?",
        color: "#4fbc79",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page27/E1/1.jpg'>
          <div style='margin-left:20px'>How many people are there in the class?</div>#
          <div style='margin-left:20px'>How many people watch tV programmes in another language?</div>#
        `,
        answer: [
          "There are 13 people in the class.",
          "Only a few people watch TV programmes in another language.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB7-U2-P27-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", marginLeft: 15 },
    inputSize: 170,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 13,
    exerciseKey: "img/FriendsPlus/Page27/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the Key Phrases from the text. Which key phrases are followed by a verb in the singular form?",

        color: "#4fbc79",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <b style='color:white;border-radius:15px;background:rgb(79 188 121);padding:10px 460px 10px 10px; font-size: 27px'>KEY PHRASES</b>
        <div style='background: rgb(207 232 211); border-radius: 15px; padding: 10px;margin:-10px 0px'>
          <b style='color: rgb(35 159 86)'>Making plans</b>
            <div style='line-height:55px'>
                <div> Everybody <sup>1</sup>#group&emsp;<img style='width:3cm' src='img/FriendsPlus/Page27/E2/1.jpg'>
            </div>
            <div style='line-height:55px'>
                <div> More than half <sup>2</sup>#&emsp;<img style='width:2cm' src='img/FriendsPlus/Page27/E2/2.jpg'>
            </div>
            <div style='line-height:55px'>
                <div> half <sup>3</sup>#in the group&emsp;<img style='width:2cm' src='img/FriendsPlus/Page27/E2/2.jpg'>
            </div>
            <div style='line-height:55px'>
                <div> <sup>4</sup>#than half&emsp;<img style='width:2cm' src='img/FriendsPlus/Page27/E2/2.jpg'>
            </div>
            <div style='line-height:55px'>
                <div> A<sup>5</sup>#people&emsp;<img style='width:2cm' src='img/FriendsPlus/Page27/E2/2.jpg'>
            </div>
            <div style='line-height:55px'>
                <div> <sup>3</sup>#in the group speaks English.&emsp;<img style='width:2cm' src='img/FriendsPlus/Page27/E2/2.jpg'>
            </div>
        </div>
        `,
        answer: [
          "in the",
          "of the class",
          "of the people",
          "Less",
          "few",
          "Nobody",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB7-U2-P27-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", marginLeft: 15 },
    inputSize: 470,
    // textAlign: "center",
    maxLength: 40,
    exerciseKey: "img/FriendsPlus/Page27/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Find the words <i>but</i> and <i>however</i> in the survey. Do they come at the beginning, middle or end of a sentence?",

        color: "#4fbc79",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page27/E3/1.jpg'>
          <div>
            <b style='font-style: italic;'>However:</b>#<br>
            <b style='font-style: italic;'>but:</b>#<br>
          </div>
        `,
        answer: [
          "comes at the beginning of a sentence",
          "comes in the middle of a sentence. ",
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 2",
    id: "SB7-U2-P29-E4",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page27/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: "Match 1–4 with a–d.",
        color: "#4fbc79",
      },
    ],
    fontSize: 30,
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "17px",
              left: "340px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "89px",
              left: "340px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "161px",
              left: "340px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "233px",
              left: "340px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "17px",
              left: "485px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "89px",
              left: "485px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "161px",
              left: "485px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "233px",
              left: "485px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "89px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "123px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "26px",
              left: "674px",
              width: 20,
              height: 20,
              // backgroundColor: "white",
              borderRadius: "50%",
            },
          }, //12
        ],
        answers: ["0-7", "1-4", "2-5", "3-6"],
        initialValue: [],
      },
      Layout: `
          <div style='display: flex'>
              <div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>1</b>
                    <div style='width: 8cm;margin-left:20px'>Everybody speaks English in class, but...</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>2</b>
                    <div style='width: 8cm;margin-left:20px'>Everybody reads in
                    their own language.</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>3</b>
                    <div style='width: 8cm;margin-left:20px'>Everybody listens
                    to songs in English.
                    However, …</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>4</b>
                    <div style='width: 8cm;margin-left:20px'>A few people know
                    three languages, but …</div>
                  </div>
              </div>
              <div style='margin-left:170px' >
                  <div style="display: flex;font-size:24px;color:black">
                    <b>a</b>
                    <div style='width: 8cm;margin-left:20px'>only a few people
                    read in a second
                    language.</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>b</b>
                    <div style='width: 8cm;margin-left:20px'>songs in English
                    are more difficult
                    to understand.</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>c</b>
                    <div style='width: 8cm;margin-left:20px'> everybody is
                    learning English.</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>d</b>
                    <div style='width: 8cm;margin-left:20px'>nobody speaks
                    English outside
                    class.</div>
                  </div>
              </div>
          </div>
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          
          
      `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "SB7-U1-P27-E5",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: "5",
        title: " ! Follow the steps in the Writing guide.",
        color: "#12a15f",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <img src='img/FriendsPlus/Page27/E5/1.jpg'>
            <div><textarea id="0" rows="7" ></textarea><div>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
