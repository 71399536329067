import PropTypes from 'prop-types';
import { Fragment, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styles from './caseInput.module.css';

const CaseInput = ({ content, dataIndex, handleChange, handleColor, isDone, inputStyle }) => {
  const handleTransform = useCallback(
    (node, nodeIndex, dataIndex) => {
      if (node.type === 'tag' && node.name === 'input') {
        const { id } = node.attribs;
        return (
          <input
            className={styles.input}
            key={nodeIndex}
            onChange={(e) => handleChange(dataIndex, id, e.target.value)}
            style={{ color: handleColor(dataIndex, id, isDone), ...inputStyle }}
            
          />
        );
      }
    },
    [handleChange, handleColor, isDone]
  );

  return (
    <Fragment>
      <div>
        {ReactHtmlParser(content, { transform: (node, nodeIndex) => handleTransform(node, nodeIndex, dataIndex) })}
      </div>
    </Fragment>
  );
};

CaseInput.propTypes = {
  content: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleColor: PropTypes.func,
  index: PropTypes.number,
  isDone: PropTypes.bool,
  inputStyle: PropTypes.object,
};

export default CaseInput;
