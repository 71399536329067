import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'U8',
		id: 'SB7-PG-P91-E1',
		exerciseKey: 'img/FriendsPlus/Page91/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: TB2,
		titleQuestion: [
			{
				num: '1',
				title: `
          Find nine more music words. Then put them in the correct list.`,
				color: '#2e321b',
			},
		],

		questions: [
			{
				title: `
          <div style="width: 400px; margin-top: 20px;">
            <div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page91/E1/1.jpg" style="max-width: 100%;" />
            </div>
          </div>
                `,
				answer: [],
			},
		],

		tb2Style: { width: 500 },
		type: 1,
		data: [
			{
				title: 'Musical instruments',
				content: ['piano', '#', '#', '#', '#'],
				answers: ['piano,', 'bass', 'violin', 'keyboards', 'vocals'],
				commonStyle: {
					backgroundColor: 'rgb(244,215,110)',
					color: 'rgb(90,88,89)',
				},
				titleStyle: { backgroundColor: 'rgb(221,29,113)', color: 'white' },
				contentStyle: {},
			},
			{
				title: 'Types of music',
				content: ['#', '#', '#', '#'],
				answers: ['hard rock', 'pop', 'rap', 'classical'],
				commonStyle: {
					backgroundColor: 'rgb(244,215,110)',
					color: 'rgb(90,88,89)',
				},
				titleStyle: { backgroundColor: 'rgb(221,29,113)', color: 'white' },
				contentStyle: {},
			},
		],
	},

	2: {
		unit: 'U8',
		id: 'SB7-PG-P91-E2',
		exerciseKey: 'img/FriendsPlus/Page91/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 490,
		maxLength: 45,
		stylesTextInput: {
			fontSize: 23,
		},
		hintBox: [
			{
				src: ['What’s Ali going to do on Tuesday morning?'],
				borderColor: 'white',
				// borderColor: '#f77e1d',
				arrow: true,
				position: 1,
				width: 300,
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
					ALI’S WEEK. Look at the pictures in Ali’s diary for next week. 
					Follow the instructions.
				`,
				color: '#2e2919',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 700px; margin-top: 20px;">
						<div style="display: flex; justify-content: center; align-items: center; padding: 20px; border-radius: 20px; border: 3px solid rgb(204,187,219);">
							<img src="img/FriendsPlus/Page91/E2/1.jpg" style="max-width: 100%;" />
						</div>

						<div style="margin-top: 10px; background-color: rgb(204,187,219); border-radius: 20px; padding: 5px 5px 5px 20px;">
							<ul>
								<li>
									Tell your partner what Ali is and isn’t going to do.
								</li>
								<li>
									Cover the diary and change partners. 
									Test your new partner: ask what Ali is going to do at certain times 
									and days next week.
								</li>
							</ul>

							<div style="margin-left: 50px;">
								<hintbox id='0'></hintbox>
							</div>

							<ul style="margin-top: 30px;">
								<li>
									Make a diary for Ali for the week after next. 
									Draw different activities for each day.
								</li>
								<li>
									Show your pictures to a new partner for them 
									to guess what he’s going to do that week.
								</li>
							</ul>
						</div>
                    </div>

					
                `,
				answer: [],
			},
		],
	},

	3: {
		unit: 'U8',
		id: 'SB7-PG-P91-E3',
		exerciseKey: 'img/FriendsPlus/Page91/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 110,
		maxLength: 10,
		stylesTextInput: {
			fontSize: 18,
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '3',
				title: `
					Use the code to write nouns and adjectives in the correct column.
        		`,
				color: '#2e2919',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 700px; background-color: rgb(242,237,111); padding: 15px; border-radius: 20px; margin-top: 20px; position: relative;">
						<div style="display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page91/E3/1.jpg" style="width: 64%;" />
						</div>

						<div style="position: absolute; top: 211px; left: 450px;">#</div>
						<div style="position: absolute; top: 243px; left: 341px">#</div>
						<div style="position: absolute; top: 274px; left: 341px;">#</div>
						<div style="position: absolute; top: 306px; left: 341px;">#</div>
						<div style="position: absolute; top: 338px; left: 450px;">#</div>
						<div style="position: absolute; top: 369px; left: 450px;">#</div>
					</div>
                `,
				answer: [
					'energetic',
					'confidence',
					'kindness',
					'fame',
					'weak',
					'ambitious',
				],
			},
		],
	},

	4: {
		unit: 'U8',
		id: 'SB7-PG-P91-E4',
		exerciseKey: 'img/FriendsPlus/Page91/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: MatchDots,
		titleQuestion: [
			{
				num: '4',
				title: `
					SCHOOL TRIP ARRANGEMENTS. Work in pairs. 
				  	Work out the right order for the school trip and 
				  	then ask and answer questions about the trip.`,
				color: '#2e2919',
			},
		],
		// titleQuestion: [{num: "3", title: "Match 1–5 with a–e to make sentences about the person in the song.", color: "#dd2d38",}],
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '39px',
							left: '85px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // 1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '191px',
							left: '190px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // e

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '77px',
							left: '85px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // 2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '153px',
							left: '190px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // d

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '115px',
							left: '85px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // 3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '229px',
							left: '190px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // f

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '153px',
							left: '85px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // 4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '39px',
							left: '190px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // a

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '191px',
							left: '85px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // 5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '77px',
							left: '190px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // b

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '229px',
							left: '85px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // 6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '115px',
							left: '190px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #F58024',
							background: 'white',
						},
					}, // c
				],

				// answers: ['0-1', '2-3', '4-5', '6-7', '8-9'],
				// initialValue: ['0-1'],
				answers: ['0-1', '2-3', '4-5', '6-7', '8-9', '10-11'],
				initialValue: ['0-1'],
			},
			Layout: `
            <div>
              <div style="font-size: 26px; margin-top: 20px; display: flex;">
                <div>
                  <div>7:30</div>
                  <div>9:30</div>
                  <div>12:30</div>
                  <div>14:00</div>
                  <div>17:30</div>
                  <div>19:30</div>
                </div>
  
                <div>
                  <input id='0' type= 'boxMatch' />
                  <input id='1' type= 'boxMatch' />
                  <input id='2' type= 'boxMatch' />
                  <input id='3' type= 'boxMatch' />
                  <input id='4' type= 'boxMatch' />
                  <input id='5' type= 'boxMatch' />
                </div>
  
                <div style="margin-left: 144px">
					<div>Go to see a musical.</div>
					<div>Coach picks up students from the theatre.</div>
					<div>Arrive back at school.</div>
					<div>Visit the Natural History Museum.</div>
					<div>Coach leaves school.</div>
					<div>Have lunch in a rooftop restaurant.</div>
                </div>
  
                <div>
                  <input id='6' type= 'boxMatch' />
                  <input id='7' type= 'boxMatch' />
                  <input id='8' type= 'boxMatch' />
                  <input id='9' type= 'boxMatch' />
                  <input id='10' type= 'boxMatch' />
                  <input id='11' type= 'boxMatch' />
                </div>
              </div>
            </div>
            <br/><br/><br/>
          `,
		},
	},
};

export default json;
