import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Option 7',
    id: 'SB7-O7-P112-E1',
    exerciseKey: 'img/FriendsPlus/Page112/E1/Key/answerKey.png',

    audio: '',
    video: '',

    component: T6,
    inputSize: 120,
    maxLength: 7,
    // checkDuplicated: true,
    textAlign: 'center',

    hintBox: [
      {
        src: ['musical', 'concert', 'play'],
        borderColor: '#f77e1d',
        width: 525,
      },
    ],

    titleQuestion: [
      {
        num: '1',
        title: 'Match posters 1–3 with the types of show in the box.',
        color: '#f77e1d',
      },
    ],

    questions: [
      {
        title: `
                <div style="margin-top: 20px;"><hintbox id='0' style="width: 400px;"></hintbox></div>

                <div style="margin-top: 20px; width: 500px">
                    <div style="margin-top: 50px; margin-bottom: 50px;">
                        <img src="img/FriendsPlus/Page112/E1/1.jpg" style="width: 120%;" />
                    </div>
                    <p style="margin-right: 20px">Poster 1: #</p>
                    <p style="margin-right: 20px">Poster 2: #</p>
                    <p style="margin-right: 20px">Poster 3: #</p>
                </div>
            `,

        answer: ['play', 'musical', 'concert'],
      },
    ],
  },

  2: {
    unit: 'Option 7',
    id: 'SB7-O7-P112-E2',
    exerciseKey: 'img/FriendsPlus/Page112/E2/Key/answerKey.png',

    audio: 'Audios/3-19 Friends Plus 7.mp3',
    video: '',

    component: T6,
    inputSize: 180,
    maxLength: 13,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },

    titleQuestion: [
      {
        num: '2',
        title: `
                <audioimage name='3.19'></audioimage>
                Look at the posters and listen to the conversation.
                Which type of show does Will decide to buy tickets for?
            `,
        color: '#f77e1d',
      },
    ],

    questions: [
      {
        title: `
                    <div style="margin-top: 20px; width: 500px">
                        <div style="margin-top: 50px; margin-bottom: 50px;">
                            <img src="img/FriendsPlus/Page112/E1/1.jpg" style="width: 120%;" />
                        </div>
                        <p style="margin-right: 20px">Answer: #</p>
                    </div>
            `,

        answer: ['The Lion King'],
      },
    ],
  },

  3: {
    unit: 'Option 7',
    id: 'SB7-O7-P112-E3',
    exerciseKey: 'img/FriendsPlus/Page112/E3/Key/answerKey.png',

    audio: 'Audios/3-19 Friends Plus 7.mp3',
    video: '',

    component: T6,
    inputSize: 110,
    maxLength: 7,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },

    titleQuestion: [
      {
        num: '3',
        title: `
                <audioimage name='3.19'></audioimage>
                Listen to the conversation again.
                Correct the words in bold in the Key Phrases.
            `,
        color: '#f77e1d',
      },
    ],

    questions: [
      {
        title: `
                    <div style="margin-top: 20px; width: 450px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                        <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                            Key Phrases
                        </div>
                        
                        <div style="margin-top: 10px; padding-left: 20px;">
                            <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                                Buying tickets
                            </div>

                            <div>
                                Can I have two <strong><sup>1</sup>seats</strong> for …, please? <br/>
                                Where would you like to sit: front, <strong><sup>2</sup>centre</strong> or back? <br/>
                                That’s seats … and … in <strong><sup>3</sup>line</strong> … . <br/>
                                That’s <strong><sup>4</sup>$</strong>… altogether, please. <br/>
                            </div>
                            
                            <div style="margin-top: 30px;">
                                1: #  <br /> 
                                2: # <br />
                                3: # <br />
                                4: # <br /> 
                            </div>
                        </div>
                        
                    </div>
                    
                `,
        answer: ['tickets', 'middle', 'row', 'pounds'],
      },
    ],
  },

  4: {
    unit: 'Option 7',
    id: 'SB7-07-P112-E4',
    exerciseKey: 'img/FriendsPlus/Page112/E4/Key/answerKey.png',

    audio: 'Audios/3-19 Friends Plus 7.mp3',
    video: '',

    component: T6,
    inputSize: 165,
    maxLength: 13,
    // checkDuplicated: true,
    stylesTextInput: {
      fontSize: 23,
    },

    titleQuestion: [
      {
        num: '4',
        title: `
                <audioimage name='3.19'></audioimage>
                Listen again, and complete the ticket 
                with the correct information.
            `,
        color: '#f77e1d',
      },
    ],

    questions: [
      {
        title: `
                <div style="margin-top: 20px; width: 450px;box-shadow: 5px 10px 10px rgba(197, 194, 189, 1) ; overflow: hidden; position: relative;">
                    <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(255, 183, 21, 1); color: white; padding: 10px; text-transform: uppercase;">
                        Theatre Ticket
                    </div>
                    
                    <div style="padding: 20px; background-color: rgba(255, 239, 206, 1);">
                            Show: <sup>1</sup>#  <br /> 
                            Date: <sup>2</sup>#  <br /> 
                            Seats: <sup>3</sup>#  <br /> 
                            November <sup>4</sup>#  <br /> 
                            Row: <sup>5</sup>#  <br /> 
                            Total price: <sup>6</sup>£#  <br /> 
                    </div>

                    <div style="position: absolute; top: 8px; right: 23px;"><img src="img/FriendsPlus/Page112/E4/1.jpg" /></div>
                    
                </div>
                
            `,
        answer: ['The Lion King', '18', '11', '12', 'V', '76'],
      },
    ],
  },

  5: {
    unit: 'Option 7',
    id: 'SB7-07-P112-E5',
    exerciseKey: 'img/FriendsPlus/Page112/E5/Key/answerKey.png',
    audio: 'Audios/3-20 Friends Plus 7.mp3',
    component: T6,
    inputSize: 210,
    maxLength: 17,
    // checkDuplicated: true,

    stylesTextInput: {
      fontSize: 24,
    },

    titleQuestion: [
      {
        num: '5',
        title: `
                <audioimage name='3.20'></audioimage>
                Listen and write the numbers and letters you hear.
                Then listen and repeat.
            `,
        color: '#f77e1d',
      },
    ],

    questions: [
      {
        title: `
                <ol style="margin-top: 20px">
                    <li>9, 10, R</li>
                    <li>#</li>
                    <li>#</li>
                    <li>#</li>
                </ol>
            `,
        answer: [
          '24,25,26,H / 24, 25, 26, H / 24-26, H',
          '16,17,18,19,J / 16, 17, 18, 19, J / 16-19, J',
          '30,31,32,E / 30, 31, 32, E / 30-32, E',
        ],
        // answer: ['24, 25, 26, H', '16-19, J', '30-32, E'],
      },
    ],
  },

  6: {
    unit: 'Option 7',
    id: 'SB7-O7-P112-E6',
    exerciseKey: 'img/FriendsPlus/Page112/E6/Key/answerKey.png',

    audio: 'Audios/3-21 Friends Plus 7.mp3',
    video: '',

    component: T6,
    inputSize: 250,
    maxLength: 20,
    // checkDuplicated: true,
    stylesTextInput: {
      fontSize: 24,
    },

    titleQuestion: [
      {
        num: '6',
        title: `
                <audioimage name='3.21'></audioimage>
                Complete the dialogue with words from the Key Phrases. 
                Listen and check. Then practise the dialogue.
            `,
        color: '#f77e1d',
      },
    ],

    questions: [
      {
        title: `
                <div style="width: 550px">
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span># three tickets for Hamlet, please?</span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>Certainly. Which date?</span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>22 October.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span># to sit: front, middle or back?</span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>Middle, please.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>Certainly. # 31, 32 and 33 in row M. They're £34 each.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>Great!</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>That's £102 # , please.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>Here you are.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>Thanks. Enjoy the show.</span>
                    </div>
                </div>
            `,

        answer: ['Can I have', 'Where would you like', "That's seats", 'altogether'],
      },
    ],
  },

  7: {
    unit: 'Option 7',
    id: 'SB7-O7-P112-E7',
    exerciseKey: '',

    audio: '',
    video: '',

    component: T6,
    inputSize: 550,
    maxLength: 100,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

    titleQuestion: [
      {
        num: '7',
        title: `
                Work in pairs. Look at the
                posters and choose a show. Prepare and
                practise a new dialogue using the key
                phrases and the dialogue in exercise 6.
            `,
        color: '#f77e1d',
        prefix: 'default',
      },
    ],

    questions: [
      {
        title: `
                <div style="margin-block: 20px; width: 450px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                        <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                            Key Phrases
                        </div>
                        
                        <div style="margin-top: 10px; padding-left: 20px;">
                            <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                                Buying tickets
                            </div>

                            <div>
                                Can I have two <strong><sup>1</sup>seats</strong> for …, please? <br/>
                                Where would you like to sit: front, <strong><sup>2</sup>centre</strong> or back? <br/>
                                That’s seats … and … in <strong><sup>3</sup>line</strong> … . <br/>
                                That’s <strong><sup>4</sup>$</strong>… altogether, please. <br/>
                            </div>
                        </div>
                </div>
                <div style="width: 550px">
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>......... three tickets for Hamlet, please?</span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>Certainly. Which date?</span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>22 October.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>......... to sit: front, middle or back?</span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>Middle, please.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>Certainly. ......... 31, 32 and 33 in row M. They're £34 each.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>Great!</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>That's £102 ......... , please.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Anna</span>
                        <span>Here you are.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Clerk</span>
                        <span>Thanks. Enjoy the show.</span>
                    </div>
                </div>
            `,

        answer: [],
      },
    ],
  },
};

export default json;
