import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
	1: {
		unit: "Unit 2",
		id: "SB7-U2-P20-E1",
		audio: "Audios/1-14 Friends Plus 7.mp3",
		video: "",
		component: T6,
		inputSize: 45,
		stylesTextInput: {
			border: "2px solid",
			height: 45,
			backgroundColor: "transparent",
		},
		textAlign: "center",
		maxLength: 1,
		exerciseKey: "img/FriendsPlus/Page20/E1/Key/answerKey.png",
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: "1",
				title:
					"<audioimage name='1.14'></audioimage> Match the words in <span style='color: #00aeef;'>blue</span> in the communication survey with pictures A–J. <br/>Listen and check.",
				color: "#0067b4",
			},
		],
		questions: [
			{
				title: `
          <div style='display: flex; justify-content: space-between;'>
						<div style='flex: 1;margin-right: 10px;'>
							<div style='background-color: rgb(246,137,31); color: white;'>COMMUNICATION SURVEY</div>
							<div style='background-color: rgb(254,188,17); color: white;'>The way we communicate is changing. That's a fact. But how? Please take our survey so we can find out.</div>
							<div style='background-color: rgb(246,137,31); color: white;'>How often do you...</div>
							<div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>1</div>
									<div style='margin-left: 10px;width: 85%;'>have <span style='color: rgb(0,174,239)'>face-to-face conversations</span> with friends?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>2</div>
									<div style='margin-left: 10px;width: 85%;'>send a <span style='color: rgb(0,174,239)'>text message</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>3</div>
									<div style='margin-left: 10px;width: 85%;'>send an <span style='color: rgb(0,174,239)'>email</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>4</div>
									<div style='margin-left: 10px;width: 85%;'>send a <span style='color: rgb(0,174,239)'>letter</span> or <span style='color: rgb(0,174,239)'>card</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>5</div>
									<div style='margin-left: 10px;width: 85%;'>call someone from <span style='color: rgb(0,174,239)'>mobile phone</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>6</div>
									<div style='margin-left: 10px;width: 85%;'>call someone from <span style='color: rgb(0,174,239)'>landline</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>7</div>
									<div style='margin-left: 10px;width: 85%;'>use <span style='color: rgb(0,174,239)'>instant messaging</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>8</div>
									<div style='margin-left: 10px;width: 85%;'>use <span style='color: rgb(0,174,239)'>video chat</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>9</div>
									<div style='margin-left: 10px;width: 85%;'>use <span style='color: rgb(0,174,239)'>symbols</span> like <span style='color: rgb(0,174,239)'>emoticons</span> and <span style='color: rgb(0,174,239)'>emojis</span> in messages?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>10</div>
									<div style='margin-left: 10px;width: 85%;'>Post messages on <span style='color: rgb(0,174,239)'>social media</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
							</div>
						</div>
						<div style='flex: 1;margin-left: 10px;'>
							<div style='display: flex;'>
								<img src='img/FriendsPlus/Page20/E1/1.jpg' style='width: 200px; height: 200px;' />
								<img src='img/FriendsPlus/Page20/E1/2.jpg' style='width: 200px; height: 200px;' />
							</div>
							<div style='display: flex;'>
								<img src='img/FriendsPlus/Page20/E1/3.jpg' style='width: 200px; height: 200px;' />
								<img src='img/FriendsPlus/Page20/E1/4.jpg' style='width: 200px; height: 200px;' />
							</div>
							<div style='display: flex;'>
								<img src='img/FriendsPlus/Page20/E1/5.jpg' style='width: 200px; height: 200px;' />
								<img src='img/FriendsPlus/Page20/E1/6.jpg' style='width: 200px; height: 200px;' />
							</div>
							<div style='display: flex;'>
								<img src='img/FriendsPlus/Page20/E1/7.jpg' style='width: 200px; height: 200px;' />
								<img src='img/FriendsPlus/Page20/E1/8.jpg' style='width: 200px; height: 200px;' />
							</div>
							<div style='display: flex;'>
								<img src='img/FriendsPlus/Page20/E1/9.jpg' style='width: 200px; height: 200px;' />
								<img src='img/FriendsPlus/Page20/E1/10.jpg' style='width: 200px; height: 200px;' />
							</div>
						</div>
					</div>
        `,
				answer: ["I", "E", "F", "C", "B", "A", "G", "D", "H", "J"],
			},
		],
	},
	2: {
		unit: "Unit 2",
		id: "SB7-U2-P20-E2",
		component: T6,
		inputSize: 45,
		stylesTextInput: {
			border: "2px solid",
			height: 45,
			backgroundColor: "transparent",
		},
		textAlign: "center",
		maxLength: 1,
		exerciseKey: "img/FriendsPlus/Page20/E1/Key/answerKey.png",
		hideBtnFooter: true,
		questionImage: [],
		titleQuestion: [
			{
				num: "2",
				title:
					" Do the survey and compare your answers with your partner's.",
				color: "#0067b4",
			},
		],
		questions: [
			{
				title: `
          <div style='display: flex; justify-content: space-between;'>
						<div style='flex: 1;margin-right: 10px;'>
							<div style='background-color: rgb(246,137,31); color: white;'>COMMUNICATION SURVEY</div>
							<div style='background-color: rgb(254,188,17); color: white;'>The way we communicate is changing. That's a fact. But how? Please take our survey so we can find out.</div>
							<div style='background-color: rgb(246,137,31); color: white;'>How often do you...</div>
							<div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>1</div>
									<div style='margin-left: 10px;width: 85%;'>have <span style='color: rgb(0,174,239)'>face-to-face conversations</span> with friends?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>2</div>
									<div style='margin-left: 10px;width: 85%;'>send a <span style='color: rgb(0,174,239)'>text message</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>3</div>
									<div style='margin-left: 10px;width: 85%;'>send an <span style='color: rgb(0,174,239)'>email</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>4</div>
									<div style='margin-left: 10px;width: 85%;'>send a <span style='color: rgb(0,174,239)'>letter</span> or <span style='color: rgb(0,174,239)'>card</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>5</div>
									<div style='margin-left: 10px;width: 85%;'>call someone from <span style='color: rgb(0,174,239)'>mobile phone</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>6</div>
									<div style='margin-left: 10px;width: 85%;'>call someone from <span style='color: rgb(0,174,239)'>landline</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>7</div>
									<div style='margin-left: 10px;width: 85%;'>use <span style='color: rgb(0,174,239)'>instant messaging</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>8</div>
									<div style='margin-left: 10px;width: 85%;'>use <span style='color: rgb(0,174,239)'>video chat</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>9</div>
									<div style='margin-left: 10px;width: 85%;'>use <span style='color: rgb(0,174,239)'>symbols</span> like <span style='color: rgb(0,174,239)'>emoticons</span> and <span style='color: rgb(0,174,239)'>emojis</span> in messages?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
								<div style='display: flex; position: relative;margin-top: 20px;'>
									<div style='background-color: rgb(246,137,31);border-radius: 50%; width: 35px;height: 35px; text-align: center; color: white;'>10</div>
									<div style='margin-left: 10px;width: 85%;'>Post message on <span style='color: rgb(0,174,239)'>social media</span>?</div>
									<div style='position: absolute; right: 0;'>#</div>
								</div>
                <div  style='margin-top: 30px'>
                  <img src='img/FriendsPlus/Page20/E2/1.jpg' style='width:74%'/>
                </div>
							</div>
						</div>
					</div>
        `,
				answer: [],
			},
		],
	},

};

export default json;
