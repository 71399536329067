// TEMPLATE
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
    1: {
        unit: "Option 4",
        id: "SB7-O4-P109-E1",
        exerciseKey: "img/FriendsPlus/Page109/E1/Key/answerKey.png",
        audio: "Audios/3-11 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 150,
        maxLength: 10,
        hintBox: [{
            src: [
                'basket',
                'cuddly toy',
                'drawer',
                'laptop',
                'pillow',
                'poster',
                'rug',
                'sheets',
                'shelves',
            ],
            borderColor: "#f77e1d",
            width: 525,
        },],
        titleQuestion: [
            {
                num: "1",
                title: `
                <audioimage name='3.11'></audioimage>
                Look at the picture of Malaya and
                Anya’s bedroom. Match objects A–I in the
                picture with the words in the box. Listen and
                check.
            `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                    <div style="margin-top: 20px; width: 600px">
                        <hintbox id='0'></hintbox>

                        <div style="margin-top:20px;"><img src="img/FriendsPlus/Page109/E1/1.jpg" style="max-width: 100%" /></div>

                        <div style="display: flex; margin-top: 20px;">
                            <p>A. #</p>
                            <p style="margin-left: 60px;">B. #</p>
                        </div>
                        <div style="display: flex;">
                            <p>C. #</p>
                            <p style="margin-left: 60px;">D. #</p>
                        </div>
                        <div style="display: flex;">
                            <p>E. #</p>
                            <p style="margin-left: 60px;">F. #</p>
                        </div>
                        <div style="display: flex;">
                            <p>G. #</p>
                            <p style="margin-left: 60px;">H. #</p>
                        </div>

                        <div style="display: flex;">
                            <p>I.<span style="padding-left: 13px"> #</span></p>
                        </div>
                        
                    </div>
                `,

                answer: [
                    'poster',
                    'shelves',
                    'laptop',
                    'pillow',
                    'cuddly toy',
                    'drawer',
                    'sheets',
                    'basket',
                    'rug',
                ],
            },
        ],
    },

    2: {
        unit: "Option 4",
        id: "SB7-O4-P109-E2",
        exerciseKey: "img/FriendsPlus/Page109/E2/Key/answerKey.png",
        audio: "Audios/3-12 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 480,
        maxLength: 41,
        titleQuestion: [
            {
                num: "2",
                title: `
                <audioimage name='3.12'></audioimage>
                Listen to eight false statements
                about the picture. Correct the sentences.
            `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
            <div style="margin-top: 20px; width: 700px">
                <div style="margin-top:20px;"><img src="img/FriendsPlus/Page109/E1/1.jpg" style="max-width: 100%" /></div>

                    <div style="margin-top: 20px; margin-left: 12px">
                        1. <span style="margin-left: 12px;">'The pillows are under the beds.'<br></span>
                        <span style="color: rgba(106, 103, 104, 1); margin-left: 41px">
                            The pillows are on the beds.
                        </span>
                    </div>

                    <ol start="2">
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                        <li>#</li>
                    </ol>
            </div>
            `,

                answer: [
                    'The sheets are on the bed / The sheets are on the bed.',
                    'The laptop is on the table / The laptop is on the table.',
                    'The chair is in front of the table / The chair is in front of the table.',
                    'The tidy bed is on the right / The tidy bed is on the right.',
                    'The posters are on the wall / The posters are on the wall.',
                    'The plant is beside the bed on the right / The plant is beside the bed on the right.',
                    'The basket is next to the bed / The basket is next to the bed.',
                ],

            },
        ],

    },

    3: {
        unit: "Option 4",
        id: "SB7-O4-P109-E3",
        exerciseKey: "img/FriendsPlus/Page109/E3/Key/answerKey.png",
        audio: "Audios/3-13 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 40,
        maxLength: 1,
        stylesTextInput: {
            border: "1px solid",
            height: 25,
            fontSize: 20
        },
        titleQuestion: [
            {
                num: "3",
                title: `
                <audioimage name='3.13'></audioimage>
                Listen to Malaya and Anya and
                number key phrases a–d in the order you
                hear them.
              `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                    <div style="margin-top: 20px; width: 480px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                        <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                            Key Phrases
                        </div>
                        
                        <div style="margin-top: 10px; padding-left: 20px;">
                            <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                                Finding things
                            </div>

                            <div style="display: flex">
                                <ol style="list-style-type: lower-alpha;;">
                                    <li>That’s the wrong one.</li>
                                    <li>Look, here’s … !</li>
                                    <li>Here it is!</li>
                                    <li>Is this what you’re looking for?</li>
                                </ol>
                                <div style="margin-left: 10px"># <br/> # <br/> # <br/> # <br/> </div>
                            </div>

                            
                        </div>
                        
                    </div>
                `,

                // title: `
                //     <div style="margin-top: 20px; width: 450px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                //         <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                //             Key Phrases
                //         </div>

                //         <div style="margin-top: 10px; padding-left: 20px;">
                //             <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                //                 Finding things
                //             </div>

                //             <ol style="list-style-type: lower-alpha;;">
                //                 <li>That’s the wrong one. #</li>
                //                 <li>Look, here’s … ! #</li>
                //                 <li>Here it is! #</li>
                //                 <li>Is this what you’re looking for? #</li>
                //             </ol>
                //         </div>

                //     </div>
                // `,

                answer: [
                    '3',
                    '1',
                    '4',
                    '2',
                ],
            },
        ],

    },

    4: {
        unit: "Option 4",
        id: "SB7-O4-P109-E4",
        exerciseKey: "img/FriendsPlus/Page109/E4/Key/answerKey.png",
        audio: "Audios/3-13 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 655,
        maxLength: 55,
        titleQuestion: [
            {
                num: "4",
                title: `
                <audioimage name='3.13'></audioimage> 
                Listen to the conversation again and 
                answer the questions.
              `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                    <div style="width: 550px">
                    <ol style='margin-top: 40px'>
                        <li>What are the girls looking for? #</li>
                        <li>Why does Anya need the book today? #</li>
                        <li>Where does Anya usually put her book? #</li>
                        <li>Where does she do her homework? #</li>
                        <li>Where does Anya find the book? #</li>
                    </ol>
                    </div>  
                `,

                answer: [
                    "They're looking for Anya's history book / They're looking for Anya's history book.",
                    "She's got a test / She's got a test.",
                    "She usually puts it on the shelves with her other books / She usually puts it on the shelves with her other books.",
                    "She does her homework at the table / She does her homework at the table.",
                    "In the clothes basket / In the clothes basket.",
                ],

            },
        ],
    },

    5: {
        unit: "Option 4",
        id: "SB7-O4-P109-E5",
        exerciseKey: "img/FriendsPlus/Page109/E5/Key/answerKey.png",

        audio: "Audios/3-14 Friends Plus 7.mp3",
        video: "",

        component: T6,
        inputSize: 340,
        maxLength: 31,
        stylesTextInput: {
            fontSize: 23,
        },
        hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

        titleQuestion: [
            {
                num: "5",
                title: `
                <audioimage name='3.16'></audioimage>
                Look at the photo of Adam’s bedroom and listen. 
                Then practise the dialogue with a partner.
              `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                    <div style="width: 600px;">
                        <img src="img/FriendsPlus/Page109/E5/1.jpg" style="max-width: 100%" />

                        <div style="margin-top: 20px">
                            <div style="display: flex;">
                                <span style="margin-right: 25px">Mum</span>
                                <span>What’s wrong?</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 15px">Adam</span>
                                <span>I can’t find my phone.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Mum</span>
                                <span>Is it under those magazines on your table?</span>
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 15px">Adam</span>
                                <span>No, it isn’t.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Mum</span>
                                <span>Look, here’s a mobile phone! Is this what you’re looking for?</span>
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 15px">Adam</span>
                                <span>No, that’s the wrong one. That’s my old one.</span>
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 25px">Mum</span>
                                <span>What about your school bag? Is it in there?</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 15px">Adam</span>
                                <span>No, it isn’t. Oh, here it is! It’s on the bed.</span>
                            </div>
                        </div>
                    </div>
                `,
                answer: [
                    "How can I help you?",
                    "I'd like to make a complaint",
                    "there's something wrong with it",
                    "I'm terribly sorry about that",
                    "I'm afraid we can't change it",
                    "it doesn't work.",
                ],
            },
        ],

    },

    6: {
        unit: "Option 4",
        id: "SB7-O4-P109-E6",
        exerciseKey: "",
        audio: "",
        video: "",
        component: T6,
        inputSize: 550,
        maxLength: 100,
        textareaStyle: { width: 1000 },

        hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
        hintBox: [{
            src: [
                'football',
                'homework',
                'notebook',
                'laptop',
                'shirt',
            ],
            borderColor: "#f77e1d",
            width: 525,
        },],

        titleQuestion: [
            {
                num: "6",
                title: `
                Work in pairs and look at
                Adam’s bedroom again. Imagine you can’t
                find one of the objects in the box. Prepare
                and practise a new dialogue using the key
                phrases and the dialogue in exercise 5.
            `,
                color: "#f77e1d",
                prefix: "default",
            },
        ],

        questions: [
            {
                title: `
                <div style="margin-top: 20px; width: 600px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <img src="img/FriendsPlus/Page109/E5/1.jpg" style="max-width: 100%; margin-bottom: 20px" />

                    <hintbox id='0'></hintbox>

                    <div style="margin-top: 20px;"><textarea id="0" rows="7" ></textarea></div>
                </div>
            `,

                answer: [],
            },
        ],
    },

};

export default json;