import React from 'react';
import styles from './audioimage.module.css';

function AudioImage({ name='', colorText = '#aecbd4', colorAudio = '#64a0b6' }) {
  return (
    <div className={styles.container} style={{ backgroundColor: colorText }}>
      <div
        className={styles.icon_container}
        style={{ backgroundColor: colorAudio }}
      >
        <i
          className={`fas fa-volume-up ${styles.icon_container_content}`}
        />
      </div>
      <div className={styles.name_container}>
        <span
          className={styles.name_container_content}
        >{` ${name}`}</span>
      </div>
    </div>
  );
}

export default AudioImage;
