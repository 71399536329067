import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
  1: {
    unit: "Unit 3",
    id: "SB7-U3-P40-E1",
    audio: "Audios/1-36 Friends Plus 7.mp3",
    video: "",
    component: Circle_Write,
    inputSize: 180,
    exerciseKey: "img/FriendsPlus/Page40/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          `<videoimage></videoimage><audioimage name='1.36'></audioimage>Choose the correct words in the dialogue.Then watch or listen and check. What did Mark do? Did Sarah have a good weekend?`,
        color: "#da1f73",
      },
    ],
    questionImage: [],
    question: {
      Write: {
        inputStyle: { width: 1500 },
        answers: [
          "Mark played football and got a haircut. He met some friends for a picnic on Sunday.",
          "Sarah's weekend wasn't brilliant. She stayed at her friend Jenny's house, but Jenny was sick so she didn't sleep much and was too tired to do anything on Sunday.",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '49%',
          borderColor: 'transparent',
          color: '#000000',
          fontSize: 25,
          fontWeight: 600,
        },
        selectWordStyle: {
          padding: 3,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: [
          "How / What",
          "before / ago",
          "go / do",
          "bad / brilliant",
          "family / friends",
          "was / were"
        ],
        answers: ['1-4', '0-0', '2-4', '3-4', '4-4', '5-0'],
        initialValue: [],
      },

      Layout: `
          <div style='display: flex; margin-top: 10px; justify-content: space-between; background-color: rgb(212, 227, 233); padding: 50px; border-radius: 50px; margin-top: 20px;'>
            <div>
              <div style='display: flex;'>
                <div><b>Sarah</b></div>
                <div style='margin-left: 35px;'>Hey, Mark. <input id='0' type='Circle' /> was your weekend?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Mark</b></div>
                <div style='margin-left: 35px;'>Not bad, thanks. I played football and I got a haircut. What do you think?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Sarah</b></div>
                <div style='margin-left: 35px;'>It looks great. Very cool. I got a haircut, too.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Mark</b></div>
                <div style='margin-left: 35px;'>When?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Sarah</b></div>
                <div style='margin-left: 35px;'>Two weeks <input id='1' type='Circle' />. But nobody noticed.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Mark</b></div>
                <div style='margin-left: 35px;'> Oh dear. So, what about you? What did you <input id='2' type='Circle' />?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Sarah</b></div>
                <div style='margin-left: 35px;'> Well, I stayed at my friend Jenny's house on Saturday night.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Mark</b></div>
                <div style='margin-left: 35px;'> Oh, right. How was that?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Sarah</b></div>
                <div style='margin-left: 35px;'>It wasn't <input id='3' type='Circle' />. Jenny was sick so I didn't sleep much and I was too tired to go out on Sunday.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Mark</b></div>
                <div style='margin-left: 35px;'> Bad luck! I met some <input id='4' type='Circle' /> for a picnic on Sunday.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Sarah</b></div>
                <div style='margin-left: 35px;'>Really? What <input id='5' type='Circle' /> it like?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Mark</b></div>
                <div style='margin-left: 35px;'>It was fun.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Sarah</b></div>
                <div style='margin-left: 35px;'>Oh, you had a much better weekend. Can I come out with you next time?</div>
              </div>
            </div>
          </div>
          </br />
          <div>
            <div>
              <div><b>What did Mark do?</b></div>
              <div><input id='0' type='TextArea' rows='4' width='700px' /></div>
            </div>
            <div>
              <div><b>Did Sarah have a good weekend?</b></div>
              <div><input id='1' type='TextArea' rows='4' width='700px'/></div>
            </div>
          </div>
        `,
    },
  },
  2: {
    unit: 'Unit 3',
    id: 'SB7-U3-P40-E2',
    component: T6,
    inputSize: '200px',
    exerciseKey: 'img/FriendsPlus/Page40/E2/Key/answerKey.png',
    audio: "Audios/1-36 Friends Plus 7.mp3",
    video: "/Videos/Speaking/U3-1.36. Trang 40.mp4",
    stylesTextInput: { textAlign: "center" },
    titleQuestion: [
      {
        num: "2",
        title:
          " <videoimage></videoimage><audioimage name='1.36'></audioimage> Cover the dialogue and complete the Key Phrases. Watch or listen again and check.",
        color: "rgb(222, 25, 113)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
          <div style="margin-top: 10px;"><img src='img/FriendsPlus/Page40/E2/2.jpg'/></div>
          <div style="padding-left: 20px; background-color: rgb(244, 211, 218); border-radius: 20px; padding-bottom: 20px; width: 693px">
            <div><b style="color: rgb(222, 25, 113); padding-top: 30px">Asking for and giving opinions</b></div>
            <div>How was <b>1.</b>#?</div>
            <div>&nbsp &nbsp &nbspNot <b>2.</b># , thanks.</div>
            <div>What <b>3.</b># think?</div>
            <div>&nbsp &nbsp &nbspIt looks <b>4.</b># .Very <b>5.</b> #.</div>
            <div>How <b>6.</b># that?</div>
            <div>&nbsp &nbsp &nbspIt wasn't <b>7.</b>#</div>
            <div>What was it <b>8.</b># ?</div>
            <div>&nbsp &nbsp &nbspIt was <b>9.</b># .</div>
          </div>
        </div>`,
        answer: ['your weekend', 'bad', 'do you', 'great', 'cool ', 'was', 'brilliant', 'like', 'fun']
      }
    ]
  },

  3: {
    unit: 'Unit 3',
    id: 'SB7-U3-P40-E3',
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="text-align: center"><img style="width: 74%" src="img/FriendsPlus/Page40/E3/1.jpg"/></div>
      `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'SB7-U3-P40-E4',
    component: T6,
    inputSize: '200px',
    exerciseKey: 'img/FriendsPlus/Page40/E4/Key/answerKey.png',
    audio: "Audios/1-37 Friends Plus 7.mp3",
    stylesTextInput: { textAlign: "center" },
    titleQuestion: [
      {
        num: "4",
        title:
          " <audioimage name='1.37'></audioimage> Cover the dialogue and the Key Phrases. Complete the mini-dialogues. Listen and check. Practise them with your partner.",
        color: "rgb(222, 25, 113)",
        prefix: { icons: ["far fa-comment"] }
      },
    ],
    questions: [
      {
        title: `
        <div>
            <div style="display:flex;">
              <div><b>1</b> </div>
              <div style="margin-left: 20px">A </div>
              <div style="margin-left: 20px">How <b>1.</b># weekend?</div>
            </div>
            <div style="display:flex;">
              <div style="margin-left: 33px">B </div>
              <div style="margin-left: 20px">Not <b>2.</b># . I went shopping on Saturday. I bought these headphones. <b>3.</b># think</div>
            </div>
            <div style="display:flex;">
              <div style="margin-left: 33px">A </div>
              <div style="margin-left: 20px"><b>4.</b># cool.</div>
            </div>
            <div style="display:flex;">
              <div><b>2</b> </div>
              <div style="margin-left: 20px">A </div>
              <div style="margin-left: 20px">What <b>5.</b># at the weekend?</div>
            </div>
            <div style="display:flex;">
              <div style="margin-left: 33px">B </div>
              <div style="margin-left: 20px">Well, I went to the cinema on Saturday evening. I saw The Fast and the Furious.</div>
            </div>
            <div style="display:flex;">
              <div style="margin-left: 33px">A </div>
              <div style="margin-left: 20px"><b>6.</b># like?</div>
            </div>
            <div style="display:flex;">
              <div style="margin-left: 33px">B </div>
              <div style="margin-left: 20px"><b>7.</b># brilliant, to be honest.</div>
            </div>
          </div>
        </div>`,
        answer: ['was your ', 'bad', 'What do you', 'Very', 'did you do ', 'What was it', "It wasn't"]
      }
    ]
  },
  5: {
    unit: 'Unit 3',
    id: 'SB7-U3-P40-E5',
    component: T6,
    hideBtnFooter: true,
    hintBox: [
      {
        src: ["How was your weekend?"],
        borderColor: "rgb(217, 26, 114)",
        arrow: true,
        position: 1,
        width: 320,
      },
      {
        src: ["Not bad, thanks."],
        borderColor: "rgb(230, 133, 160)",
        arrow: true,
        position: 2,
        width: 250,
      },
      {
        src: ["What did you do? "],
        borderColor: "rgb(217, 26, 114)",
        arrow: true,
        position: 1,
        width: 240,
      },
      {
        src: ["I went to a football match on Saturday."],
        borderColor: "rgb(230, 133, 160)",
        arrow: true,
        position: 2,
        width: 440,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title:
          " Work in pairs. Read the situation.Practise a new dialogue about your weekend using the Key Phrases.",
        color: "rgb(222, 25, 113)",
        prefix: 'default'
      },
    ],
    questions: [
      {
        title: `
        <div style="font-size: 22px;">
          <div style="background-color: rgb(245, 211, 219); border-radius: 15px;  width: 500px; padding: 2px">
            <div style="border: 2px solid rgb(217, 26, 114); padding: 10px; margin: 5px">
              <b>Student A: </b> Tell student B about your 
                weekend. You did something different each 
                day and enjoyed your weekend.
            </div>
            <div style="border: 2px solid rgb(217, 26, 114); padding: 10px; margin: 5px">
              <b>Student A: </b> Tell student B about your 
                weekend. You did something different each 
                day and enjoyed your weekend.
            </div>
          </div>
          <div style="display: flex; margin-top: 20px">
            <div><hintBox id='0'></hintBox></div>
            <div style=" margin: 30px 0 0 10px"><hintBox id='1'></hintBox></div>
          </div>
          <div style="display: flex; margin-top: 35px">
            <div><hintBox id='2'></hintBox></div>
            <div style=" margin: 30px 0 0 10px"><hintBox id='3'></hintBox></div>
          </div>
         </div> 

      `,
        answer: [],
      },
    ],
  },
}


export default json;
