import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 5',
    id: 'SB7-U5-P62-E1',
    audio: 'Audios/1-55 Friends Plus 7.mp3',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page62/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: "<audioimage name='1.55'></audioimage> Choose the correct bold words in the texts. Listen and check.",
        color: '#fd7e14',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '0 5px',
          fontWeight: 600,
          color: 'black',
          fontSize: 24,
        },
        selectWordStyle: { border: '3px solid #7dc03f', borderRadius: '50%' },
        limitSelect: 1,
        listWords: [
          'expext /  artist',
          'programmer / winner',
          'inventor / professor',
          'artist / scientist',
          'winner / writer',
          'genius / player',
          'champion / winner',
          'musician / scientist',
          'composer / programmer',
          'champion / writers',
          'mathermatician / champion',
          ' genius / player',
        ],
        answers: ['0-0', '1-0', '2-4', '3-4', '4-0', '5-4', '6-0', '7-0', '8-0', '9-4', '10-0', '11-2'],
        initialValue: [''],
      },
      Layout: `
       
      <div style='display:flex'>
          <div>
              <div style='display:flex;width:16cm; border: 6px solid  rgb(246 139 60);border-radius:20px;padding:10px'>
                  <div >
                    <span style=' font-size:28px; font-weight:700;color: rgb(125 192 63)'>Grace Hopper</span><br>
                    A mathematics <sup>1</sup><input id='0'  type='Circle' /> and computer <sup>2</sup><input id='1'  type='Circle' />
                    She was also a university <sup>3</sup><input id='2'  type='Circle' />.
                  </div>
                  <img style='height:55mm' src='img/FriendsPlus/Page62/E1/1.jpg'>
              </div>
              <div style='margin:10px 0px;display:flex;width:16cm; border: 6px solid  rgb(246 139 60);border-radius:20px;padding:10px'>
                  <div >
                    <span style=' font-size:28px; font-weight:700;color: rgb(125 192 63)'>Marie Curie</span><br>
                    The <sup>4</sup><input id='3'  type='Circle' /> who discovered radium. First female <sup>5</sup><input id='4'  type='Circle' />
                    of the Nobel prize for physics.
                  </div>
                  <img style='height:50mm' src='img/FriendsPlus/Page62/E1/2.jpg'>
              </div>
              <div style=';display:flex;width:16cm; border: 6px solid  rgb(246 139 60);border-radius:20px;padding:10px'>
                  <div >
                    <span style=' font-size:28px; font-weight:700;color: rgb(125 192 63)'>Judit Polgár</span><br>
                    Number one female <sup>6</sup><input id='5'  type='Circle' /> in the world from the age of twelve,
                    and the best female chess player in history. She beat the
                     world <sup>7</sup><input id='6'  type='Circle' />
                    Garry Kasparov, in 2002.
                  </div>
                  <img style='height:70mm' src='img/FriendsPlus/Page62/E1/3.jpg'>
              </div>
          </div>
          <div style='margin-left:20px'>
              <div style='display:flex;width:16cm; border: 6px solid  rgb(246 139 60);border-radius:20px;padding:10px'>
                  <div >
                    <span style=' font-size:28px; font-weight:700;color: rgb(125 192 63)'>Wolfgang Amadeus Mozart</span><br>
                    A talented <sup>8</sup><input id='7'  type='Circle' /> and <sup>9</sup><input id='8'  type='Circle' />.
                  </div>
                  <img style='height:55mm' src='img/FriendsPlus/Page62/E1/4.jpg'>
              </div>
              <div style='margin:10px 0px;display:flex;width:16cm; border: 6px solid  rgb(246 139 60);border-radius:20px;padding:10px'>
                  <div >
                    <span style=' font-size:28px; font-weight:700;color: rgb(125 192 63)'>Charles Dickens</span><br>
                    One of the greatest <sup>10</sup><input id='9'  type='Circle' /> in
                    the English language.
                  </div>
                  <img style='height:50mm' src='img/FriendsPlus/Page62/E1/5.jpg'>
              </div>
              <div style=';display:flex;width:16cm; border: 6px solid  rgb(246 139 60);border-radius:20px;padding:10px'>
                  <div >
                    <span style=' font-size:28px; font-weight:700;color: rgb(125 192 63)'>Leonardo da Vinci</span><br>
                    A <sup>11</sup><input id='10'  type='Circle' />, scientist, inventor
                    and artist. Leonardo was a<sup>12</sup><input id='11'  type='Circle' />
                    with many different talent.
                  </div>
                  <img style='height:56mm' src='img/FriendsPlus/Page62/E1/6.jpg'>
              </div>
          </div>

      </div>
         
      `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 5',
    id: 'SB7-U5-P62-E2',
    audio: 'Audios/1-56 Friends Plus 7.mp3',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: '2',
        title:
          "<audioimage name='1.56'></audioimage> <span style='color:#fd7e14'><b>PRONUNCIATION</b> /ə/ in jobs </span> Listen to the jobs. Then listen again and repeat.",
        color: '#fd7e14',
        // prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page62/E2/1.jpg' }],
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 5',
    id: 'SB7-U5-P62-E3',
    audio: 'Audios/1-57 Friends Plus 7.mp3',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: '3',
        title:
          "<audioimage name='1.57'></audioimage>  Listen to more jobs and skills. Find the words you hear in the text in exercise 1. Listen again and repeat the words.",
        color: '#fd7e14',
        // prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page62/E3/1.jpg' }],
    ],
  },

  4: {
    unit: 'Unit 5',
    id: 'SB7-U5-P62-E4',
    audio: 'Audios/1-58 Friends Plus 7.mp3',
    video: '',
    component: T6,
    stylesTextInput: { borderBottom: 'dash' },
    inputSize: 90,
    maxLength: 5,
    exerciseKey: 'img/FriendsPlus/Page62/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: "<audioimage name='1.58'></audioimage> Listen to part of the programmer and write <i>True</i> of <i>False</i>.",
        color: '#fd7e14',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1.</b>Clare isn’t very good at maths#<br>	
            <b>2.</b>Mozart couldn’t compose music when he was eight.#<br>	
            <b>3.</b>Most children can read when they’re six.#<br>	
            <b>4.</b>Leonardo da Vinci wasn’t very good at spelling.#<br>	
            <b>5.</b>Kieron Williamson had £22,000 from his art when he was ten.#<br>	
        </div>  `,
        answer: ['true', 'false', 'true', 'true', 'false'],
      },
    ],
  },
  5: {
    unit: 'Unit 5',
    id: 'SB7-U5-P62-E5',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    inputSize: 200,
    maxLength: 50,
    recorder: true,
    hideBtnFooter: true,
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: '5',
        title: ' Work in pairs. Use comparatuve and superlative form of the adjective in the box to compare jobs.',
        prefix: 'default',
        color: '#fd7e14',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        
        <div style=' padding:3mm 0mm; margin: 1cm 2cm ; border: 3px solid rgb(244 133 45); border-radius: 30px'>
          <div style='margin-right:5mm; text-align: center;'>boring&emsp;&emsp;difficult&emsp;&emsp;easy&emsp;&emsp;exciting<br>good&emsp;&emsp;intesting&emsp;&emsp;useful</div>
        </div>
				<img src='img/FriendsPlus/Page62/E5/2.jpg' style='margin-left: 100px'/>
				<div>		
          
				</div>	
          `,
        answer: [],
      },
    ],
  },
};

export default json;
