import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DrawColor from '../../components/ExcerciseTypes/DrawColor';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB7-U4-P51-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 800,
    exerciseKey: "img/FriendsPlus/Page51/E1/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the story. Where was the rescue? What was the writer doing?",
        color: "#01a951",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <img style="width:60%" src='img/FriendsPlus/Page51/E1/1.jpg'>
      <div>  
        <div style='padding-top: 10px'><b>1.</b> Where was the rescue? <br> #</div>
        <div style='padding-top: 10px'><b>2.</b> What was the writer doing? <br> #</div>
      </div>

            
          `,
        answer: [
          "The rescue was in Kenya.",
          "The writer was taking photos of wild animals.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB7-U4-P51-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", fontSize: 23 },
    inputSize: 550,
    maxLength: 50,
    exerciseKey: "img/FriendsPlus/Page51/E2/Key/answerKey.png",
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the key phrases from the text in your notebook. Then read the text and check your answers.",
        color: "#01a951",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page51/E1/1.jpg' style='width:60% ;margin-left: 100px'>
          <div style='padding:8mm  3mm; margin-top: 50px; border-radius: 30px; background:rgb(209 232 212)'>
            
            <div style='color: white; background: rgb(79 188 123); padding: 20px 20px 20px 20px; margin: -1cm -11px 5mm -11px; border-radius: 25px'><b> KEY PHRASES </b> </div>
            <b style='color:rgb(79 188 123)'>Expressing interest</b>
              <div style='margin-top :5mm'>I took this photo while  <sup>1</sup>#.</div>
              <div style='margin-top :5mm'>It was an amazing  <sup>2</sup>#.</div>
              <div style='margin-top :5mm'>I was lucky to <sup>3</sup>#.</div>
              <div style='margin-top :5mm'>It happened while <sup>4</sup>#.</div>
              <div style='margin-top :5mm'>There was a group of people and <sup>5</sup><input id='4' width='400px'>.</div>
         </div>     
          `,
        answer: [
          "we were on a safari holiday in Kenya last February",
          "rescue",
          "see it",
          "we were taking photos of wild animals ",
          "they were looking at something",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: "Unit 4",
    id: 'SB7-U4-P51-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page51/E3/Key/answerKey.png',
    titleQuestion: [{
      num: '3',
      title: 'Read the text again and find the linking words: after, as soon as, when, while.',
      color: "#01a951"
    }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: { padding: 2 },
          // circle: { padding: 0.75, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
          choose: { fontWeight: 900 }
        },
        // limitSelect: 1,
        listWords: [
          "I took this photo while we were on a safari holiday ",
          "in Kenya last February. It was an amazing rescue ",
          "and I was lucky to see it.",
          "It happened while we were taking photos of wild ",
          "animals. We were driving slowly when we saw ",
          "a bulldozer. There was a group of people and ",
          "they were looking at something  There was an ",
          "elephant. It was in a mud hole and it couldn’t get",
          "out. The elephant wasn’t moving, but it was alive. ",
          "A bulldozer was breaking the sides of the hole. ",
          "As_soon_as it was possible, the elephant climbed out. ",
          "After_a_few_minutes, it walked away.",
          "The elephant was very lucky to be alive after twelve ",
          "hours in the hole. The rescuers were very pleased ",
          "and one of them posted a video of the rescue online.",
        ],
        answers: { '0-8': 'choose', '3-4': 'choose', '4-10': 'choose', '10-0': 'choose', '11-0': 'choose' },

        initialValue: {},
      },
      Layout: `
        <div style="font-size: 21px; position: relative">
          <img src='img/FriendsPlus/Page51/E3/1.jpg' style='width:90% ;margin-left: 100px'>
          <div style="position: absolute; left: 160px; top: 150px">
            <div><input id='0' type='Circle' /></div>
            <div><input id='1' type='Circle' /></div>
            <div><input id='2' type='Circle' /></div>
            <div><input id='3' type='Circle' /></div>
            <div><input id='4' type='Circle' /></div>
            <div><input id='5' type='Circle' /></div>
            <div><input id='6' type='Circle' /></div>
            <div><input id='7' type='Circle' /></div>
            <div><input id='8' type='Circle' /></div>
            <div><input id='9' type='Circle' /></div>
            <div><input id='10' type='Circle' /></div>
            <div><input id='11' type='Circle' /></div>
            <div><input id='12' type='Circle' /></div>
            <div><input id='13' type='Circle' /></div>
            <div><input id='14' type='Circle' /></div>
          </div>
        </div>
      `,
    },
  },
  4: {
    // Exercise num
    unit: "Unit 4",
    id: "SB7-U4-P51-E4",
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page51/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: "4",
        title:
          "Choose the correct linking words.",
        color: "#01a951",
        // prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' }
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: 'none',
          borderRadius: '49%',
          padding: "0 5px",
          fontWeight: 600,
          fontSize: 24,
        },
        selectWordStyle: { border: "2px solid #000" },
        limitSelect: 1,
        listWords: [
          "when /  while",
          "As_soon_as / while",
          "while / when",
          "After_a_few_minutes / As_soon_as",
        ],
        answers: ['0-6', '1-0', '2-4', '3-0'],
        initialValue: [],
      },
      Layout: `
        <div style='margin :2mm 0 0 1cm'><b>1.</b>  I saw a bear <input id='0'  type='Circle' />  I was walking in the forest.</div>
        <div style='margin :5mm  0 0 1cm'><b>2.</b>  <input id='1' type='Circle' /> I saw it, I climbed a tree.</div>
        <div style='margin :5mm 0 0 1cm'><b>3.</b> It ran away <input id='2' type='Circle' /> my friends arrived.</div>
        <div style='margin :5mm 0 0 1cm'><b>4.</b> <input id='3' type='Circle' /> , I climbed down. </div>
      `,
    },
  },
  5: {
    unit: "Unit 4",
    id: "SB7-U4-P51-E5",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 1050 },
    titleQuestion: [
      {
        num: "5",
        title:
          "Follow the steps in the Writing Guide.",
        color: "#01a951",
        prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' }
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='margin: 0 0 5mm 5cm; width:50%' src='img/FriendsPlus/Page51/E5/2.jpg' />
          <div><textarea id="0" rows="15" ></textarea><div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
