import DrawColor from '../../components/ExcerciseTypes/DrawColor';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 4',
    id: 'SB7-U4-P49-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page49/E1/Key/answerKey.png',
    component: T6,
    stylesTextInput: { borderBottom: 'dotted 1px', textAlign:"center" },
    inputSize: 100,
    maxLength: 10,
    titleQuestion: [
      {
        num: '1',
        title: 'Match questions 1-4 with answers a-d. Then complete the rule.',
        color: '#2eb6e1',
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex; gap:50px;'>
            <div>
              <div><b>1.&ensp;</b>Was the squirrel eating flowers?</div>
              <div><b>2.&ensp;</b>Were the two men posing?</div>
              <div><b>3.&ensp;</b>What were the men doing?</div>
              <div><b>4.&ensp;</b>Who was skiing?</div>
            </div>
            <div>
              <div><b>a.&ensp;</b>No, it wasn’t. </div>
              <div><b>b.&ensp;</b>Steve’s friend.</div>
              <div><b>c.&ensp;</b>Yes, they were. </div>
              <div><b>d.&ensp;</b>They were using a computer.</div>
            </div>
          </div>

          <div style="display:flex; gap:50px;margin: 30px 0;">
            <div><b>Match:</b></div>
            <div><b>1 - </b><input width="50px"/></div>
            <div><b>2 - </b><input width="50px"/></div>
            <div><b>3 - </b><input width="50px"/></div>
            <div><b>4 - </b><input width="50px"/></div>
          </div>

          <div style='border: 5px solid rgb(92, 201, 221); border-radius:30px;width:600px;'>
            <div style='padding:5px; margin: 5px; background-color:rgb(92, 201, 221);border-radius:25px;color:rgb(255, 255, 255)'>&emsp;RULE</div>
            <div style='padding:5px; margin: 5px;'>
            We form past continuous questions with:<br/>(question word) + <b><sup>1</sup></b># or <b><sup>2</sup></b># + subject + <i>-ing</i> form.
            </div>
          </div>

        `,
        answer: [
          `a`, `c`, `d`, `b`,
          `was`, `were`,
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'SB7-U4-P49-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page49/E2/Key/answerKey.png',
    component: T6,
    stylesTextInput: { borderBottom: 'dotted 1px', fontSize: 22 },
    inputSize: 700,
    maxLength: 200,
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: '2',
        title: ' Write questions and answers using the past continuous.',
        color: '#2eb6e1',
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page47/E5/2.jpg' }]],
    questions: [
      {
        title: `
          <p >the men / ride / horses ? ✗<br> <span style='color: rgb(107 106 107)'>Were the men riding horses? <br/>No, they weren't.</span></p> 
          <div>
            <div><b>1.</b>  the squirrel / eat / nuts ? ✓<br> #<br> #</div>
            <div><b>2.</b> where / your friends / travel / this time last year? (in canada) <br> #<br> #</div>
            <div><b>3.</b> what / you / do / last night at 8 p.m? ( watch TV) <br> #<br> #</div>
            <div><b>4.</b> Maria / swim / in a river ? ✗ <br> #<br> #</div>
            <div><b>5.</b> Who / you / talk to / after class yesterday? (Mary) <br> #<br> #</div>
          </div>

        `,
        answer: [
          ' Was the squirrel eating nuts ?',
          ' Yes, it was.',
          ' Where were your friends travelling this time last year ?',
          ' They were travelling in Canada.',
          ' What were you doing last night at 8 p.m.?',
          ' I was watching TV.',
          ' Was Maria swimming in a river ?',
          " No, she wasn't.",
          ' Who were you talking to after class yesterday?',
          'I was talking to Mary.',
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 4',
    id: 'SB7-U4-P49-E3',
    audio: 'Audios/1-44 Friends Plus 7.mp3',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page49/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title:
          "<audioimage name='1.44'></audioimage><b style=' color: rgb(28, 190, 215)'>PRONUNCIATION</b> Word stress in questions",
        color: '#2eb6e1',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: { padding: 2 },
          choose: { fontWeight: 900 },
          // circle: { padding: 0.75, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          'Were you sleeping at 7 a.m. yesterday?', //0
          'Was your mum listening to music at 8 p.m. last night ? ', //1
          'What were you doing on Sunday morning?', //2
          'Who were you talking to before class this morning ? ', //3
        ],
        answers: {
          '0-4': 'choose',
          '0-8': 'choose',
          '0-10': 'choose',
          '0-12': 'choose',
          '1-6': 'choose',
          '1-4': 'choose',
          '1-10': 'choose',
          '1-14': 'choose',
          '1-16': 'choose',
          '1-18': 'choose',
          '1-20': 'choose',
          '3-0': 'choose',
          '2-0': 'choose',
          '2-6': 'choose',
          '2-10': 'choose',
          '2-12': 'choose',
          '3-6': 'choose',
          '3-8': 'choose',
          '3-12': 'choose',
          '3-16': 'choose',
        },

        initialValue: {},
      },
      Layout: `
        <div><b> Listen. Which words are stressed in each phrase? Listen again and repeat.</b> </div>
        <div style="font-size: 21px;">
          <div><b>1&emsp;</b><input id='0' type='Circle' /></div>
          <div><b>2&emsp;</b><input id='1' type='Circle' /></div>
          <div><b>3&emsp;</b><input id='2' type='Circle' /></div>
          <div><b>4&emsp;</b><input id='3' type='Circle' /></div>
        </div>
      `,
    },
  },
  4: {
    unit: 'Unit 4',
    id: 'SB7-U3-P49-E4',
    component: T6,
    inputSize: '160px',
    exerciseKey: 'img/FriendsPlus/Page37/E7/Key/answerKey.png',
    checkUppercase: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '4',
        title: ' Work in pairs. Ask and answer the questions from exercise 3. Make true answers.',
        color: '#1CBED7',
      },
    ],
    hintBox: [
      {
        src: ['Were you sleeping at 7 a.m.yesterday ? '],
        borderColor: '#1CBED7',
        arrow: true,
        position: 1,
        width: 280,
      },
      {
        src: ['Yes, I was.'],
        borderColor: '#8BD5E5',
        arrow: true,
        position: 2,
        width: 250,
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex; justify-content: space-between; width: 600px; margin-top: 40px; text-align: center;'>
            <div><hintbox id='0'></hintbox></div>
            <div style=" margin: 30px 0 0 40px"><hintbox id='1'></hintbox></div>
          </div>
          <div style="font-size: 21px; margin-top: 40px">
            <div><b>1&emsp;</b>"Were you sleeping at 7 a.m. yesterday?",	</div>
            <div><b>2&emsp;</b>"Was your mum listening to music at 8 p.m. last night ? ",</div>
            <div><b>3&emsp;</b>"What were you doing on Sunday morning?",</div>
            <div><b>4&emsp;</b>"Who were you talking to before class this morning ? ",</div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Unit 4',
    id: 'SB7-U4-P49-E5',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    inputSize: 200,
    maxLength: 50,
    exerciseKey: 'img/FriendsPlus/Page49/E5/Key/answerKey.png',
    checkUppercase: true,
    titleQuestion: [
      {
        num: '5',
        title: 'Study the sentences. The complete the Rules with <i>past simple</i> and <i>past continuous</i>.',
        color: '#2eb6e1',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <p style='margin:10px'>I saw this squirrel <span style='color: rgb(0,174,239)'> <i>while i was photographing flowers in a park</i></span> <br> I <span style='color: rgb(0,174,239)'><i>was photographing</i></span> flowers <span style ='color: rgb(0,174,239)'> <i>when</i></span> i saw this squirrel.</p>
          <div style='padding:8mm  2mm;width:74% ; border: 3px solid rgb(17 205 239); border-radius: 30px'>
            <div style='padding-block:1mm'><img src='img/FriendsPlus/Page49/E5/1.jpg'; style='margin: -7mm 0 1cm 0;/></div>
            <div style='padding-bottom: 5mm'><b>1.</b> We use the # for longer actions in progress and the # for shorter actions.</div>
            <div style='padding-bottom: 5mm'><b>2.</b> We often use when before the # and while before the # .</div>
          </div>
          `,
        answer: ['past continuous', 'past simple', 'past simple', 'past continuous'],
      },
    ],
  },

  6: {
    unit: 'Unit 4',
    id: 'SB7-U4-P49-E6',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { borderBottom: 'dotted 1px', textAlign: 'center' },
    inputSize: 200,
    maxLength: 50,
    exerciseKey: 'img/FriendsPlus/Page49/E6/Key/answerKey.png',
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: '6',
        title: 'Complete the sentences using the past simple and past continuous form of the verb in brackets.',
        color: '#2eb6e1',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div> We<u style="text-decoration: dotted underline;color: grey">&emsp;<b>saw</b>&emsp;</u> (see) the Pyramids while we <u style="text-decoration: dotted underline;color: grey">&emsp;<b>were visiting </b>&emsp;</u> (visit) Egypt.</div>
          <div style='padding-bottom: 5mm'><b>1.</b> I # (take) photos of my friend while we # (cycle).</div>
          <div style='padding-bottom: 5mm'><b>2.</b> She # (climb) when she  # (have) an accident.</div>
          <div style='padding-bottom: 5mm'><b>3.</b> We # (take) photos of elephants while we # (travel) across Đắk Lắk.</div>
          <div style='padding-bottom: 5mm'><b>4.</b> My parents # (not sleep) when i # (get) home late last night .</div>
        `,
        answer: ['took', 'were cycling', 'was climbing', 'had', 'took', 'were travelling', "weren't sleeping", 'got'],
      },
    ],
  },
  7: {
    unit: 'Unit 4',
    id: 'SB7-U4-P49-E7',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    inputSize: 700,
    maxLength: 200,
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: '7',
        title:
          'Work in pairs. Imagine you travelled around the world.Talk about things you did using the past simple and past continuous.Use the ideas in the table and your own ideas.',
        color: '#2eb6e1',
        prefix: 'default',
      },
    ],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page49/E7/2.jpg'  style='margin: 1cm 2cm ;'/>
        `,
        answer: [],
      },
    ],
  },
  8: {
    unit: 'Unit 4',
    id: 'SB7-U4-P49-E8',
    component: T6,
    finished: {
      text: 'Imagine you travelled around Việt Nam. Write a diary(60 – 80 words) about what you did.',
    },
    textareaStyle: { width: 1050 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <finished></finished>
          <div style='padding-top: 40px'><textarea id="0" rows="12" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
