import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Language Focus',
		id: 'SB7-L-P97-E1',
		exerciseKey: 'img/FriendsPlus/Page97/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '1',
				title: `
                    Choose ONE option to complete the sentence.
                `,
				color: '#1dbcd5',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
					color: '#00aeef',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					borderColor: '#00aeef',
				},
				limitSelect: 1,
				listWords: [
					'to_see / see',
					'go / to_go',
					'have_to / have',
					'to_catch / catch',
				],
				answers: ['0-0', '1-4', '2-0', '3-4'],
				initialValue: [],
			},
			Layout: `
            <div style="width: 600px">
                <ol>
                    <li>
                        I really want <input id='0' type='Circle' /> Ben Thanh 
                        Market and the War Remnants Museum.
                    </li>                    
                    <li>Which gate do we need <input id='1' type='Circle' /> to?</li>                    
                    <li>We <input id='2' type='Circle' /> go to Gate 34, Terminal 1.</li>                    
                    <li>We must <input id='3' type='Circle' /> the shuttle to Terminal 1.</li>                    
                    
                </ol>
            </div>
          `,
		},
	},

	2: {
		unit: 'Language Focus',
		id: 'SB7-L-P97-E2',
		exerciseKey: 'img/FriendsPlus/Page97/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 130,
		maxLength: 20,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
                    Look at the sentences in exercise 1 and complete the Rules.
                `,
				color: '#1dbcd5',
			},
		],
		questions: [
			{
				title: `

            <div style="width: 600px">
                <ol>
                    <li>
                        I really want  (to see / see)  Ben Thanh 
                        Market and the War Remnants Museum.
                    </li>                    
                    <li>Which gate do we need  (go / to go)  to?</li>                    
                    <li>We  (have to / have)  go to Gate 34, Terminal 1.</li>                    
                    <li>We must  (to catch / catch) the shuttle to Terminal 1.</li>                    
                    
                </ol>
            </div>

					<div style="width: 600px; margin-top: 20px;">
						<div style="width: 650px; padding-bottom: 20px; border-radius: 24px; overflow: hidden; border: 3px solid rgb(93,200,220);">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(93,200,220); color: white; padding: 5px 10px; border-radius: 20px; text-transform: uppercase; margin: 2px;">
                                RULES
							</div>
							
							<div style="margin-top: 10px; padding-left: 20px;">
								<div style="font-weight: bold; font-size: 1.6rem">
                                    The infinitive is the basic form of a verb.
                                    We can use it <i>with to</i> or <i>without to</i>.
								</div>
	
								<div>
									We use infinitives <br/>
                                    – # after ordinary verbs like want, need and others. <br/>
                                    – # after modal verbs like must, should and others. <br/>
								</div>
							</div>
						</div>
					</div>
                    
                    
                `,
				answer: ['with to', 'without to'],
			},
		],
	},

	3: {
		unit: 'Language Focus',
		id: 'SB7-L-P97-E3',
		exerciseKey: 'img/FriendsPlus/Page97/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 130,
		maxLength: 9,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '3',
				title: `
                    Complete the sentences with the correct
                    form of the verbs in the brackets.
                `,
				color: '#1dbcd5',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 10px;">
                        <ol>
                            <li>Do you want # with me? (go out)</li>
                            <li>Can you # me? (help)</li>
                            <li>Would you like # with me? (dance)</li>
                            <li>They must # hard. (work)</li>
                            <li>You shouldn't # late. (stay up)</li>
                            <li>You don't need # this exercise. (do)</li>
                            <li>You don't have to # her phone. (answer)</li>
                            <li>We must # helmets while riding a motorbike. (wear)</li>
                        </ol>
                    </div>
                `,
				answer: [
					'to go out',
					'help',
					'to dance',
					'work',
					'stay up',
					'to do',
					'answer',
					'wear',
				],
			},
		],
	},

	4: {
		unit: 'Language Focus',
		id: 'SB7-L-P97-E4',
		exerciseKey: 'img/FriendsPlus/Page97/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 540,
		maxLength: 45,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '4',
				title: `
                    Write the negative and interrogative forms 
                    of these affirmative sentences.
                `,
				color: '#1dbcd5',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 650px; margin-top: 10px;">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <img src="img/FriendsPlus/Page97/E4/1.jpg" style="width: 70%;" />
                        </div>

                        <ol style="margin-top: 10px;">
                            <li>
                                Susan has to get up early. 
                                <br/> #. <br/> #?
                            </li>
                            <li>
                                Peter has to do five exercises.
                                <br/> #. <br/> #?
                            </li>
                            <li>
                                They have to wear ties at work.
                                <br/> #. <br/> #?
                            </li>
                            <li>
                                People have to wear masks at public places.
                                <br/> #. <br/> #?
                            </li>
                        </ol>
                    </div>
                `,
				answer: [
					"Susan doesn't have to stay up late.",
					'Does Susan have to stay up late',
					"Peter doesn't have to do five exercises",
					'Does Peter have to do five exercises',
					"They don't have to wear ties at work",
					'Do they have to wear ties at work',
					"People don't have to wear masks at public places",
					'Do people have to wear masks at public places',
				],
			},
		],
	},

	5: {
		unit: 'Language Focus',
		id: 'SB7-L-P97-E5',
		exerciseKey: 'img/FriendsPlus/Page97/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},

		titleQuestion: [
			{
				num: '5',
				title: `
                    Fill in the blanks with <i>some</i>, <i>any</i> or <i>a lot of</i> / <i>lots of</i>.
                `,
				color: '#1dbcd5',
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 670px; margin-top: 10px;">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <img src="img/FriendsPlus/Page97/E5/1.jpg" style="width: 70%;" />
                        </div>

                        <ol style="margin-top: 10px;">
                            <li>
                                Let's stop and get # petrol at the petrol station.
                            </li>
                            <li>
                                There are # cars on the street,
                                so they cause traffic jams every day.
                            </li>
                            <li>
                                If we don't use # fossil fuels,
                                our environment won't be polluted.
                            </li>
                            <li>
                                People use # solar panels to
                                replace non-renewable sources of energy.
                            </li>
                        </ol>
                    </div>
                `,
				answer: ['some', 'a lot of / lots of', 'any', 'a lot of / lots of'],
			},
		],
	},

	6: {
		unit: 'Language Focus',
		id: 'SB7-L-P97-E6',
		exerciseKey: 'img/FriendsPlus/Page97/E6/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 590,
		maxLength: 100,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		textareaStyle: { width: 740 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
                    Work in pairs. 
                    Tell the other what you <i>want to</i> do, 
                    <i>need to</i> do and <i>must</i> do when you go home every day.
                    
                    
				`,
				color: '#1dbcd5',
				prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
			},
		],
		questions: [
			{
				title: `
                    <div style="margin-top: 20px;">
                        <textarea id="8" rows="10"></textarea>
                    </div>
					
                `,
				answer: [],
			},
		],
	},
	7: {
		unit: 'Language Focus',
		id: 'SB7-I-P97-E5',
		exerciseKey: 'img/FriendsPlus/Page93/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 620 },
		finished: {
			text: `
        Think of more sentences using infinitives with 
				to or without to.	
      `,
		},
		textareaStyle: { width: 740 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
            <finished></finished>
            <textarea id="8" rows="5"></textarea>
				`,
				answer: [],
			},
		],
	},

};

export default json;
