import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB7-U2-P24-E1",
    audio: "Audios/1-18 Friends Plus 7.mp3",
    video: "",
    component: T6,
    stylesTextInput: {
      borderBottom: "dash",
      textAlign: "center",
      border: "2px solid",
      height: 40,
      width: 40,
    },
    inputSize: 35,
    maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page24/E1/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "<audioimage name='1.18'></audioimage> Listen and match phrases A–H with what you hear (1–8). Listen again and check.",
        color: "#F58024",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
         <div style='display:flex'>
            <div style='margin-left: 40px; line-height:50px'>
              <b>A</b>&emsp; be engaged / busy<span style='margin-left:10px'>#</span><br>
              <b>B</b>&emsp; call back<span style='margin-left:114px'>#</span><br>
              <b>C</b>&emsp; dial a number<span style='margin-left: 62px'>#</span><br>
              <b>D</b>&emsp; dowload a ringtone<span style='margin-left:5px'>#</span><br>
            </div>
            <div style='margin-left: 100px; line-height:50px'>
              <b>E</b>&emsp; hang up<span style='margin-left:145px'>#</span><br>
              <b>F</b>&emsp; leave a voicemail<span style='margin-left:49px'>#</span><br>
              <b>G</b>&emsp; put on speakerphone<span style='margin-left:3px'>#</span><br>
              <b>H</b>&emsp; send a text message<span style='margin-left:9px'>#</span><br>
            </div>
         </div>
        `,
        answer: ["8", "3", "1", "6", "2", "7", "4", "5"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "SB7-U2-P24-E2",
    audio: "Audios/1-19 Friends Plus 7.mp3",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page24/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: "Match 1–6 with a–f. Listen and check.",
        color: "#F58024",
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "17px",
              left: "367px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "52px",
              left: "367px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "89px",
              left: "367px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "123px",
              left: "367px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "367px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "367px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "17px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "52px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "89px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "123px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "464px",
              width: 30,
              height: 30,
              // backgroundColor: "white",
              border: "1px solid",
              borderRadius: "50%",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "26px",
              left: "674px",
              width: 20,
              height: 20,
              // backgroundColor: "white",
              borderRadius: "50%",
            },
          }, //12
        ],
        answers: ["0-9", "1-7", "10-2", "3-8", "4-6", "11-5"],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex;font-size:24px'>
            <div >
                <b>1</b> I’m afraid …<br> 
                <b>2</b> His phone’s ringing, …<br> 
                <b>3</b> Wait! Wait! Please, …<br> 
                <b>4</b> Sorry, I can’t hear you.<br> 
                <b>5</b> Oh, I need to top up my
                  phone. <br> 
                <b>6</b> Hello, is that Susan?<br> 
            </div>
            <div style='margin-left:130px'>
                <b>a </b> I haven’t got any credit.<br> 
                <b>b </b> … but he’s not answering<br> 
                <b>c </b> Can you speak up?<br> 
                <b>d </b> … you’ve got the wrong
                number.<br> 
                <b>e </b> … don’t hang up.<br> 
                <b>f </b> No, it’s Janet.<br> 
            </div>
        </div>
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
      `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "SB7-U2-P24-E3",
    audio: "Audios/1-20 Friends Plus 7.mp3",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 250,
    maxLength: 16,
    exerciseKey: "img/FriendsPlus/Page24/E3/Key/answerKey.png",
    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "<audioimage name='1.20'></audioimage> Listen to three conversations. Write who says phrases 1–6 in exercise 2.",

        color: "#F58024",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex'>
          <img style='height: 14cm' src='img/FriendsPlus/Page24/E3/1.jpg'/>
          <div style='margin:10px 0px 0px 20px;line-height:40px'>
            <b>1</b> #<br><b>2</b> #<br><b>3</b> #<br>
            <b>4</b> #<br><b>5</b> #<br><b>6</b> #
          </div>
        </div>
        `,
        answer: [
          "Anthony",
          "Andrew’s grandma",
          "David’s mum",
          "David",
          "Janet",
          "Ollie",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB7-U2-P24-E4",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 1000 },
    exerciseKey: "",
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Work in pairs. Imagine you are in the following situations. Think of your answers and then explain your reasons to your partner.",
        color: "#F58024",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
              <div0>
                <b>1</b> You are in town with friends. Your mum calls you. <br/>Do you ...
                <div style='margin-left:20px'>
                  <b>a.</b> answer your phone?<br>
                  <b>b.</b> pretend you can’t hear it?<br>
                  <b>c.</b> answer, but say that the line is bad and hang up?<br>
                </div>
                <b>2</b> You are talking to a friend on the phone in a public place.
                <div style='margin-left:20px'>
                  <b>a.</b> explain that the line is bad, hang up and call later?<br>
                  <b>b.</b> continue the call and speak up?<br>
                  <b>c.</b> hang up and send a message?<br>
                </div>
                <b>3</b> You send an instant message to a friend with important
                news. Your friend doesn’t answer. Do you …
                <div style='margin-left:20px'>
                  <b>a.</b> forget about it and wait for your friend’s response?<br>
                  <b>b.</b> think your friend is ignoring you and feel angry?<br>
                  <b>c.</b> call to tell your friend to look at the message?<br>
                </div>
              </div0>
              <div style=''>
                <img src='img/FriendsPlus/Page24/E4/1.jpg'>
                <img src='img/FriendsPlus/Page24/E4/2.jpg'>
                <img src='img/FriendsPlus/Page24/E4/3.jpg'>
              </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
