// TEMPLATE
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
    1: {
        unit: "Option 1",
        id: "SB7-O1-P106-E1",
        exerciseKey: "img/FriendsPlus/Page106/E1/Key/answerKey.png",
        audio: "Audios/3-02 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 80,
        maxLength: 5,
        stylesTextInput: {
            fontSize: 23,
        },

        titleQuestion: [
            {
                num: "1",
                title: `
                <audioimage name='3.02'></audioimage> 
                Fill in the blank with <i>play, do, go</i> or <i>watch</i>. 
                Then listen and check.
              `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                    <div style="width: 600px; margin-top: 30px;">
                        <ol>
                            <li><strong  style="color: rgba(140,135,132,1)">play</strong> footbal</li>
                            <li># aerobics</li>
                            <li># television</li>
                            <li># the drums</li>
                            <li># swimming</li>
                            <li># jogging</li>
                            <li># films</li>
                            <li># the piano</li>
                            <li># badminton</li>
                            <li># gymnastics</li>
                        <ol>
                    </div>
                `,
                answer: [
                    'do',
                    'watch',
                    'play',
                    'go',
                    'go',
                    'watch',
                    'play',
                    'play',
                    'do',
                ],
            },
        ],
    },
    2: {
        unit: "Option 1",
        id: "SB7-O1-P106-E2",
        exerciseKey: "img/FriendsPlus/Page106/E2/Key/answerKey.png",
        audio: "Audios/3-03 Friends Plus 7.wav",
        video: "",
        component: T6,
        inputSize: 185,
        maxLength: 16,
        stylesTextInput: {
            fontSize: 23,
        },
        titleQuestion: [
            {
                num: "2",
                title: `
                <audioimage name='3.03'></audioimage> 
                Listen to a dialogue between two friends. 
                Which phrases from exercise 1 do you hear?
              `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                    <div style="width: 600px; margin-top: 30px;">
                        <img src="img/FriendsPlus/Page106/E2/1.jpg" style="max-width: 100%" />
                        
                        <ol style="margin-top: 30px;">
                            <li>#</li>
                            <li>#</li>
                        </ol>
                    </div>
                `,
                answer: [
                    'watch television',
                    'play the piano',
                ],
            },
        ],

    },

    3: {
        unit: "Option 1",
        id: "SB7-O1-P106-E3",
        exerciseKey: "img/FriendsPlus/Page106/E3/Key/answerKey.png",
        audio: "Audios/3-03 Friends Plus 7.wav",
        video: "",
        component: T6,
        inputSize: 530,
        maxLength: 47,
        stylesTextInput: {
            // textAlign: 'center',
            fontSize: 24,
        },
        titleQuestion: [
            {
                num: "3",
                title: `
                <audioimage name='3.03'></audioimage> 
                Listen to the dialogue again and answer the questions.
              `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                    <div style="width: 680px">
                        <ol style='margin-top: 30px'>
                            <li>Why doesn't Emily often watch television? #</li>
                            <li>What is her hobby? #</li>
                            <li>When does Tina play the piano? #</li>
                            <li>How often does Emily practise the piano? #</li>
                        </ol>
                    </div>  
                `,

                answer: [
                    "She doesn't really like television adverts. / She doesn't really like television adverts",
                    "Her hobby is playing the piano. / Her hobby is playing the piano",
                    "Tina usually plays the piano in the afternoons. / Tina usually plays the piano in the afternoons",
                    "Emily practises the piano once or twice a week. / Emily practises the piano once or twice a week",
                ],
                // answer: [
                //     "Emily doesn't often watch television because she doesn't really like television adverts.",
                //     "Her hobby is playing the piano.",
                //     "Tina plays the piano most afternoons. / Tina usually plays the piano in the afternoons.",
                //     "Emily practises the piano once or twice a week.",
                // ],

            },
        ],

    },

    4: {
        unit: "Option 1",
        id: "SB7-O1-P106-E4",
        exerciseKey: "img/FriendsPlus/Page106/E4/Key/answerKey.png",

        audio: "Audios/3-03 Friends Plus 7.wav",
        video: "",

        component: T6,
        inputSize: 140,
        maxLength: 8,
        // checkDuplicated: true,
        stylesTextInput: {
            // textAlign: 'center',
            fontSize: 23,
        },

        hintBox: [{
            src: [
                'normally',
                'often',
                'sometimes',
                'usually',
            ],
            borderColor: "#f77e1d",
            width: 525,
        },],

        titleQuestion: [
            {
                num: "4",
                title: `
                <audioimage name='3.03'></audioimage>
                Complete the Key Phrases with the words in the box. 
                Then listen to the dialogue again and check.
            `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                    <div style="margin-top: 20px; width: 450px;"><hintbox id='0'></hintbox></div>
  
                    <div style="margin-top: 20px; width: 450px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                        <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                            Key Phrases
                        </div>
                        
                        <div style="margin-top: 10px; padding-left: 20px;">
                            <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                            Talk about personal interests
                            </div>
  
                            <div>
                                I play the piano.<br/>
                                I do, too.<br/>
                                How <sup>1</sup># do you practise?<br/>
                                Most afternoons, <sup>2</sup>#<br/>
                                <sup>3</sup># it's hard to find time.<br/>
                                I <sup>4</sup># practise once or twice a week.<br/>
                            </div>
                        </div>
                    </div>
                    
                `,
                answer: [
                    'often',
                    'usually',
                    'Sometimes',
                    'normally',
                ],
            },
        ],

    },

    5: {
        unit: "Option 1",
        id: "SB7-O1-P106-E5",
        exerciseKey: "img/FriendsPlus/Page106/E5/Key/answerKey.png",

        audio: "Audios/3-04 Friends Plus 7.wav",
        video: "",

        component: T6,
        inputSize: 190,
        maxLength: 14,
        stylesTextInput: {
            fontSize: 23,
        },

        hintBox: [{
            src: [
                'How far',
                'I do',
                'What do you do',
                'enough time',
                'What about',
                'any interest',
            ],
            borderColor: "#f77e1d",
            width: 525,
        },],

        titleQuestion: [
            {
                num: "5",
                title: `
                    <audioimage name='3.04'></audioimage>
                    Listen and fill in the dialogue with the correct phrases. 
                    There are two extra phrases. Then practise the dialogue.
                `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                    <div style="width: 600px;">
                        <hintbox id='0'></hintbox>

                        <div style="margin-top: 5px;">
                            <div style="display: flex;">
                                <span style="margin-right: 25px">Tim</span>
                                <span><sup>1</sup># when you're not studying?</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 12px">John</span>
                                <span>Well, I sometimes go swimming.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Tim</span>
                                <span>Really? <sup>2</sup>#, too!</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 12px">John</span>
                                <span>How often do you swim?</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Tim</span>
                                <span>I swim most evenings, usually. <sup>3</sup># you?</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 12px">John</span>
                                <span>Once or twice a week. I haven't got <sup>4</sup> # to go more than that.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 25px">Tim</span>
                                <span>I know what you mean. It's hard to find the time. I'm going swimming this evening.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 12px">John</span>
                                <span>Me too! See you at the swimming pool!</span>
                            </div>
                        </div>
                    </div>
                `,
                answer: [
                    "What do you do",
                    "I do",
                    "What about",
                    "enough time",
                ],
            },
        ],

    },

    6: {
        unit: "Option 1",
        id: "SB7-O1-P106-E6",
        exerciseKey: "img/FriendsPlus/Page106/E6/Key/answerKey.png",
        audio: "Audios/3-02 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 600,
        maxLength: 100,
        textareaStyle: { width: 1000 },
        hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
        hintBox: [
            {
                src: ["What do you do when you're not studying?"],
                borderColor: "#f77e1d",
                arrow: true,
                position: 1,
                width: 525,
            },
            {
                src: ["I often do aerobics."],
                borderColor: "#f77e1d",
                arrow: true,
                position: 2,
                width: 280,
            },
        ],
        titleQuestion: [
            {
                num: "6",
                title: `
            Work in pairs. 
            Ask and answer about your personal interests using the Key Phrases.
            `,
                color: "#f77e1d",
                prefix: "default",
            },
        ],
        questions: [
            {
                title: `
                <div style="margin-top: 20px; width: 600px;">
                    <hintbox id='0'></hintbox>

                    <div style="margin-top: 40px; margin-left: 280px">
                        <hintbox id='1'></hintbox>
                    </div>

                    <div style="margin-top: 20px;"><textarea id="0" rows="7" ></textarea></div>
                </div>
            `,

                answer: [],
            },
        ],
    },

};

export default json;