// TEMPLATE
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
    1: {
        unit: "Option 6",
        id: "SB7-O6-P111-E1",
        exerciseKey: "img/FriendsPlus/Page111/E1/Key/answerKey.png",
        audio: "",
        video: "",
        component: T6,
        inputSize: 210,
        maxLength: 16,
        // checkDuplicated: true,
        hintBox: [{
            src: [
                'school uniform',
                'school lunches',
                'skipping classes',
                'exam pressure',
                'bullying',
                'homework',
                'class size',
                'sports',
                'cheating',
                'school trips',
                'school clubs',
            ],
            borderColor: "#f77e1d",
            width: 525,
        },],
        titleQuestion: [
            {
                num: "1",
                title: `
                Check the meaning of the topics in the box.
                Then match four of the topics with photos A–D.
            `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                    <div style="margin-top: 20px; width: 550px">
                        <hintbox id='0'></hintbox>
                        <div style="margin-top:20px;"><img src="img/FriendsPlus/Page111/E1/1.jpg" /></div>
                        <ol style="list-style-type: upper-alpha;">
                            <li>#</li>
                            <li>#</li>
                            <li>#</li>
                            <li>#</li>
                        </ol>
                    </div>
                `,

                answer: ['sports', 'school lunches', 'school uniform', 'class size'],
            },
        ],

    },

    2: {
        unit: "Option 6",
        id: "SB7-O6-P111-E2",
        exerciseKey: "img/FriendsPlus/Page111/E2/Key/answerKey.png",
        audio: "Audios/3-17 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 210,
        maxLength: 16,
        hintBox: [{
            src: [
                'school uniform',
                'school lunches',
                'skipping classes',
                'exam pressure',
                'bullying',
                'homework',
                'class size',
                'sports',
                'cheating',
                'school trips',
                'school clubs',
            ],
            borderColor: "#f77e1d",
            width: 525,
        },],
        titleQuestion: [
            {
                num: "2",
                title: `
                <audioimage name='3.17'></audioimage>
                Listen to an interview with two
                students, Will and Olivia. Which four topics
                about school life from exercise 1 do they
                mention?
            `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                <div style="margin-top:30px;"><hintbox id='0'></hintbox></div>
                <ol style="margin-top: 50px">
                    <li>#</li>
                    <li>#</li>
                    <li>#</li>
                    <li>#</li>
                </ol>
            `,
                answer: ['cheating', 'bullying', 'school trips', 'class size'],
            },
        ],

    },

    3: {
        unit: "Option 6",
        id: "SB7-O6-P111-E3",
        exerciseKey: "img/FriendsPlus/Page111/E3/Key/answerKey.png",
        audio: "Audios/3-17 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 85,
        maxLength: 6,
        // checkDuplicated: true,
        // stylesTextInput: {textAlign: 'center'},
        titleQuestion: [
            {
                num: "3",
                title: `
                <audioimage name='3.17'></audioimage>
                Listen to the interview again and
                write Will or Olivia next to the opinions.
              `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                    <ol style='margin-top: 20px; width: 550px;'>
                        <li>Cheating is not a big problem. #</li>
                        <li>Students shouldn’t take mobile phones into exams. #</li>
                        <li>Cyber bullying is a serious problem. #</li>
                        <li>There should be more school trips to help with studies. #</li>
                        <li>Classes should be smaller. #</li>
                    </ol>
                `,
                answer: [
                    'Will',
                    'Olivia',
                    'Will',
                    'Olivia',
                    'Will',
                ],
            },
        ],

    },

    4: {
        unit: "Option 6",
        id: "SB7-O6-P111-E4",
        exerciseKey: "img/FriendsPlus/Page111/E4/Key/answerKey.png",
        audio: "Audios/3-17 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 200,
        maxLength: 15,
        // checkDuplicated: true,
        stylesTextInput: {
            // textAlign: 'center',
            fontSize: 23,
        },
        titleQuestion: [
            {
                num: "4",
                title: `
                <audioimage name='3.17'></audioimage>
                Complete the Key Phrases, then listen again and check.
              `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                    <div style="margin-top: 20px; width: 450px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                        <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                            Key Phrases
                        </div>
                        
                        <div style="margin-top: 10px; padding-left: 20px;">
                            <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                                Opinions
                            </div>

                            <div>
                                Do you think there’s a problem with <br/> <sup>1</sup> # at school?<br/>
                                Not <sup>2</sup>#<br/>
                                I disagree <sup>3</sup># <br/>
                                Yes, <sup>4</sup>#<br/>
                                How can <sup>5</sup># improve<br/>
                                We should definitely have <br/> <sup>6</sup>#
                            </div>
                        </div>
                    </div>
                    
                `,
                answer: [
                    'cheating',
                    'really',
                    'with that',
                    "that's right",
                    'the school',
                    'smaller classes',
                ],
            },
        ],

    },

    5: {
        unit: "Option 6",
        id: "SB7-O6-P111-E5",
        exerciseKey: "img/FriendsPlus/Page111/E5/Key/answerKey.png",
        audio: "Audios/3-18 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 400,
        maxLength: 36,
        // checkDuplicated: true,
        stylesTextInput: {
            textAlign: 'center',
            fontSize: 24,
        },
        titleQuestion: [
            {
                num: "5",
                title: `
                <audioimage name='3.18'></audioimage>
                Complete the dialogue with the key
                phrases. Listen and check. Then practise the
                dialogue with a partner.
            `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                <div style="width: 600px">
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Sally</span>
                        <span>
                            # skipping classes at school?
                        </span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Dan</span>
                        <span>
                        # . A few students go into town, but not many. 
                        What about school uniforms? Do you think we should have one?
                        </span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Sally</span>
                        <span>Yes, # a uniform.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Dan</span>
                        <span>
                            I agree with that. If everyone wears the same thing it makes us all the same.
                        </span>
                    </div>

                    <div style="display: flex;">
                        <span style="margin-right: 20px">Sally</span>
                        <span>Yes, #. And our uniform is easy to wear.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Dan</span>
                        <span>I think sport is a problem. We should have more classes.</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Sally</span>
                        <span># with that. I don’t want any more! # improve?</span>
                    </div>
                    
                    <div style="display: flex;">
                        <span style="margin-right: 20px">Dan</span>
                        <span>School lunches aren’t very good. I think there should be more choice.</span>
                    </div>
                </div>
            `,

                answer: [
                    'Do you think there is a problem with',
                    'Not really',
                    'we should definitely have',
                    "that's right",
                    'I disagree',
                    'How can our school',
                ],
            },
        ],
    },

    6: {
        unit: "Option 6",
        id: "SB7-O6-P111-E6",
        exerciseKey: "",
        audio: "",
        video: "",
        component: T6,
        inputSize: 500,
        maxLength: 100,
        hideBtnFooter: true,
        textareaStyle: { width: 1000 },
        hintBox: [{
            src: [
                'school uniform',
                'school lunches',
                'skipping classes',
                'exam pressure',
                'bullying',
                'homework',
                'class size',
                'sports',
                'cheating',
                'school trips',
                'school clubs',
            ],
            borderColor: "#f77e1d",
            width: 525,
        },],

        titleQuestion: [
            {
                num: "6",
                title: `
                Work in pairs. Prepare and
                practise a new dialogue using ideas about
                your school. Use the topics in exercise 1, the
                dialogue in exercise 5 and the key phrases.
            `,
                color: "#f77e1d",
                prefix: "default",
            },
        ],

        questions: [
            {
                title: `
                <div style="margin-top: 20px; width 550px">
                    <hintbox id='0'></hintbox>
                    <div style="margin-top: 20px">
                    <div style="margin-top: 20px;"><textarea id="0" rows="7" ></textarea></div>
                    </div>
                </div>
            `,
                answer: ['play', 'musical', 'concert'],
            },
        ],
    },

};

export default json;