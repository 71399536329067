import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB7-U3-P34-E1',
    component: T6,
    inputSize: '50px',
    textAlign: 'center',
    maxLength: 1,
    textTransform: 'uppercase',
    questionImage: [],
    exerciseKey: 'img/FriendsPlus/Page34/E1/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position: relative;'>
          <img src='img/FriendsPlus/Page34/E1/1.jpg' style="width:39%" />
          <img src='img/FriendsPlus/Page34/E1/2.jpg' style="width:39%" />
          <img src='img/FriendsPlus/Page34/E1/3.jpg' style="width:86%"/>
          <img src='img/FriendsPlus/Page34/E1/4.jpg' style="width:86%"/>
          <div style=" position: absolute; top: 615px; left: 639px; ">#</div> 
          <div style=" position: absolute; top: 800px; left: 832px; ">#</div>
          <div style=" position: absolute; top: 1205px; left: 510px; ">#</div>
          <div style=" position: absolute; top: 1330px; left: 290px; ">#</div>
          <div style=" position: absolute; top: 1370px; left: 848px; ">#</div>
        </div>
        `,
        answer: ['E', 'D', 'C', 'A', 'B']
      }
    ]
  },

  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB7-U3-P34-E2',
    audio: 'Audios/1-27 Friends Plus 7.mp3',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page34/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: "2",
        title:
          `<audioimage name="1.27"></audioimage>  Complete the short history with the 
correct <span style='color: #2dc6f8;'>blue</span> adjectives. Listen and check.`,
        color: "#0067b2",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight:"bold",
          color: "#00aeef",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "boring / exciting", //0
          "poor / rich",
          "dead / alive",
          "familiar / uncommon",
          "awful / brave",
          "useful / useless",
          "brilliant / terrible",
          "cruel / kind",
          "popular / unpopular",
          "enormous / tiny"
        ],
        answers: ['0-4', '1-4', '2-0', '3-0', '4-4', '5-4', '6-0', '7-0', '8-0', '9-0'],
        initialValue: [],
      },

      Layout: `

<img src="img/FriendsPlus/Page34/E2/2.jpg" style="width: 100%">
        <img src="img/FriendsPlus/Page34/E2/3.jpg" style="width: 100%">

        <div><b>1.</b> This is so <input id='0' type='Circle' />.</div>
        <div><b>2.</b> About 1134 The first European universities were in Bologna, Oxford, Paris and Salamanca. Only VIPS and <input id='1' type='Circle' /> people could study at university.</div>
        <div><b>3.</b> Between 1206 and 1227, Genghis Khan invaded countries in Asia and Europe and left almost 40 million people <input id='2' type='Circle' />.</div>
        <div><b>4.</b> This is <input id='3' type='Circle' />.</div>
        <div><b>5.</b> She's <input id='4' type='Circle' />.</div>
        <div><b>6.</b> This is <input id='5' type='Circle' />.</div>
        <div><b>7.</b> It's <input id='6' type='Circle' />.</div>
        <div><b>8.</b> Slavery is <input id='7' type='Circle' />.</div>
        <div><b>9.</b> Victoria became Queen of the UK in 1837. She ruled for 63 years and seven months. She was very <input id='8' type='Circle' />.</div>
        <div><b>10.</b> Wow! The universe is <input id='9' type='Circle' />.</div>
      `,
    },
  },

  3: {
    unit: 'Unit 3',
    id: 'SB7-U3-P34-E3',
    component: T6,
    textareaStyle: { width: 1080 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "3",
        title:
          " Think of people, places and events that you know in Việt Nam. Use the adjectives from the short history to describe them.</b>",
        color: "#0067b2",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: []
      },
    ]
  },
}

export default json;