import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 2',
    id: 'SB7-U2-P25-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page25/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: 'Match questions 1–4 with answers a–d. Then choose <br/>the correct words in rules 1–2.',
        color: '#1dbfd7',
      },
    ],
    question: {
      Write: {
        underlineStyle: { border: '1px solid' },
        inputStyle: {
          width: 39,
          textAlign: 'center',
          fontSize: 26,
          color: 'black',
          maxLength: 1,
        },
        answers: ['d', 'c', 'b', 'a'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          fontStyle: 'italic',
          fontWeight: 'bold',
          // fontSize: 30,
        },
        selectWordStyle: {
          padding: 3,
          border: 'solid 2px',
          borderColor: '#1dbfd7',
        },
        limitSelect: 1,
        listWords: [
          'be / do / have.', //0
          'be / do / have.', //1
        ],
        answers: ['0-0', '1-0'],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div style='margin-top:15px'>
          <div><b>1</b> Are the boys watching TV? <input id='0' /></div>
            <div><b>2</b> What are they chatting about? <input id='1' /></div>
            <div><b>3</b> Is Becky answering questions? <input id='2' /></div>
            <div><b>4</b> What’s she doing with her mum? <input id='3' /></div>

            <div><b>a.</b> She’s cooking.</div>
            <div><b>b.</b> Yes, she is.</div>
            <div><b>c.</b> They’re chatting about sport.</div>
            <div><b>d.</b> No, they aren’t.</div>
        </div>
        <div style='margin:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 15cm'>
          <div style='font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>RULES</div>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:20px'>In the present continuous we make questions using the verb  <input id='0' type='Circle'/></div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>We make short answers with a pronoun, like I, we, etc., and the verb<input id='1' type='Circle'/> </div>
          </div>
          </div>
      `,
    },
  },
  2: {
    unit: 'Unit 2',
    id: 'SB7-U2-P25-E2',
    audio: 'Audios/1-21 Friends Plus 7.mp3',
    video: '',
    component: Circle_Write,
    recorder: true,
    exerciseKey: 'img/FriendsPlus/Page25/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title:
          "<audioimage name='1.21'></audioimage> <span style='color: #1dbfd7; font-weight: 900'>PRONUNCIATION</span> <span style='color: #1dbfd7'>Diphthongs</span> Listen. Choose the words whose underlined part is pronounced differently from that of the others.",
        color: '#1dbfd7',
        // prefix: {
        //   icons: [""],
        //   text: " PRONUNCIATION",
        // },
      },
    ],
    hintBox: [
      {
        src: ['are becoming', 'aren’t speaking', 'is sending', 'isn’t using'],
        width: 500,
        borderColor: '#1dbfd7',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 210, color: '#000', textAlign: 'center' },
        answers: [],
        initialValue: [],
        textAlign: 'center',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // border: "2px solid",
          borderRadius: '50%',
          borderColor: 'red',
          // fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: [
          'br<u>ea</u>k &emsp;&emsp; st<u>ea</u>k &emsp;&emsp; gr<u>ea</u>t &emsp;&emsp; p<u>ea</u>r', //1
          's<u>ou</u>nd &emsp;&emsp; sh<u>ou</u>t &emsp;&emsp; gr<u>ou</u>nd &emsp;&emsp; sh<u>ou</u>lder', //1
          'b<u>ea</u>r &emsp;&emsp; cl<u>ea</u>r &emsp;&emsp; h<u>ea</u>r &emsp;&emsp; n<u>ea</u>r', //1
          'ch<u>oi</u>r &emsp;&emsp; ch<u>oi</u>ce &emsp;&emsp; n<u>oi</u>se &emsp;&emsp; <u>oi</u>l', //1
          'h<u>ai</u>r &emsp;&emsp; f<u>ai</u>l &emsp;&emsp; l<u>ai</u>r &emsp;&emsp; f<u>ai</u>r', //1
        ],
        answers: ['0-12', '1-12', '3-0', '4-4', '2-0'],
        initialValue: [],
      },
      Layout: `
        
        <div style=' line-height: 50px'>
          <div style='margin-left:20px'>
            <b>1</b>&emsp;<input id='0' type='Circle'/>
          </div>
          <div style='margin-left:20px'>
            <b>2</b>&emsp;<input id='1' type='Circle'/>
          </div>
          <div style='margin-left:20px'>
            <b>3</b>&emsp;<input id='2' type='Circle'/>
          </div>
          <div style='margin-left:20px'>
            <b>4</b>&emsp;<input id='3' type='Circle'/>
          </div>
          <div style='margin-left:20px'>
            <b>5</b>&emsp;<input id='4' type='Circle'/>
          </div>
        </div>
        
      `,
    },
  },
  3: {
    unit: 'Unit 2',
    id: 'SB7-U2-P25-E3',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    inputSize: 500,
    maxLength: 60,
    exerciseKey: 'img/FriendsPlus/Page25/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: 'Order the words to make questions. Then ask and answer <br/>the questions with your partner.',
        color: '#1dbfd7',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div>
            <b>1</b> you / are / listening to / your teacher / ?<br>
            <div style='margin-left: 20px'>#</div>
            <b>2</b> your teacher / what / doing / is / ?<br>
            <div style='margin-left: 20px'>#</div>
            <b>3</b> speaking / the person next to you / is / ?<br>
            <div style='margin-left: 20px'>#</div>
            <b>4</b> are / you / near a window / sitting / ?<br>
            <div style='margin-left: 20px'>#</div>
            <b>5</b> are / talking a lot / the people in your class / ?<br>
            <div style='margin-left: 20px'>#</div>
            <b>6</b> what / thinking / are / you / ?<br>
            <div style='margin-left: 20px'>#</div>
          </div>
        `,
        answer: [
          'Are you listening to your teacher?',
          'What is your teacher doing?',
          'Is the person next to you speaking?',
          'Are you sitting near a window?',
          'Are the people in your class talking a lot?',
          'What are you thinking?',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 2',
    id: 'SB7-U2-P25-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page25/E4/Key/answerKey.png',
    inputSize: 150,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    maxLength: 10,
    textAlign: 'center',
    isHiddenCheck: true,
    checkUppercase: true,
    checkDuplicated: true,
    titleQuestion: [
      {
        num: '4',
        title: 'Read the examples and then complete the Rules.',
        color: '#1ec0d9',
      },
    ],
    // hintBox: [
    //   {
    //     src: ["doesn't", "don't", "end", "habits", "routines", "start"],
    //     borderColor: "#1bb7d0",

    //     width: 350,
    //   },
    // ],
    questions: [
      {
        title: `
        <span style='color:gray'>  
          I often listen to music on my phone.<br>
          I’m listening to a great song at the moment.
        </span>
      <div style='margin:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 15cm'>
        <div style='font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>RULES</div>
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>We use the present # for actions in progress.
          </div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>We use the present # for routines or
          repeated actions.
          .</div>
        </div>
        
      </div>
        `,
        answer: ['continuous', 'simple'],
      },
    ],
  },
  5: {
    unit: 'Unit 2',
    id: 'SB7-U2-P25-E5',
    audio: '',
    video: '',
    component: D1,
    textAlign: 'center',
    fontSize: 25,
    maxLength: 16,
    inputHeight: 30,
    titleQuestion: [
      {
        num: '5',
        title: 'Complete the interview with the present simple or present continuous form of the verbs.',
        color: '#1dbfd7',
      },
    ],
    // hideRulesRequired: true,
    exerciseKey: 'img/FriendsPlus/Page25/E5/Key/answerKey.png',
    questionImage: [
      [{ url: 'img/FriendsPlus/Page25/E5/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page25/E5/2.jpg' },
        {
          url: 'img/FriendsPlus/Page25/E5/3.jpg',
          input: true,
          answer: 'are you studying',
        },
        { url: 'img/FriendsPlus/Page25/E5/4.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page25/E5/5.jpg' }],
      [
        { url: 'img/FriendsPlus/Page25/E5/6.jpg' },
        {
          url: 'img/FriendsPlus/Page25/E5/7.jpg',
          input: true,
          answer: 'am working',
        },
        { url: 'img/FriendsPlus/Page25/E5/8.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page25/E5/9.jpg' }],
      [
        { url: 'img/FriendsPlus/Page25/E5/10.jpg' },
        {
          url: 'img/FriendsPlus/Page25/E5/11.jpg',
          input: true,
          answer: 'are studying',
        },
        { url: 'img/FriendsPlus/Page25/E5/12.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page25/E5/13.jpg' }],
      [
        { url: 'img/FriendsPlus/Page25/E5/14.jpg' },
        {
          url: 'img/FriendsPlus/Page25/E5/15.jpg',
          input: true,
          answer: 'are you doing',
        },
        { url: 'img/FriendsPlus/Page25/E5/16.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page25/E5/17.jpg' },
        {
          url: 'img/FriendsPlus/Page25/E5/18.jpg',
          input: true,
          answer: 'are listening',
        },
        { url: 'img/FriendsPlus/Page25/E5/19.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page25/E5/20.jpg' }],
      [
        { url: 'img/FriendsPlus/Page25/E5/21.jpg' },
        {
          url: 'img/FriendsPlus/Page25/E5/22.jpg',
          input: true,
          answer: 'make',
        },
        { url: 'img/FriendsPlus/Page25/E5/23.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page25/E5/24.jpg' }],
      [
        { url: 'img/FriendsPlus/Page25/E5/25.jpg' },
        {
          url: 'img/FriendsPlus/Page25/E5/26.jpg',
          input: true,
          answer: 'Are they playing ',
        },
        { url: 'img/FriendsPlus/Page25/E5/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E5/28.jpg' },
        {
          url: 'img/FriendsPlus/Page25/E5/29.jpg',
          input: true,
          answer: 'play',
        },
        { url: 'img/FriendsPlus/Page25/E5/30.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page25/E5/31.jpg' }],
    ],
  },
  6: {
    unit: 'Unit 2',
    id: 'SB7-U2-P25-E6',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    inputSize: 500,
    maxLength: 44,
    recorder: true,
    exerciseKey: 'img/FriendsPlus/Page25/E3/Key/answerKey.png',
    hideBtnFooter: true,
    hintBox: [
      {
        src: [
          'are',
          'does',
          'phone',
          'what',
          'you',
          'your sister',
          'call',
          'get bad',
          'reception',
          'hang up',
          'how often',
          'talk to',
          'the wrong number',
          'when',
          'who',
          'why',
          'your brother',
          'your parents',
        ],
        width: 500,
        borderColor: '#1dbfd7',
      },
    ],
    titleQuestion: [
      {
        num: '6',
        title:
          ' Write present simple and present continuous questions using the words in the box and your own ideas. then ask and answer the questions with your partner',
        color: '#1dbfd7',
        prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display:flex; justify-content: center; width: 900px"><hintBox id='0'></hintBox></div>
        `,
        answer: [],
      },
    ],
  },
  7: {
    unit: 'Unit 2',
    id: 'SB7-U2-P25-E7',
    audio: '',
    video: '',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700 },
    hideBtnFooter: true,
    finished: {
      text: 'Write questions about people in your class.',
    },
    questions: [
      {
        title: `
        <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
