import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Language Focus',
    id: 'SB7-L-P95-E1',
    exerciseKey: 'img/FriendsPlus/Page95/E1/Key/answerKey.png',

    audio: '',
    video: '',

    component: T6,
    inputSize: 120,
    maxLength: 7,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 23,
    },

    titleQuestion: [
      {
        num: '1',
        title: `
                    Fill in the blanks with the words from the text on page 94.
                `,
        color: '#1ebfd7',
      },
    ],

    questions: [
      {
        title: `
					<div style="width: 600px; margin-top: 20px;">
                        <div style="display: flex; width: 400px">
                            <img src="img/FriendsPlus/Page95/E1/1.jpg" style="max-width: 100%;" />
                            <img src="img/FriendsPlus/Page95/E1/2.jpg" style="max-width: 100%;" />
                        </div>        
                        
                        <ol style="margin-top: 20px;">
                            <li>
                                Solar energy is renewable # the sun never stops producing sunlight.
                            </li>
                            <li>
                                Solar panels catch sun rays # change them into electricity.
                            </li>
                            <li>
                                We use fossil fuels in our daily life, # they are limited and cause pollution.
                            </li>
                            <li>
                                There is nothing to lose when we use solar energy # wind power.
                            </li>
                        </ol>
					</div>
                `,
        answer: ['because', 'and', 'but', 'or'],
      },
    ],
  },

  2: {
    unit: 'Language Focus',
    id: 'SB7-L-P95-E2',
    exerciseKey: 'img/FriendsPlus/Page95/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 110,
    maxLength: 7,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 22,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
                    Look at the sentences in exercise 1 and 
                    choose the correct words in the Rules.
                `,
        color: '#1ebfd7',
      },
    ],

    questions: [
      {
        title: `

                    <div style="display: flex; width: 500px">
                        <img src="img/FriendsPlus/Page94/E1/1.jpg" style="max-width: 100%;" />
                        <img src="img/FriendsPlus/Page94/E1/2.jpg" style="max-width: 100%;" />
                    </div>     

					<div style="width: 640px; margin-top: 20px;margin-left: 150px;">
						<div style="width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; border: 1px solid rgb(93,200,220);">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(93,200,220); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
                                RULES
							</div>
							
							<div style="margin-top: 10px; padding-left: 20px;">
								<div style="font-weight: bold; font-size: 1.6rem">
                                    We use <i>because, and, but</i> and <i>or</i> to connect ideas.
								</div>
	
								<div>
                                    We use <br/>
                                    – # for giving additional information. <br/>
                                    – # for giving different information. <br/>
                                    – # for giving choices. <br/>
                                    – # for giving reasons. <br/>
								</div>
							</div>
						</div>
					</div>
                    
                    
                `,
        answer: ['and', 'but', 'or', 'because'],
      },
    ],
  },

  3: {
    unit: 'Language Focus',
    id: 'SB7-L-P95-E3',
    exerciseKey: 'img/FriendsPlus/Page95/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 120,
    maxLength: 10,
    stylesTextInput: {
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
                    Look at pages 27 and 46 again, then fill in the blanks with 3 different words: <i>Although,however</i> and <i>but</i>.
                `,
        color: '#1ebfd7',
      },
    ],
    questions: [
      {
        title: `

<div style="margin-bottom: 30px;">
<div><b>Page 27, excercise 3.4</b></div>
          <div><img src='img/FriendsPlus/Page27/E3/1.jpg'></div>

          <div style='display: flex'>
              <div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>1</b>
                    <div style='width: 8cm;margin-left:20px'>Everybody speaks English in class, but...</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>2</b>
                    <div style='width: 8cm;margin-left:20px'>Everybody reads in
                    their own language.</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>3</b>
                    <div style='width: 8cm;margin-left:20px'>Everybody listens
                    to songs in English.
                    However, …</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>4</b>
                    <div style='width: 8cm;margin-left:20px'>A few people know
                    three languages, but …</div>
                  </div>
              </div>
              <div style='margin-left:170px' >
                  <div style="display: flex;font-size:24px;color:black">
                    <b>a</b>
                    <div style='width: 8cm;margin-left:20px'>only a few people
                    read in a second
                    language.</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>b</b>
                    <div style='width: 8cm;margin-left:20px'>songs in English
                    are more difficult
                    to understand.</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>c</b>
                    <div style='width: 8cm;margin-left:20px'> everybody is
                    learning English.</div>
                  </div>
                  <div style="display: flex;font-size:24px;color:black">
                    <b>d</b>
                    <div style='width: 8cm;margin-left:20px'>nobody speaks
                    English outside
                    class.</div>
                  </div>
              </div>
          </div>
</div>

<div><b>Page 46, excercise 5</b></div>
        <div style="font-weight: bold; color: grey ;margin-bottom: 30px;">
          <div> Their business was different, but it was meaningful to the environment.</div>
          <div> → Although their business was different, it was meaningful to the environment.</div>
        </div>

					<div style="width: 600px; margin-top: 20px;">
            <ol style="margin-top: 20px;">
                <li>
                    We use fossil fuels in our daily life, # they are limited and cause pollution.
                </li>
                <li>
                    We use fossil fuels in our daily life; #, they are limited and cause pollution.
                </li>
                <li>
                    # we use fossil fuels in our daily life, they are limited and cause pollution.
                </li>
            </ol>
					</div>
                `,
        answer: ['but', 'hower', 'Although'],
      },
    ],
  },

  4: {
    unit: 'Language Focus',
    id: 'SB7-L-P95-E4',
    exerciseKey: 'img/FriendsPlus/Page95/E4/Key/answerKey.png',

    audio: '',
    video: '',

    component: T6,
    inputSize: 690,
    maxLength: 63,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },

    titleQuestion: [
      {
        num: '4',
        title: `
                    Rewrite the sentences with the words in the brackets.
                `,
        color: '#1ebfd7',
      },
    ],

    questions: [
      {
        title: `
					<div style="width: 650px">
                        <div style="display: flex; justify-content: center;">
                            <div style="width: 500px; padding-bottom: 10px; border-radius: 20px; overflow: hidden; border: 1px solid rgb(93,200,220);">
                                <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(93,200,220); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
                                    REMEMBER
                                </div>
                                
                                <p style="padding: 20px 20px 0;">
                                    The English language has various ways to link ideas. 
                                    When you connect ideas, you can use more than one word 
                                    but the positions of the words are different.
                                </p>
                            </div>
                        </div>
                        
                        <ol style="margin-top: 20px;">
                            <li>
                                We should stop using fossil fuels. 
                                They cause pollution. (… because …) <br/> #
                            </li>
                            <li>
                                We should choose solar energy. Otherwise, 
                                we should choose wind power. (… or …) <br/> #
                            </li>
                            <li>
                                Wind power is renewable. It is safe. (… and …) <br/> #
                            </li>
                            <li>
                                Nuclear power is unlimited. It is unsafe. (, but …)<br/> #
                            </li>
                            <li>
                                Nuclear power is unlimited. It is unsafe. (; however, …)<br/> #
                            </li>
                        </ol>
					</div>
                `,
        answer: [
          'We should stop using fossil fuels because they cause pollution.',
          'We should choose solar energy, or we should choose wind power.',
          'Wind power is renewable and safe.',
          'Nuclear power is unlimited, but it is unsafe.',
          'Nuclear power is unlimited; however, it is unsafe.',
        ],
      },
    ],
  },

  5: {
    unit: 'Language Focus',
    id: 'SB7-L-P95-E6',
    audio: 'Audios/2-27 Friends Plus 7.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page95/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
					<audioimage name='2.27'></audioimage><b style='color: rgb(28, 190, 215)'>PRONUNCIATION</b> <span style='color: rgb(28, 190, 215)'>Consonant clusters</span> <br/>
					A consonant cluster is a group of two or more 
					consonant sounds that are pronounced 
					together with no vowel sound between them. <br/>
					Listen and circle the words with consonant clusters.

				`,
        color: '#1ebfd7',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          margin: 20,
          padding: 10,
          border: 'none',
          borderRadius: '10px',
          borderColor: 'transparent',
          color: 'black',
          display: 'inline-block',
          width: 120,
          textAlign: 'left',
        },
        selectWordStyle: {
          padding: 5,
          margin: 20,
          border: 'solid 2px',
          borderColor: 'black',
          display: 'inline-block',
          width: 120,
          textAlign: 'center',
        },
        limitSelect: 2,
        listWords: [
          'scientist listen cyclist fasten', //1
          'single singer triangle ringer', //2
          'climber plumber member timber', //3
          'school science scale scissors', //4
          'changing singing bringing challenge', //5
        ],
        answers: ['0-0', '0-4', '1-0', '1-4', '2-4', '2-6', '3-0', '3-4', '4-0', '4-6'],
        initialValue: [],
      },
      Layout: `
			<div><b>1&ensp;</b> <input id='0' type='Circle' /></b></div>
			<div><b>2&ensp;</b> <input id='1' type='Circle' /></b></div>
			<div><b>3&ensp;</b> <input id='2' type='Circle' /></b></div>
			<div><b>4&ensp;</b> <input id='3' type='Circle' /></b></div>
			<div><b>5&ensp;</b> <input id='4' type='Circle' /></b></div>
		  `,
    },
  },

  6: {
    unit: 'Language Focus',
    id: 'SB7-L-P95-E6',
    exerciseKey: 'img/FriendsPlus/Page95/E6/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 100,
    maxLength: 8,
    stylesTextInput: {
      fontSize: 23,
    },

    textareaStyle: { width: 620 },

    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

    titleQuestion: [
      {
        num: '6',
        title: `
					Work in pairs. One gives two ideas, and the other connects them.
                `,
        color: '#1ebfd7',
        prefix: 'default',
      },
    ],

    questions: [
      {
        title: `
					<div style="margin-top: 20px; width: 600px;">
						<div style="display: flex; justify-content: center;">
							<img src="img/FriendsPlus/Page95/E6/1.jpg" style="max-width: 100%;" />
						</div>
						<div style="margin-top: 30px;">
							<textarea id="8" rows="10"></textarea>
						</div>
					</div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
