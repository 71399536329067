import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";


const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'SB7-U6-P76-E1',
		audio: 'Audios/2-11 Friends Plus 7.mp3',
		video: '',
		padding: 0,
		textAlign: 'left',
		maxLength: 40,
		exerciseKey: 'img/FriendsPlus/Page76/E1/Key/answerKey.png',
		questionImage: [],
		titleQuestion: [
			{
				num: '1',
				title: '<audioimage name="2.11"></audioimage>Complete the imperative sentences with the verbs in the box. ',
				color: '#dc2c39',
			},
		],
		component: T6,
		inputSize: 170,
		checkUppercase: true,
		hintBox: [
			{
				src: ['applications', 'receiver', 'speed', 'track'],
				borderColor: '#dc2c39',
				width: 600,
			},
		],
		questions: [
			{
				title: `
        <div ><img src='img/FriendsPlus/Page76/E1/1.png' alt=''style='border-radius: 20px;'/></div>
        <div style='margin: 30px 0 30px 150px; '><hintbox id= '0'></hintbox></div>
        <div style='display:flex; justify-content: space-around; gap:50px'>
          <div>
            <div><b>1 </b> #</div>
            <div><b>2 </b> #</div>
          </div>
          <div>
            <div><b>3 </b> #</div>
            <div><b>4 </b> #</div>
          </div>
        </div>
        
        
        `,
				answer: ['track', 'receiver', 'speed', 'applications'],
			},
		],
	},

	2: {
		unit: 'Unit 6',
		id: 'SB7-U6-P76-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page76/E2/key/answerKey.png',
		component: MatchDots,
		stylesTextInput: { borderBottom: '1px dashed', textAlign: 'center' },
		inputSize: 50,
		titleQuestion: [{ num: '2', title: ' Read the text again and match 1–6 with a–f.', color: '#dc2c39' }],
		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '750px',
							left: '430px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '789px',
							left: '430px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // d
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '828px',
							left: '430px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '868px',
							left: '430px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // b
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '947px',
							left: '430px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '985px',
							left: '430px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // e
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '750px',
							left: '570px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '789px',
							left: '570px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // c
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '828px',
							left: '570px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '868px',
							left: '570px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // a
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '947px',
							left: '570px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // a
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '985px',
							left: '570px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // a
				],
				answers: ['0-10', '1-6', '11-2', '3-7', '4-9', '5-8'],
				initialValue: [],
			},
			Layout: `
        <div><img src='img/FriendsPlus/Page76/E1/1.png' alt=''style='border-radius: 20px;'/></div>
        <div style="display: flex; margin-top: 20px; font-size: 26px;">
          <div style='margin-left: 20px;'>
            <div><b>1</b> The USA developed GPS …</div>
            <div><b>2</b> Millions of people …</div>
            <div><b>3</b> The system needs to be …</div>
            <div><b>4</b> You must know which way …</div>
            <div><b>5</b> To track your movement, you should …</div>
            <div><b>6</b> Smartphones now have …</div>
          </div>
         
          <div style='margin-left: 50px; margin-top: 200px;'>
            <input id='0' type= 'boxMatch' />
            <input id='1' type= 'boxMatch' />
            <input id='2' type= 'boxMatch' />
            <input id='3' type= 'boxMatch' />
            <input id='4' type= 'boxMatch' />
            <input id='5' type= 'boxMatch' />
          </div>
         
          <div style='margin-left: 100px;'>
            <div><b>a.</b> … can now use GPS.</div>
            <div><b>b.</b> … can now use GPS. </div>
            <div><b>c.</b> … lots of ways to help people survive. </div>
            <div><b>d.</b> … find your position at different points in time. </div>
            <div><b>e.</b> … for soldiers to use in war. </div>
            <div><b>f.</b> …  in contact with satellites in space. </div>
          </div>
         
          <div style='margin-left: 50px; margin-bottom: 100px;'>
            <input id='6' type= 'boxMatch' />
            <input id='7' type= 'boxMatch' />
            <input id='8' type= 'boxMatch' />
            <input id='9' type= 'boxMatch' />
            <input id='10' type= 'boxMatch' />
            <input id='11' type= 'boxMatch' />
          </div>
        </div>
        <div style='height: 150px;'></div>
        
      `,
		},
	},

	3: {
		// Exercise num
		unit: 'Unit 6',
		id: 'SB7-U6-P76-E3',
		audio: '',
		video: '',
		padding: 0,
		textAlign: 'left',
		maxLength: 100,
		exerciseKey: 'img/FriendsPlus/Page76/E3/Key/answerKey.png',
		questionImage: [],
		titleQuestion: [
			{
				num: '3',
				title: 'Read the text again and answer the questions. ',
				color: '#dc2c39',
			},
		],
		component: T6,
		inputSize: 750,
		checkUppercase: true,
		questions: [
			{
				title: `
        <div><img src='img/FriendsPlus/Page76/E3/1.png' alt=''style='border-radius: 20px;'/></div>
        <div style='margin: 40px;'>
          <div>
            <div><b>1 </b> When did the USA invent GPS?</div>
            <div> # .</div>
          </div>
          <div>
            <div><b>2 </b> What can anyone use GPS for?</div>
            <div> # .</div>
          </div>
          <div>
            <div><b>3 </b> How does GPS provide information on your speed and direction?</div>
            <div> # .</div>
          </div>
          <div>
            <div><b>4 </b> What four things must explorers carry to survive?</div>
            <div> # .</div>
          </div>
        </div>
        
        
        `,
				answer: [
					'In the 1970s',
					'To find out exactly their locations, and track their movements / Finding out exactly their locations, and track their movements',
					'By tracking your position over time',
					'A smartphone and GPS, a compass, torch and map',
				],
			},
		],
	},

	4: {
		unit: 'Unit 6',
		id: 'SB7-U6-P76-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title:
					'Invent a new smartphone application. Say what it does, and write instructions for how to use it. Tell your class about your application. Vote for the most interesting one.',
				color: '#dc2c39',
				prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'USE IT!' },
			},
		],
		textareaStyle: { width: 1140 },
		hideBtnFooter: true,
		questionImage: [],
		hintBox: [
			{
				src: ['If you use my application, you’ll ...'],
				borderColor: '#dc2c39',
				arrow: true,
				position: 1,
				width: 500,
			},
			{
				src: ['To use it, you must ... and ...'],
				borderColor: '#dc2c39',
				arrow: true,
				position: 2,
				width: 400,
			},
			{
				src: ['You should / shouldn’t ...'],
				borderColor: '#dc2c39',
				arrow: true,
				position: 1,
				width: 400,
			},
		],
		questions: [
			{
				title: `
            <div style="margin-top: 10px; margin-left: 130px; font-weight: 600;"><hintbox id= '0' ></hintbox></div>
            <div style='font-weight: 600; margin: 10px 0px 30px 470px;'><hintbox id= '1'></hintbox></div>
            <div style="font-weight: 600; margin-bottom: 60px;margin-left: 130px;" ><hintbox id= '2'></hintbox></div>
            <textarea id="1" rows="8"></textarea>
              
            `,
				answer: [],
			},
		],
	},
}

export default json;