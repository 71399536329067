import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'SB7-U6-P74-E1',
		audio: '',
		video: '/Videos/Speaking/U6-2.09-Trang 74.mp4',
		padding: 0,
		textAlign: 'left',
		maxLength: 40,
		exerciseKey: 'img/FriendsPlus/Page74/E1/Key/answerKey.png',
		questionImage: [],
		titleQuestion: [
			{
				num: '1',
				title:
					'Complete the dialogue with the words in the box. Then watch or listen and check. Which two items of safety equipment does Mark need? ',
				// title:
				// 	'<videoimage></videoimage><audioimage name="2.09" ></audioimage> Complete the dialogue with the words in the box. Then watch or listen and check. Which two items of safety equipment does Mark need? ',
				color: '#d71f75',
			},
		],
		component: T6,
		inputSize: 100,
		hintBox: [
			{
				src: ['fast', 'follow', 'head', 'slowly', 'small', 'wear'],
				borderColor: '#d71f75',
				width: 600,
			},
		],
		questions: [
			{
				title: `
          <div style='display: flex; margin: 20px; background-color: #d4e4ea;'>
            <div style='display: flex; flex-direction: column; flex-basis: 65%;margin: 20px; '>
              <hintbox id= '0' ></hintbox>
              <div style=' margin: 20px; '>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> So, is this my bike? </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Yes, it is. And here’s your safety vest. </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> A safety vest? Really? </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Yes! And make sure that you <b><sup>1</sup></b># it when you’re on your bike. Every time.</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> OK. Can you check my helmet? It feels too  <b><sup>2</sup></b># . </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Let’s see. Yes, it’s fine. It needs to be secure. It’s important to protect your <b><sup>3</sup></b># .</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> You won’t go too <b><sup>4</sup></b># ?</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Don’t worry. We’ll go <b><sup>5</sup></b># . Try not to be nervous or you won’t enjoy it. </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> OK.</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Just remember to stay with the group. You’ll be fine if you follow my instructions. Are we ready?</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> Yes.</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Let’s go, then. <b><sup>6</sup></b># me.</div>
                </div>
                <div style='margin-top: 20px  '>
                  <div><b><i>Which two items of safety equipment does Mark need?</i></b></div>
                  <div><input id='6' width="600px" textAlign: "left" /></div>
                </div>
              </div>
            </div>
            <div style='flex-basis:35%; margin-top: 80px;'><img src='img/FriendsPlus/Page74/E1/1.png'</div>
          </div>
        `,
				answer: [
					'wear',
					'small',
					'head',
					'fast',
					'slowly',
					'Follow',
					'Mark needs a safety vest and a helmet. / A safety vest and a helmet.',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 6',
		id: 'SB7-U6-P74-E2',
		audio: '',
		video: '/Videos/Speaking/U6-2.09-Trang 74.mp4',
		padding: 0,
		textAlign: 'center',
		maxLength: 40,
		exerciseKey: 'img/FriendsPlus/Page74/E2/Key/answerKey.png',
		questionImage: [],
		titleQuestion: [
			{
				num: '2',
				title:
					'Read the key phrases. Cover the dialogue and complete them with one word. Watch or listen again and check.',
				// title:
				// 	'<videoimage></videoimage><audioimage name="2.09" ></audioimage> Read the key phrases. Cover the dialogue and complete them with one word. Watch or listen again and check.',
				color: '#d71f75',
			},
		],
		component: T6,
		inputSize: 140,
		hintBox: [],
		questions: [
			{
				title: `
        <div style='background-color: rgb(230, 196, 203); margin-top: 20px; margin-left:200px; width:600px; border-radius: 15px'>
          <div style='background-color: rgb(215, 31, 117); border-radius: 15px;'><h1 style='color: rgb(255, 255, 255); padding: 5px 5px 5px 25px;'>KEY PHRASES</h1></div>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(193, 26, 101) ;'>Giving instructions and safety information</b></div>
            <div>Make<sup>1</sup># that …</div>
            <div>I / You / It need(s)<sup>2</sup># …</div>
            <div>It’s<sup>3</sup># to …</div>
            <div>Don’t<sup>4</sup># .</div>
            <div>Try (not) <sup>5</sup># …</div>
            <div>Remember<sup>6</sup># …</div>
            <div>You’ll be fine<sup>7</sup># …</div>
          </div>
      </div>
        `,
				answer: ['sure', 'to', 'important', 'worry', 'to', 'to', 'if'],
			},
		],
	},
	3: {
		unit: 'Unit 6',
		id: 'SB7-U6-P74-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title: 'Work in pairs. Practise the dialogue.',
				color: '#d71f75',
				prefix: { icons: ['far fa-comment'] },
			},
		],
		exerciseKey: '',
		textareaStyle: { width: 1140 },
		hideBtnFooter: true,
		questionImage: [],
		hintBox: [],
		questions: [
			{
				title: `
        <div style='display: flex; margin: 20px; background-color: #d4e4ea;'>
            <div style='display: flex; flex-direction: column; flex-basis: 65%;margin: 20px; '>
              <div style=' margin: 20px; '>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> So, is this my bike? </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Yes, it is. And here’s your safety vest. </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> A safety vest? Really? </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Yes! And make sure that you <b><sup>1</sup></b># it when you’re on your bike. Every time.</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> OK. Can you check my helmet? It feels too  <b><sup>2</sup></b># . </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Let’s see. Yes, it’s fine. It needs to be secure. It’s important to protect your <b><sup>3</sup></b># .</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> You won’t go too <b><sup>4</sup></b># ?</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Don’t worry. We’ll go <b><sup>5</sup></b># . Try not to be nervous or you won’t enjoy it. </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> OK.</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Just remember to stay with the group. You’ll be fine if you follow my instructions. Are we ready?</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Mark </b></div>
                  <div> Yes.</div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> Amy </b></div>
                  <div> Let’s go, then. <b><sup>6</sup></b># me.</div>
                </div>
              </div>
            </div>
            <div style='flex-basis:35%; margin-top: 80px;'><img src='img/FriendsPlus/Page74/E1/1.png'</div>
          </div>
        `,
				answer: [],
			},
		],
	},
	4: {
		unit: 'Unit 6',
		id: 'SB7-U6-P74-E4',
		audio: '/Audios/2-10 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 40,
		stylesTextInput: {
			border: '2px solid',
			borderBottom: 'dash',
			height: 40,
			// fontSize: 30,
		},
		maxLength: 1,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '4',
				title:
					"<audioimage name= '2.10'></audioimage> Put the dialogue in the correct order. Listen and check. Then practise it with your partner.",
				color: '#d71f75',
				prefix: { icons: ['far fa-comment'] },
			},
		],
		exerciseKey: 'img/FriendsPlus/Page74/E4/Key/answerKey.png',
		hideBtnFooter: false,
		isHiddenCheck: true,
		questionImage: [],
		hintBox: [],
		questions: [
			{
				title: `
        <div style='line-height:50px'>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>A</b> You’ll be fine if you follow me.</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>A</b> Let’s see. It’s fine. Your helmet needs to be secure. OK, are you ready?</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>A</b> It’s important to check your equipment first, Becky. Is your helmet OK?</div>
            <div style='width:40px; height: 40px; border: 2px solid rgb(0, 0, 0);margin-right: 4px;'><b><i style="padding: 5px 12px; color: green;">1</i></b></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>A</b> Don’t worry. Just go slowly and remember to relax and use your legs.</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>B</b> Erm, yes, I think so. I’m nervous.</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>B</b> That’s a very big rock! What if I fall?</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>B</b> It isn’t very comfortable. It feels very small.</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
        </div>
        `,
				answer: ['7', '3', '5', '4', '6', '2'],
			},
		],
	},
	5: {
		unit: 'Unit 6',
		id: 'SB7-U6-P74-E5',
		audio: '',
		video: '',
		component: T6,
		recorder: true,
		titleQuestion: [
			{
				num: '5',
				title:
					'Work in pairs. Practise a new dialogue for the situation using the Key Phrases and the dialogue in exercise 1 to help you.',
				color: '#d71f75',
				prefix: 'default',
			},
		],
		exerciseKey: '',
		textareaStyle: { width: 1140 },
		hideBtnFooter: true,
		questionImage: [],
		hintBox: [
			{
				src: ['fast', 'follow', 'head', 'slowly', 'small', 'wear'],
				borderColor: '#d71f75',
				width: 600,
			},
		],
		questions: [
			{
				title: `
        <div style='background-color: rgb(230, 196, 203); margin-top: 20px; margin-left:200px; width:600px; border-radius: 15px'>
          <div style='background-color: rgb(215, 31, 117); border-radius: 15px;'><h1 style='color: rgb(255, 255, 255); padding: 5px 5px 5px 25px;'>KEY PHRASES</h1></div>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(193, 26, 101) ;'>Giving instructions and safety information</b></div>
            <div>Make<sup>1</sup># that …</div>
            <div>I / You / It need(s)<sup>2</sup># …</div>
            <div>It’s<sup>3</sup># to …</div>
            <div>Don’t<sup>4</sup># .</div>
            <div>Try (not) <sup>5</sup># …</div>
            <div>Remember<sup>6</sup># …</div>
            <div>You’ll be fine<sup>7</sup># …</div>
          </div>
        </div>
            <div style='margin: 20px 0 30px 200px;'><hintbox id= '0' ></hintbox></div>
            <div style='background-color: rgb(230, 196, 203); padding: 20px; border-radius: 20px;'>
              <div style='padding: 15px;border: 2px solid rgb(193, 26, 101); margin-bottom: 20px;'><b>Student A:</b> You are going to go on a mountain bike trip in the countryside. Ask the instructor to check your equipment. Tell him / her that you are a little scared of cycling in the countryside and ask for instructions.</div>
              <div style='padding: 15px;border: 2px solid rgb(193, 26, 101);'><b>Student B:</b> You are an instructor at an activity centre and you are taking a group of students on a mountain bike trip in the countryside. Check student A’s equipment. Ask if he / she is OK and if he / she has any questions or doubts.</div>
            </div>              
            `,
				answer: [],
			},
		],
	},
}

export default json
