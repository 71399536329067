import { useState } from 'react';

const useCaseInput = () => {
  const [text, setText] = useState([]);
  const [resultText, setResultText] = useState([]);

  const handleColor = (group, index, isDone) => {
    let color = 'black';
    if (resultText.length === 0) return;
    if (isDone) {
      const rowIndex = resultText.findIndex((x) => x.some((k) => k.group === group));
      const colIndex = resultText[rowIndex].findIndex((x) => x.index === index);
      color = resultText[rowIndex][colIndex].isCorrect ? 'green' : 'red';
    }
    return color;
  };

  const handleChange = (group, index, value) =>
    setText((pre) => {
      const result = pre[group]?.findIndex((x) => x.index === index);
      if (result === -1 || result === undefined) {
        const existIndex = pre.findIndex((x) => x.some((k) => k.group === group));
        if (existIndex !== -1) {
          pre[existIndex] = [...pre[existIndex], { group, index, value }];
          return pre;
        }
        return [...pre, [{ group, index, value }]];
      } else {
        pre[group].splice(result, 1);
        pre[group] = [...pre[group], { group, index, value }];
        return pre;
      }
    });

  return { handleChange, handleColor, resultText, setResultText, setText, text };
};

export default useCaseInput;

// group: dataIndex
// index: id
// value:e.target.value
