import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 6",
    id: "SB7-U6-P73-E1",
    audio: "",
    video: "",
    component: T6,
    maxLength: 5,
    inputSize: 80,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page73/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at these sentences about Kay Freeman’s Desert Challenge. Write <i>true</i> or <i>false</i>.",
        color: "#1dbfd7",
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex; margin-top: 30px; gap: 50px;'>
            <div style='flex-basis:40%'>
                <b>1</b> You must be fit to go on the Desert Challenge.
                #<br> 
                <b>2</b> You mustn’t leave the group
                #<br> 
                <b>3</b> You should take a map.
                #<br> 
                <b>4</b> You shouldn’t wear shorts and a T-shirt.
                #<br> 
            </div>
            <div style='flex-basis:60%;'><img src='img/FriendsPlus/Page73/E1/1.png' alt=''/></div>
        </div>
        `,
        answer: ["true", "true", "false", "true",],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB7-U6-P73-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page73/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: "Study the sentences in exercise 1 and complete the Rules <br/>with <i>must, mustn’t, should</i> and <i>shouldn’t</i>.",
        color: "#1dbfd7",
      },
    ],
    inputSize: 150,
    stylesTextInput: { borderBottom: "dotted 1px" },
    maxLength: 10,
    textAlign: "center",
    checkUppercase: true,
    checkDuplicated: true,
    questions: [
      {
        title: `
      <div style='margin:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 15cm'>
        <div style='font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>RULES</div>
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>We use # and # to give advice.</div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>We use # and #  to talk about a strong obligation. </div>
        </div>
      </div>
        `,
        answer: ["should", "shouldn't", "must", "mustn't"],
      },
    ],
  },

  3: {
    unit: "Unit 6",
    id: "SB7-U6-P73-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "1px dashed", textAlign: "left" },
    inputSize: 450,
    exerciseKey: 'img/FriendsPlus/Page73/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: "3",
        title:
          "Correct the mistakes in the sentences.",
        color: "#1dbfd7",
      },
    ],
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
            <div >
                <div><b>1</b> You must to have a medical certificate. ✗</div>
                <div>#</div>
                <div><b>2</b> He should works harder. ✗</div>
                <div>#</div>
                <div><b>3</b> We should not to camp near a river. ✗</div>
                <div>#</div>
                <div><b>4</b> They don't must take their dog. ✗</div>
                <div>#</div>
            </div>        
                    `,
        answer: ["You must have a medical certificate.", 
        "He should work harder.", 
        "We should not camp near a river.", 
        "They mustn't take their dog.",],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "SB7-U6-P73-E4",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page73/E4/Key/answerKey.png",
    titleQuestion: [
      { num: "4", title: "Choose the correct words.", color: "#2CBAEA" },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          " should / must ", //1
          " should / must  ", //2
          "shouldn’t / mustn’t", //3
          " shouldn’t / mustn’t",
          "should / must ",
          "shouldn’t / mustn’t",
        ],
        answers: ['0-2', '1-6', '2-4', '3-2', '4-0', '5-4'],
        initialValue: [],
      },
      Layout: `
      <div  style="display: flex;flex-direction: row;line-height: 48px;justify-content: space-between;">
        <div>
          <div><b style = "margin-right:15px">1</b> You <b><input id='0' type='Circle' /></b> watch this film. It’s very 
          good. </div>
          <div><b style = "margin-right:15px">2</b> We <b><input id='1' type='Circle' /></b> wear uniforms at our school. </div>
          <div><b style = "margin-right:15px">3</b> You <b><input id='2' type='Circle' /></b>  take dogs into the 
          park. It isn’t allowed. </div>
          <div><b style = "margin-right:15px">4</b> You <b><input id='3' type='Circle' /></b> buy that T-shirt. 
          It’s expensive. </div>
          <div><b style = "margin-right:15px">5</b> You <b><input id='4' type='Circle' /></b> wear a hat. It’s very sunny.   </div>
          <div><b style = "margin-right:15px">6</b> You <b><input id='5' type='Circle' /></b>  pick flowers here. 
          That’s the rule. </div>
        </div>
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "SB7-U6-P73-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "1px dashed", textAlign: "left" },
    inputSize: 600,
    exerciseKey: 'img/FriendsPlus/Page73/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: "5",
        title:
          "Look at the signs. Write sentences with <i>should, shouldn't, must</i> or <i> mustn't</i>. and the prompts.",
        color: "#1dbfd7",
      },
    ],
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
            <div style='width:100%; display:flex; '>
                <div style='width:60%; display:inline-block;'>
                    <div>wear / hat</div>
                    <div><b><i>You should wear a hat.</i></b></div>
                    <div><b>1</b> bring / water</div>
                    <div>#</div>
                    <div><b>2</b> make / fire</div>
                    <div>#</div>
                    <div><b>3</b> use / sunscreen</div>
                    <div>#</div>
                    <div><b>4</b> come / between 11 a.m. and 3 p.m.</div>
                    <div>#</div>
                    <div><b>5</b> swim / red flag</div>
                    <div>#</div>
                    <div><b>6</b> touch / jellyfish</div>
                    <div>#</div>
                    <div><b>7</b> bring / your dog</div>
                    <div>#</div>
                </div>        
                <div style='flex-grow: 1; '><img src="img/FriendsPlus/Page73/E5/1.png" alt=''/></div>
            </div>
                    `,
        answer: ["You should bring water.", "You mustn't make a fire.", " You should use sunscreen.", " You shouldn't come between 11 a.m. and 3 p.m.", "You mustn't swim when there's a red flag.", "You shouldn't touch jellyfish.", "You mustn't bring your dog."],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "SB7-U6-P73-E6",
    video: "",
    audio: "Audios/2-08 Friends Plus 7.mp3",
    component: T6,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title:
          `<audioimage name="2.08"></audioimage> <span style='color:#1dbfd7'><b>PRONUNCIATION</b> <i>/ʌ/ in must, and silent t in mustn't</i></span> <br/>Listen to the sentences. Then listen again and repeat.`,
        color: "#1bb7d0",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='margin-left: 20px;'>
            <div><b>1</b> You mustn't make a fire.</div>
            <div><b>2</b> You must stay here.</div>
            <div><b>3</b> You mustn't wear shorts.</div>
            <div><b>4</b> You must be quiet.</div>
         </div>
                
              `,
        answer: [],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "SB7-U6-P73-E7",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: "Work in pairs. Give advice and make rules for your school. Use the ideas in the box and your own ideas. Then compare your ideas with another pair's.",
        color: "#1bb7d0",
        prefix: "default",
      },
    ],
    exerciseKey: "",
    textareaStyle: { width: 1140 },
    hideBtnFooter: true,
    questionImage: [],
    hintBox: [
      {
        src: [
          "arrive on time",
          "be polite to teachers",
          "bring a pet",
          "do your homework",
          "eat in class",
          "run in the corridors",
          "shout in the library",
          "wear jewellery",
          "work hard",
        ],
        borderColor: "#1bb7d0",
        width: 800,
      },
      {
        src: ["You shouldn't wear jewellery."],
        borderColor: "#1bb7d0",
        arrow: true,
        position: 1,
        width: 400,
      },
      {
        src: ["You mustn't bring your pet!"],
        borderColor: "#1bb7d0",
        arrow: true,
        position: 2,
        width: 400,
      },
    ],
    questions: [
      {
        title: `
            <div style="margin-top: 10px; margin-left: 130px; "><hintbox id= '0' ></hintbox></div>
            <div style="margin-top: 60px; margin-left: 110px; ">
                <div style='font-weight: 600'><hintbox id= '1'></hintbox></div>
                <div style="margin: -40px 0px 40px 430px; font-weight: 600;" ><hintbox id= '2'></hintbox></div>
              </div>
              <textarea id="1" rows="5"></textarea>
              
            `,
        answer: [],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB7-U6-P73-E8",
    audio: "Audios/2-07 Friends Plus 7.mp3",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 950 },
    hideBtnFooter: true,
    inputSize: 700,
    finished: {
      text: "A friend is going on a forest challenge. Write a paragraph (60 - 80 words) to give him / her some advice. Use the objects in exercise 1 (page 72) and what you remember from the listening in exercise 3 (page 72)",
    },
    hintBox: [
      {
        src: [
          'tent',
          'sleeping bag',
          'rope',
          'map',
          'water bottle',
          'mirror',
          'lighter',
          'compass',
          'first-aid kit',
          'torch',
          'knife',
        ],
        borderColor: '#f58023',
        width: 700,
      },
    ],
    questions: [
      {
        title: `
        <finished></finished>
        <div>
                              <img src='/img/FriendsPlus/Page72/E2/1.png' style=''/>
                      <div style='margin-left: 100px;'><hintbox id='0'></hintbox></div>
        <textarea id="1" rows="8"></textarea>
        </div>
        `,
        answer: [],
      },
    ],
  },

}

export default json;