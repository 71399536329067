import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB7-U5-P66-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 300,
    maxLength: 18,
    exerciseKey: "img/FriendsPlus/Page66/E1/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title: "Read the text and answer question 1-4.",
        color: "#db2f3a",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
					
							<div style='display: flex;border-radius: 20px;border: 10px solid rgb(217 217 217); background:rgb(209 226 156); padding:10px 0px'>
									<img style='margin-right:10px;height:2cm;width:4cm' src='img/FriendsPlus/Page66/E1/2.jpg'>
									<div style='font-style: italic; width: 20cm'><b >In the 200m freestyle, swimmers must swim four lengths of the pool the pool is 50m long. 
										The table below show the time that it took four swimmers to complete each length.
									</b>
									</div>
							</div>
							<img style='margin-top:3px;width:15cm ;height:70mm;'  src='img/FriendsPlus/Page66/E1/3.jpg'>
              <img style='margin:3px 0px 0px 10px;width:10cm ;height:70mm;' src='img/FriendsPlus/Page66/E1/1.jpg'>
          <div style='display:flex; line-height: 45px'>
            <div>
              <b>1.</b> Who had the fastest length?<br>#<br>
              <b>2.</b> Who had th slowest length?<br>#
            </div>
            <div style='margin-left: 60px'>
              <b>3.</b> Who finished first? <br>#<br>
              <b>4.</b> Who finished last?<br>#
            </div>
          </div>


          `,
        answer: [
          "Gomez (36 seconds)",
          "Jones (42 seconds)",
          "O'Hara",
          "Fields",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB7-U5-P66-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 80,
    textAlign: "center",
    maxLength: 4,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page66/E2/Key/answerKey.png",
    hintBox: [
      {
        src: ["distance", "time", "average speed", "calculate", "equation"],
        width: 500,
        borderColor: "#db2f3a",
        border: "4px solid #db2f3a",
      },
    ],

    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Check the meaning of the work in the box. Then read the equations and answer questions 1-2.",
        color: "#db2f3a",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='margin:10px 150px'><hintBox id='0'></hintBox></div>
        <div style='display:flex'>
          <img style='margin-right:5px' src='img/FriendsPlus/Page66/E2/1.jpg'>
          <div>
            <div style='display:flex;width:13cm'>
              <b>1.</b>
              <div style='margin-left:20px'>
                Look again at the table in exercise 1.
                Calculate the average speed of Gomez,
                Jones and O’Hara in m/s.
              </div>
            </div>
            &emsp;<b>Gomez</b> =#m/s
            &emsp;<b>Jones</b> =#m/s
            &emsp;<b>O’Hara</b> =#m/s
            <div style='display:flex;width:13cm; margin-top: 50px;'>
              <b>2.</b>
              <div style='margin-left:20px'>
                Now calculate the average speed of all the
                swimmers in km/h.
              </div>
            </div>
            &emsp;<b>Gomez</b> =#km/h      
            &emsp;<b>Jones</b> =#km/h      
            <b>O’Hara</b> =#km/h      
            &emsp;<b>Fields</b> =#km/h      
          </div>
        </div>

				

          `,
        answer: ["1.31", "1.25", "1.32", "4.71", "4.5", "4.75", "4.46"],
      },
    ],
  },

  3: {
    unit: "Unit 5",
    id: "SB7-U5-P66-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 100,
    textAlign: "center",
    maxLength: 5,
    exerciseKey: "img/FriendsPlus/Page66/E3/Key/answerKey.png",
    // hintBox: [
    //   {
    //     src: ["distance", "time", "average speed", "calculate", "equation"],
    //     width: 500,
    //     borderColor: "#db2f3a",
    //     border: "4px solid #db2f3a",
    //   },
    // ],

    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title: "Read the sentences and find each average speed in km/h.",
        color: "#db2f3a",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex;width:15cm'>
            <b>1</b>
            <div style='margin-left:20px'>
                In 2014 Dennis Kimetto completed the
                Berlin Marathon in 2 hours, 2 minutes and
                57 seconds (2.05 hours). The marathon was
                42.19 kilometres:<br>#km/h
            </div>
          </div>
          <div style='display:flex;width:15cm; margin: 20px 0px'>
            <b>2</b>
            <div style='margin-left:20px'>
                In 2014 Dame Sarah Storey completed the
                3 kilometre para-cycling track race in 3 hours,
                32 minutes and 5 seconds (3.54 hours):<br>#km/h
            </div>
          </div>
          <div style='display:flex;width:15cm; margin: 20px 0px'>
            <b>3</b>
            <div style='margin-left:20px'>
                In 2015, Lewis Hamilton drove one lap (5.3
                kilometres) in the Australian Grand Prix in
                1 minute, 30 seconds:<br>#km/h
            </div>
          </div>
          <div style='display:flex;width:15cm'>
            <b>4</b>
            <div style='margin-left:20px'>
                In 2014 Sarah Sjöström swam the 50m
                butterfly in 24.43 seconds:<br>#km/h
            </div>
          </div>

				

          `,
        answer: ["20.59", "0.85", "37.58", "7.37"],
      },
    ],
  },
};

export default json;
