import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: 'Unit 3',
    id: 'SB7-U3-P37-E1',
    component: T6,
    inputSize: '110px',
    exerciseKey: 'img/FriendsPlus/Page37/E1/Key/answerKey.png',
    checkUppercase: true,
    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the sentences with <i>was, were, wasn't</i> and <i>weren't</i>. Then check your <br/>answers in the text on page 36.",
        color: "#16bed5",
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div><b>1.</b> Last week I # with my friends in the History Museum of Hồ Chí Minh City.</div>
          <div><b>2.</b> The clothes # comfortable at all.</div>
          <div><b>3.</b> The school trip to the National History Museum # boring.</div>
          <div><b>4.</b> The exhibits # really interesting.</div>
          <div><b>5.</b># Bluebird strange?</div>
          <div>&nbsp&nbsp&nbsp Yes, it # very big and long.</div>
        </div>`,
        answer: ["was", "weren't", "wasn't", 'were', 'Was', 'was']
      }
    ]
  },
  2: {
    unit: 'Unit 3',
    id: 'SB7-U3-P37-E2',
    component: T6,
    // titleImage: 'img/FriendsPlus/Page37/E2/1.jpg',
    inputSize: '110px',
    exerciseKey: 'img/FriendsPlus/Page37/E2/Key/answerKey.png',
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title:
          " Look at the sentences in exercise 1. Then complete the Rules using the correct form of was and were.",
        color: "#16bed5",
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div><img style="width: 74%" src='img/FriendsPlus/Page37/E2/1.jpg'/></div>
          <div><b>1.</b>In affirmative sentences, we use was or #.</div>
          <div><b>2.</b>In negative sentences, we use # or weren't.</div>
          <div><b>3.</b>In yes / no questions, we use #/ were + subject + phrase.</div>
          <div><b>4.</b>In short answers, we use yes / no + pronoun + # / wasn't or were / weren't.</div>
        </div>`,
        answer: ["were ", "wasn't", "was", "was"]
      }
    ]
  },
  3: {
    unit: 'Unit 3',
    id: 'SB7-U3-P37-E3',
    component: T6,
    inputSize: '110px',
    exerciseKey: 'img/FriendsPlus/Page37/E3/Key/answerKey.png',
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the sentences with was, were, wasn’t and weren’t.",
        color: "#16bed5",
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div>Mai and her friends <b><u>were</u></b> in the History Museum of Hồ Chí Minh City last week. ✓</div>
          <div><b>1.</b>The clothing exhibition # boring. ✗</div>
          <div><b>2.</b>Bluebird # a motorbike. ✗</div>
          <div><b>3.</b>The Nguyễn Dynasty # from 1802 to 1945. ✓</div>
          <div><b>4.</b># the Natural History Museum interesting? Yes, it #. ✓</div>
          <div><b>5.</b># the dinosaurs unpopular? </div>
          <div>&nbsp&nbsp&nbspNo, they #. ✗</div>
        </div>`,
        answer: [" wasn't ", "wasn't", "was", "Was", "was", "Were", "weren't"]
      }
    ]
  },
  4: {
    unit: 'Unit 3',
    id: 'SB7-U3-P37-E4',
    component: T6,
    inputSize: '900px',
    exerciseKey: 'img/FriendsPlus/Page37/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: "4",
        title:
          " Write questions and short answers for the sentences in exercise 3.",
        color: "#16bed5",
      },
    ],
    questions: [
      {
        title: `
        <div>
          <b style="color:grey; font-style: italic">Were Mai and her friends in the History Museum of Hô Chí Minh City last week? <br/>Yes, they were.</b>
          <div><b>1.</b> The clothing exhibition......... boring. ✗</div>
          <div>#</div>
          <div>#</div>
           <div><b>2.</b> Bluebird......... a motorbike. ✗</div>
          <div>#</div>
          <div>#</div>
          <div><b>3.</b> The Nguyễn Dynasty......... from 1802 to 1945. ✓</div>
          <div>#</div>
          <div>#</div>
          <div><b>4.</b> .........the Natural History Museum interesting? Yes, it.......... ✓</div>
          <div>#</div>
          <div>#</div>
          <div><b>5.</b> .........the dinosaurs unpopular? </div>
          <div>#</div>
          <div>#</div>
        </div>`,
        answer: [
          `Was the clothes exhibition boring?`, "No, it wasn't.",
          `Was Bluebird a motorbike?`, `No, it wasn't.`,
          `Were the clothes expensive?`, `Yes, they were.`,
          `Was the Natural History Museum interesting?`, `Yes, it was.`,
          `Were Mai and her friends in the History Museum of Hồ Chí Minh City last year?`, `No, they weren't.`,
        ]
      }
    ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB7-U3-P37-E5',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page37/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: "5",
        title:
          "  Choose the correct words. ",
        color: "#2ec3d8",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: '0 5px', fontWeight: 600, color: '#20c1d8', fontSize: 24 },
        selectWordStyle: { border: '2px solid #20c1d8' },
        limitSelect: 1,
        listWords: [
          "was / were",
          "was / were",
          "wasn't / weren't",
          "wasn't / weren't",
        ],
        answers: ['0-0', '1-4', '2-0', '3-4'],
        initialValue: [],
      },
      Layout: `
        <div><b>1.</b> There <input id='0' type='Circle' />a car from 1964.</div>
        <div><b>2.</b> There <input id='1' type='Circle' />lots and lots of cars.</div>
        <div><b>3.</b> There <input id='2' type='Circle' />a good café.</div>
        <div><b>4.</b> There <input id='3' type='Circle' />any exciting animals.</div>
      `,
    },
  },
  6: {
    unit: 'Unit 3',
    id: 'SB7-U3-P37-E6',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 600 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style="width: 90%" src='img/FriendsPlus/Page37/E6/1.jpg' />
          <div><textarea id="0" rows="6" ></textarea></div>
        `,
        answer: []
      },
    ]
  },
  7: {
    unit: 'Unit 3',
    id: 'SB7-U3-P37-E7',
    component: T6,
    inputSize: '160px',
    exerciseKey: 'img/FriendsPlus/Page37/E7/Key/answerKey.png',
    checkUppercase: true,
    titleQuestion: [
      {
        num: "7",
        title:
          " Write questions with was and were and the words in the box. <br/>Then ask and answer the questions with your partner.",
        color: "#20c1d8",
        prefix: "default"
      },
    ],
    hintBox: [
      {
        src: [
          "When ",
          "Where ",
          "Who ",
          "What",
        ],
        borderColor: "#20c1d8",
        width: 500,
      },
      {
        src: ["Where were you at 8 o’clock this morning ? "],
        borderColor: "#20c1d8",
        arrow: true,
        position: 1,
        width: 350,
      },
      {
        src: ["I was on the bus."],
        borderColor: "#20c1d8",
        arrow: true,
        position: 2,
        width: 250,
      },
    ],
    questions: [
      {
        title: `
        <div>
          <hintBox id='0'></hintBox>
          <div><b>1.</b># you at 8 o'clock this morning?</div>
          <div><b>2.</b># your first teacher?</div>
          <div><b>3.</b># Lê Quý Đôn?</div>
          <div><b>4.</b># your last English test?</div>
          <div><b>5.</b># your first visit to a museum?</div>
          <div><b>6.</b># the last SEA Games?</div>
          <div style='display: flex; justify-content: space-between; width: 600px; margin-top: 40px'>
            <div><hintbox id='1'></hintbox></div>
            <div style=" margin: 70px 0 0 40px"><hintbox id='2'></hintbox></div>
          </div>
        </div>`,
        answer: ['Where were', 'Who was', 'Who was', 'When was', `When/ Where was`, 'When was']
      }
    ]
  },
  8: {
    unit: 'Unit 3',
    id: 'SB7-U3-P37-E8',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000 },
    hideBtnFooter: true,
    finished: {
      text: "Write quiz questions about the museum on the website you know."
    },
    questions: [
      {
        title: `
          <finished ></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: []
      },
    ]
  },

}

export default json;