import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import TB2 from "../../components/ExcerciseTypes/Table/TB2";
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB7-U4-P48-E1",
    audio: "Audios/1-42 Friends Plus 7.mp3",
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page48/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title:
          "<audioimage name='1.42'></audioimage>Read the texts and choose the correct words.Listen and check.",
        color: 'rgb(245, 128, 36)',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '49%',
          borderColor: 'transparent',
          color: '#000000',
          fontWeight: 600,
          fontSize: 16,
        },
        selectWordStyle: { border: "2px solid", },
        limitSelect: 1,
        listWords: [
          "easy / brave",
          "slow / fast",
          "happy / patient",
          "comfortable / careful",
          "badly / quietly",
          "polite / rude",
          "happily / rudely",
          "politely / well",
          "slowly / patiently",
          "carefully / bravely ",
          "creative / slow ",
          "bad / slow",
          "good / quiet",
          "creatively / comfortably",
        ],
        answers: ['1-4', '3-0', '4-4', '5-4', '0-0', '6-0', '7-0', '8-0', '9-0', '10-0', '11-0', '12-0', '13-0', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div style="position: relative;">
          <img  src='img/FriendsPlus/Page48/E1/1.jpg'/>
          <div style=" position: absolute; top: 290px; left: 120px; width: 380px; font-size: 17px ">
            It isn’t <b><sup>1 </sup><input id='0'  type='Circle' /> </b> to photograph wild animals 
            because they are <b><sup>2 </sup><input id='1'  type='Circle' /> </b>. You need to be 
            very <b><sup>3 </sup><input id='2'  type='Circle' /> </b>. Find a <b><sup>4 </sup><input id='3'  type='Circle' /> </b> place and sit <b><sup>5 </sup><input id='4'  type='Circle' /> </b>.
          </div>
          <div style=" position: absolute; top: 78px; left: 413px; width: 220px; font-size: 17px ">
            Remember, it’s 
            <b><sup>6 </sup><input id='5'  type='Circle' /> </b> to take 
            photos of people you don’t 
            know. Most people will 
            <b><sup>7 </sup><input id='6'  type='Circle' /> </b> say yes 
            if you ask <b><sup>8 </sup><input id='7'  type='Circle' /> </b>
            for permission.
          </div>
          <div style=" position: absolute; top: 423px; left: 375px; width: 190px; font-size: 16px ">
            When people are 
            doing sports, they 
            don’t move <b><sup>9 </sup><input id='8'  type='Circle' /> </b>
            , so choose <b><sup>10</sup><input id='9'  type='Circle' /> </b>
            where you stand. For 
            dangerous sports, the 
            photographer needs to 
            be <b><sup>11</sup><input id='10'  type='Circle' /> </b> and 
            good at the sport, too.
          </div>
          <div style=" position: absolute; top: 324px; left: 607px; width: 300px; font-size: 17px ">
            Holiday photos are often <b><sup>12</sup><input id='11'  type='Circle' /> </b>, 
            so if you want to take a <b><sup>13</sup><input id='12'  type='Circle' /> </b>
            photo, think <b><sup>14</sup><input id='13'  type='Circle' /> </b>
            and choose unusual angles
          </div>
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "SB7-U4-P48-E2",
    audio: "",
    video: "",
    component: TB2,
    exerciseKey: "img/FriendsPlus/Page48/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the table with the adjectives and adverbs in exercise 1.",
        color: "#f58024",
      },
    ],
    questions: [
      {
        title: `
            <div><img src='img/FriendsPlus/Page48/E2/1.jpg' style="width:74%  "/></div>
        `,
      },
    ],
    tb2Style: { width: 700 },
    type: 2,
    data: [
      {
        title: "Adjectives",
        content: ["bad", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#"],
        answers: ["", "easy", "brave", "slow", "fast", "happy", "patient", "comfortable", "careful", "polite", "rude", "creative", "bad", "good", "quiet"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#f9a360",
          border: "1px solid #f58024",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#fedbbe",
          border: "1px solid #f58024",
          color: "#58585a",
        },
      },
      {
        title: "Adverbs",
        content: ["badly", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#"],
        answers: ["", "quietly", "happily", "rudely", "politely", "well", "slowly", "patiently", "carefully", "bravely", "creatively", "comfortably"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#f9a360",
          border: "1px solid #f58024",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#fedbbe",
          border: "1px solid #f58024",
          color: "#58585a",
        },
      },
    ],
  },

  3: {
    unit: "Unit 4",
    id: "SB7-U4-P48-E3",
    audio: "Audios/1-43 Friends Plus 7.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page48/E3/Key/answerKey.png",
    inputSize: 50,
    stylesTextInput: { textTransform: 'uppercase', textAlign: "center" },
    maxLength: 1,
    titleQuestion: [
      {
        num: "3",
        title:
          "<audioimage name='1.43'></audioimage>Listen to a photographer explaining how he took these photos.Put photos A–D in the order you hear.",
        color: "#f58024",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="display: flex; gap: 30px; margin: 30px">
          <img src='img/FriendsPlus/Page48/E3/1.jpg' style="width:50%  "/>
          <img src='img/FriendsPlus/Page48/E3/2.jpg' style="width:50%  "/>
        </div>
        <div style="display: flex; gap: 30px; margin: 30px">
          <img src='img/FriendsPlus/Page48/E3/3.jpg' style="width:50%  "/>
          <img src='img/FriendsPlus/Page48/E3/4.jpg' style="width:50%  "/>
        </div>
        <b style="margin-left: 40px">1</b> # &emsp;<b>2</b> # &emsp;<b>3</b> # &emsp;<b>4</b> # &emsp;
        `,
        answer: ["A", "C", "B", "D"],
      },
    ],
  },

  4: {
    unit: "Unit 4",
    id: "SB7-U4-P48-E4",
    audio: "Audios/1-43 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 580,
    maxLength: 100,
    exerciseKey: "img/FriendsPlus/Page48/E4/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='1.43'></audioimage>Listen again and answer the questions.",
        color: "#f58024",
      },
    ],
    questions: [
      {
        title: `
          <div><b>1 &emsp;</b> What was the squirrel doing in photo A? <br> &emsp;&emsp; # </div>
          <div><b>2 &emsp;</b> In photo B, why was the photographer in Asia?<br> &emsp;&emsp; #</div>
          <div><b>3 &emsp;</b> In photo C, was the photographer skiing?<br> &emsp;&emsp; # </div>
          <div><b>4 &emsp;</b> Where was the photographer in photo C?<br> &emsp;&emsp; # </div>
          <div><b>5 &emsp;</b> In photo D, was the photographer standing in the boat?<br> &emsp;&emsp; # </div>
        `,
        answer: [
          "It was running up and down the tree and eating nuts.",
          "He was travelling.",
          "Yes, he was.",
          "He was on the person's left.",
          "No, he was sitting in the boat.",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 4",
    id: 'SB7-U4-P48-E5',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1080 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Work in pairs. Say how you can do the activities in the box.Use adverbs from exercise 2",
        color: "#f58024",
        prefix: "default"
      },
    ],
    hintBox: [
      {
        src: [
          "learn vocabulary",
          "read",
          "sing ",
          "speak to your teacher",
          "swim",
        ],
        borderColor: "#f58024",
        width: 520,
      },
      {
        src: [
          "I can swim fast."
        ],
        borderColor: "#f58024",
        arrow: true,
        position: 1,
        width: 220,
      },
    ],
    questions: [
      {
        title: `
            <div style="text-align: center"><hintBox id='0'></hintBox></div>
            <div style="text-align: center; margin-block: 40px"><hintBox id='1'></hintBox></div>
			  		<div style="margin-top:30px"><textarea id="0" rows="7" ></textarea></div>
			`,
        answer: []
      },
    ]
  },
};

export default json;
