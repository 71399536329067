import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  8: {
    unit: "Unit 4",
    id: "SB7-U4-P56-E8",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", marginInline: -1, textAlign: 'center' },
    inputSize: 40,
    exerciseKey: "img/FriendsPlus/Page56/E8/Key/answerKey.png",
    checkUppercase: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "8",
        title: "Complete the words.",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          
              <div >
                <div style='margin-top:5mm'><b>1.</b> In the foreground of the photo, my dad is <b>s</b>#####<b>g</b> &emsp;<b>o</b># his mountain bike.</div>
                <div style='margin-top:5mm'><b>2.</b> My friend Nga is <b>p</b>###### &emsp;<b>u</b># a beach ball in this holiday picture.</div>
                <div style='margin-top:5mm'><b>3.</b> In my favourite funny video, a little boy is <b>j</b>#####<b>g</b> &emsp;<b>u</b># with his baby sister.</div>
                <div style='margin-top:5mm'><b>4.</b> Is Denis <b>k</b>#####<b>g</b> a ball in that old picture?</div>
                <div style='margin-top:5mm'><b>5.</b> In this photo, a man is <b>h</b>#####<b>g</b> &emsp;<b>f</b>### a tree.</div>
                <div style='margin-top:5mm'><b>6.</b> On the front of this postcard, some people are <b>w</b>#####<b>g</b> &emsp;<b>o</b># the beach.</div>
                <div style='margin-top:5mm'><b>7.</b> My aunt and uncle are <b>c</b>######<b>g</b> &emsp;<b>u</b># a mountain in their holiday video.</div>
                <div style='margin-top:5mm'><b>8.</b> What are you <b>h</b>#####<b>g</b> in your left hand in that photo?</div>
              </div>
          `,
        answer: [
          "i", "t", "t", "i", "n", "n",
          "i", "c", "k", "i", "n", "g", "p",
          "u", "m", "p", "i", "n", "p",
          "i", "c", "k", "i", "n",
          "a", "n", "g", "i", "n", "r", "o", "m",
          "a", "l", "k", "i", "n", "n",
          "l", "i", "m", "b", "i", "n", "p",
          "o", "l", "d", "i", "n",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 4",
    id: "SB7-U4-P56-E9",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 200,
    exerciseKey: "img/FriendsPlus/Page56/E9/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "9",
        title: "Fill in the blank with one word in the box.",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='width:80%; margin: 10px 0 20px 0; border-radius: 30px; border: 2px solid  rgb(77 60 151)'>
              <div style='text-align:center'> success&emsp;&emsp;Although&emsp;&emsp;ice-cream sticks <br> start-up&emsp;&emsp;decorations</div>
          </div>  
              <div style='margin-top :5mm'><b>1&emsp;</b>The girl made toys from # .</div>
              <div style='margin-top :5mm'><b>2&emsp;</b>The toys were simple, but it was a  # for such a little girl</div>
              <div style='margin-top :5mm'><b>3&emsp;</b>Some people made #  from old car tires and bottles.</div>
              <div style='margin-top :5mm'><b>4&emsp;</b># their business was different, it was meaningful.</div>
              <div style='margin-top :5mm'><b>5&emsp;</b>Their  # seemed to be a success story.</div>
          `,
        answer: [
          "ice-cream sticks",
          "success",
          "decorations",
          "Although",
          "start-up",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 4",
    id: "SB7-U4-P56-E10",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 700,
    exerciseKey: "img/FriendsPlus/Page56/E10/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "10",
        title:
          "Write sentences using the affirmative (✔), negative (✘) or question (?) form of past continuous",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
              <div style='margin-top :5mm'><b>1.</b> we / play volleyball / at 10:30 ✔ <br>#</div>
              <div style='margin-top :5mm'><b>2.</b> it / rain / yesterday morning ✘<br>#</div>
              <div style='margin-top :5mm'><b>3.</b> you / listen / in class / today ✘<br>#.</div>
              <div style='margin-top :5mm'><b>4.</b> the monkeys / hang from a tree ✔<br> #.</div>
              <div style='margin-top :5mm'><b>5.</b> I / watch / that film / earlier ✘<br> #.</div>
              <div style='margin-top :5mm'><b>6.</b> Thanh / write / a letter / at 5:00 ✔ <br>#.</div>
          `,
        answer: [
          "We were playing volleyball at 10.30. ",
          "It wasn't raining yesterday morning.",
          "You weren't listening in class today.",
          "The monkeys were hanging from a tree.",
          "I wasn't watching that film earlier.",
          "Thanh was writing a letter at 5.00.",

        ],
      },
    ],
  },
  11: {
    unit: "Unit 4",
    id: "SB7-U4-P56-E11",
    audio: "Audios/1-48 Friends Plus 7.mp3",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 110,
    exerciseKey: "img/FriendsPlus/Page56/E11/Key/answerKey.png",
    checkUppercase: true,
    textAlign:"center",
    titleQuestion: [
      {
        num: "11",
        title:
          "<audioimage name='1.48'></audioimage> Listen to the photography teacher speaking to the students. <br/>Complete the sentences with adjectives or adverbs.",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
              <div style='margin-top :5mm'><b>1.</b> So don’t forget, you need to hold the camera #at all times</div>
              <div style='margin-top :5mm'><b>2.</b> Always move #with the camera</div>
              <div style='margin-top :5mm'><b>3.</b> When you take photos of people, you need to be very #.</div>
              <div style='margin-top :5mm'><b>4.</b> It’s important to speak to people #and wait #until they’re ready.</div>
              <div style='margin-top :5mm'><b>5.</b> Remember to be# when you take photos of groups of people.</div>
              <div style='margin-top :5mm'><b>6.</b>The people weren’t smiling #and they weren’t standing in one group</div>
          `,
        answer: [

          "carefully",
          "slowly",
          "patient",
          "politely", "quietly",
          "creative",
          "happily",
        ],
      },
    ],
  },
};

export default json;
