import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 1',
    id: 'SB7-U1-P15-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page15/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title:
          'Complete the questions and answers with do, don’t, does and doesn’t. Then choose the correct words in rules 1–4.',
        color: '#1dbfd7',
      },
    ],
    // hintBox: [
    //   {
    //     src: ["Do her friends like the trainers?"],
    //     width: 250,
    //     borderColor: "#1dbfd7",
    //     arrow: true,
    //     position: 1,
    //     textAlign: "center",
    //   },
    //   {
    //     src: ["Do her friends like the trainers?"],
    //     width: 250,
    //     borderColor: "#1dbfd7",
    //     arrow: true,
    //     position: 2,
    //   },
    // ],
    question: {
      Write: {
        inputStyle: {
          width: 110,
          // borderBottom: "dotted 1px",
          color: '#000',
          textAlign: 'center',
        },
        answers: ['do', "don't", 'Does', "doesn't", 'does'],
        initialValue: [],

        textAlign: 'center',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: [
          'is / do', //1
          'do / does', //2
          'am / do',
          '’m_not / don’t', //3
          'is / does',
          'isn’t / doesn’t',
        ],
        answers: ['0-4', '1-4', '2-4', '3-4', '4-4', '5-4'],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex'>
            <div style=''>
                <div style='display:flex'>
                    <div>
                      <div style='border: 3px solid rgb(42 196 198);padding:10px 20px;width:8cm;border-radius: 20px'>
                        Do her friends like the trainers?<br>
                      </div>
                       <img style='margin:-22px 0px 0px 30px;width:79px' src='img/FriendsPlus/Page15/E1/1.jpg'>
                    </div>
                    <div style='margin-left:20px'>
                      <div style='border: 3px solid rgb(151 216 231);padding:10px 20px;width:8cm;border-radius: 20px'>
                        Yes, they <sup>1</sup><input id='0'></input>. <br>
                        No, they <sup>2</sup><input id='1'></input>.
                      </div>
                       <img style='margin:-17px 0px 0px 30px;width:81px' src='img/FriendsPlus/Page15/E1/2.jpg'>
                    </div>
                </div>
                <div style='display:flex'>
                    <div>
                      <div style='border: 3px solid rgb(42 196 198);padding:10px 20px;width:8cm;border-radius: 20px'>
                        <sup>3</sup><input id='2'></input>Niall write
                          stories?<br>
                      </div>
                       <img style='margin:-22px 0px 0px 30px;width:79px' src='img/FriendsPlus/Page15/E1/1.jpg'>
                    </div>
                    <div style='margin-left:20px'>
                      <div style='border: 3px solid rgb(151 216 231);padding:10px 20px;width:8cm;border-radius: 20px'>
                        No, he <sup>4</sup><input id='3'></input>. <br>
                        Yes, he <sup>5</sup><input id='4'></input>. 
                      </div>
                       <img style='margin:-15px 0px 0px 30px;width:79px' src='img/FriendsPlus/Page15/E1/2.jpg'>
                    </div>
                </div>
            </div>
            <div style='margin-left:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 15cm'>
              <div style='font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>RULES</div>
              <div style='display:flex'>
                <b>1</b>
                <div style='margin-left:20px'>We use <input id='0' type='Circle'/> to make questions with
                ordinary verbs with I / you / we / they.</div>
              </div>
              <div style='display:flex'>
                <b>2</b>
                <div style='margin-left:20px'>We use <input id='1' type='Circle'/> to make questions with
                ordinary verbs with he / she / it. </div>
              </div>
              <div style='display:flex'>
                <b>3</b>
                <div style='margin-left:20px'>In short answers using ordinary verbs, we say
                Yes, I <input id='2' type='Circle'/> and No, I <input id='3' type='Circle'/></div>
              </div>
              <div style='display:flex'>
                <b>4</b>
                <div style='margin-left:20px'>In short answers using ordinary verbs, we say
                Yes, he <input id='4' type='Circle'/> and No, she <input id='5' type='Circle'/>.</div>
              </div>
            </div>
        </div>
        
      `,
    },
  },
  2: {
    unit: 'Unit 1',
    id: 'SB7-U1-P15-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page15/E2/Key/answerKey.png',
    inputSize: 100,
    inputStyle: { textDecorationStyle: 'none' },
    maxLength: 7,
    textAlign: 'center',
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the questions using the present simple form of the verbs in brackets.',
        color: '#1ec0d9',
      },
    ],
    hintBox: [
      {
        src: ["doesn't", "<s>don't<s>", "don't", 'studies', 'want', 'watches'],
        borderColor: '#1bb7d0',
        width: 350,
      },
    ],
    questions: [
      {
        title: `
          <div>
              <div>Do you <i style='color: gray;text-decoration: underline'>&emsp;blog&emsp;</i> about your life?(blog) </div>
              <div><b>1.</b> #you and your friends#videos?(make)</div>
              <div><b>2.</b> #you#your friends after school?(meet)</div>
              <div><b>3.</b> #your best friend# near you?(live)</div>
              <div><b>4.</b> #you#things?(collect)</div>
              <div><b>5.</b> #your dad#in bed late?(stay)</div>
              <div><b>6.</b> #people in your class#a lot?(talk)</div>
          </div>
        `,
        answer: ['Do', 'make', 'Do', 'meet', 'Does', 'live', 'Do', 'collect', 'Does', 'stay', 'Do', 'talk'],
      },
    ],
  },

  3: {
    unit: 'Unit 1',
    id: 'SB7-U1-P15-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // fontSize: 28,
    maxLength: 7,
    titleQuestion: [
      {
        num: '3',
        title: 'Ask and answer the questions from exercise 2. Use do, don’t, does and doesn’tin your answers.',
        color: '#1ec0d9',
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page15/E3/1.jpg' }]],
  },
  4: {
    unit: 'Unit 1',
    id: 'SB7-U1-P15-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page15/E4/Key/answerKey.png',
    stylesTextInput: { padding: 0, borderBottom: 'dotted 1px' },
    inputSize: 120,
    maxLength: 9,
    textAlign: 'center',
    // hideBtnFooter: true,
    checkUppercase: true,
    questionImage: [],
    titleQuestion: [
      {
        num: '4',
        title: 'Complete the questions with the words in the box. Then practise in pairs.',
        color: '#1ec0d9',
      },
    ],
    hintBox: [
      {
        src: ['<s>Do you ever</s>', 'How often', 'What', 'When', 'Where', 'Who', 'Why'],
        borderColor: '#1bb7d0',
        width: 550,
      },
    ],
    questions: [
      {
        title: `
          <hintBox id='0'></hintBox>
          
          <div style='line-height: 45px'>
          <div><b>Do you ever</b> go to the cinema?</div>
          <div>Yes, I do. I love watching films.</div>
            <b>1.</b>#do you go?
            <div style='margin-left:20px;background: rgb(207 217 183);padding-left: 10px;border-radius: 10px'>Not often-about once a month.</div>
            <b>2.</b>#do you go with?
            <div style='margin-left:20px;background: rgb(207 217 183);padding-left: 10px;border-radius: 10px'>My best friend, and sometimes my sister.</div>
            <b>3.</b>#do you go?
            <div style='margin-left:20px;background: rgb(207 217 183);padding-left: 10px;border-radius: 10px'>To the cinema in town.</div>
            <b>4.</b>#do you go?
            <div style='margin-left:20px;background: rgb(207 217 183);padding-left: 10px;border-radius: 10px'>Usually on saturday afternoons.</div>
            <b>5.</b>#do you watch?
            <div style='margin-left:20px;background: rgb(207 217 183);padding-left: 10px;border-radius: 10px'>Scary films.</div>
            <b>6.</b>#do you watch scary films?
            <div style='margin-left:20px;background: rgb(207 217 183);padding-left: 10px;border-radius: 10px'>Because my friend loves them.</div>
          </div>
        `,
        answer: ['How often', 'Who', 'Where', 'When', 'What', 'Why'],
      },
    ],
  },
  5: {
    unit: 'Unit 1',
    id: 'SB7-U1-P15-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // fontSize: 28,
    maxLength: 7,
    titleQuestion: [
      {
        num: '5',
        title:
          'Work in pairs. Ask questions using words from boxes A and B. Find out different things about your partner.',
        color: '#1ec0d9',
        prefix: 'default',
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page15/E5/1.jpg' }]],
  },
  6: {
    unit: 'Unit 1',
    id: 'SB7-U1-P15-E6',
    audio: '',
    video: '',
    component: T6,
    inputSize: 240,
    textareaStyle: { width: 600 },
    checkUppercase: true,
    recorder: true,
    // hideBtnFooter: true,
    // titleQuestion: [
    //   {
    //     num: "6",
    //     title:
    //       "Write true and false sentences using the words in the boxes. Use affirmative and negative forms. Compare with your partner.",
    //     color: "#1dbfd7",
    //     prefix: "default",
    //     // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
    //   },
    // ],
    questions: [
      {
        title: `
          
         <div><img src='img/FriendsPlus/Page15/E6/1.jpg'></div>
          <textarea id="0" rows="4"></textarea>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
