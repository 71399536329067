import _ from "lodash";
import moment from "moment";
import "moment/locale/vi";
import React, { useCallback, useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import FooterModal from "../../../FooterModal";
import TitleQuestion from "../../../TitleQuestion";
import CaseInput from "./components/CaseInput";
import CaseTextArea from "./components/CaseTextArea";
import StarModal from "./components/StarModal";
import useCaseInput from "./hooks/useCaseInput";
import useCaseTextArea from "./hooks/useCaseTextArea";
import HintBox from "../../../Utilities/HintBox";
import Finished from "../../../Utilities/Finished";
import { Form, Select } from "antd";
moment.locale("vi");

const TB1 = ({
  data,
  exerciseKey,
  hideBtnFooter,
  id,
  innerStyle,
  outterStyle,
  inputStyle,
  postAnswerToApi,
  titleQuestion,
  unit,
}) => {
  const [state, setState] = useState({
    star: 0,
    audioUrl: null,
    videoUrl: null,
    sentences: null,
    isPointed: false,
    studentAnswer: null,
    videoVisible: false,
    isDisabledRetry: true,
    isDisabledSubmit: true,
    booleanArray: [],
  });

  const { handleChange, handleColor, setResultText, text } = useCaseInput();
  const {
    handleTextAreaChange,
    resultTextArea,
    setResultTextArea,
    setTextArea,
    textArea,
  } = useCaseTextArea();

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (textArea.length !== 0 || text.length !== 0) {
      setState((prev) => ({ ...prev, isDisabledSubmit: false }));
    } else setState((prev) => ({ ...prev, isDisabledSubmit: true }));
  }, [text, textArea]);

  const onModal = useCallback(() => {
    setModal(true);

    setTimeout(() => {
      setModal(false);
    }, 3000);
  }, []);

  const onSubmit = useCallback(() => {
    const resultTextArea = textArea.map((x) => {
      const items = x.value.split(/\r\n|\r|\n/g);
      return items.map((k) => ({
        index: x.index,
        value: k,
        isCorrect: data[x.index].answers.includes(k),
      }));
    });
    setResultTextArea(resultTextArea);

    const resultText = text.map((x, i) => {
      return x.map((k) => ({
        ...k,
        isCorrect: data[i].answers[k.index] === k.value,
      }));
    });
    setResultText(resultText);

    const totalAnswers = _.flatten(data.map((x) => x.answers)); // Tổng số đáp án trong máy.

    // Lọc tất cả đáp án type textarea gom vào 1 mảng.
    const allAnswers = _.groupBy(data, "type").textarea
      ? _.flatten(_.groupBy(data, "type").textarea.map((x) => x.answers))
      : [];
    const correctUserAnswers = _.flatten(resultTextArea).map(
      (x) => x.isCorrect
    ); // Đáp án đúng|sai của người dùng, type textarea.
    const booleanUserAnswers =
      resultTextArea.length !== 0 ? correctUserAnswers : allAnswers.fill(false); // Nếu không nhập thì sai hết.
    const booleanTextAreaArray =
      _.groupBy(data, "type").textarea === undefined ? [] : booleanUserAnswers;
    const correctTextAreaAnswer = _.flatten(resultTextArea).filter(
      (x) => x.isCorrect
    ); // Tổng số đáp án đúng trong tổng đáp án người dùng nhập.
    // =============================================================================
    const allTextAnswers = _.groupBy(data, "type").input
      ? _.flatten(_.groupBy(data, "type").input.map((x) => x.answers))
      : [];
    const correctUserTextAnswers = _.flatten(resultText).map(
      (x) => x.isCorrect
    ); // Tổng số đáp án người dùng.
    const booleanUserTextAnswers =
      resultText.length !== 0
        ? correctUserTextAnswers
        : allTextAnswers.fill(false); // Nếu không nhập thì sai hết.
    const booleanTextArray =
      _.groupBy(data, "type").input === undefined ? [] : booleanUserTextAnswers;
    const correctTextAnswer = _.flatten(resultText).filter((x) => x.isCorrect); // Tổng số đáp án đúng trong tổng đáp án người dùng nhập.

    const percent = parseInt(
      ((correctTextAreaAnswer.length + correctTextAnswer.length) /
        totalAnswers.length) *
      100
    );
    const resultString = `${correctTextAreaAnswer.length + correctTextAnswer.length
      }/${totalAnswers.length}`;
    const star = percent / 20;

    const params = {
      score: percent,
      unit: unit,
      results: resultString,
      exerciseId: id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify({
        text: resultText,
        textarea: resultTextArea,
      }),
    };
    postAnswerToApi(params);

    setState((preState) => ({
      ...preState,
      isDisabledInput: true,
      isDisabledRetry: false,
      isPointed: true,
      resultString: null,
      isDisabledSubmit: true,
      booleanArray: _.concat(booleanTextAreaArray, booleanTextArray),
      inputArray: new Array(state.maxInput).fill(),
      star,
    }));

    onModal();
  }, [onModal]);

  const onRetry = useCallback(() => {
    setState((preState) => ({
      ...preState,
      isDisabledInput: false,
      isDisabledRetry: true,
      isPointed: false,
      resultString: null,
      isDisabledSubmit: false,
      booleanArray: [],
      inputArray: new Array(state.maxInput).fill(),
    }));
    setTextArea([]);
    setResultTextArea([]);
  }, []);

  const renderContent = (type, content, index) => {
    switch (type) {
      case "input":
        return (
          <CaseInput
            content={content}
            dataIndex={index}
            handleChange={handleChange}
            handleColor={handleColor}
            isDone={state.isPointed}
            inputStyle={inputStyle}
          />
        );
      case "text":
        return <div>{content}</div>;
      case "textarea":
        return (
          <CaseTextArea
            content={content}
            dataIndex={index}
            handleTextAreaChange={handleTextAreaChange}
            resultTextArea={resultTextArea}
          />
        );

      default:
        break;
    }
  };

  return (
    <Container className="fluid">
      <Row>
        <TitleQuestion titleQuestion={titleQuestion} />
      </Row>
      <div
        style={{
          border: "2px solid #1dbfd7",
          borderRadius: 20,
          fontSize: 24,
          padding: 10,
          width: 500,
          ...outterStyle,
        }}
      >
        {data.map((item, index) => {
          const { title, content, type } = item;
          return (
            <div key={index} style={{ marginBottom: 30 }}>
              <div
                style={{
                  backgroundColor: "#5dc9dd",
                  borderRadius: 20,
                  color: "black",
                  paddingLeft: 20,
                  marginBottom: 5,
                  width: "100%",
                  ...innerStyle,
                }}
              >
                <b>{title}</b>
              </div>
              {renderContent(type, content, index)}
            </div>
          );
        })}
      </div>
      <StarModal modal={modal} star={state.star} />
      <FooterModal
        onRetry={onRetry}
        onSubmit={onSubmit}
        // onPlayVideo={onPlayVideo}
        audioUrl={state.audioUrl}
        result={state?.booleanArray}
        exerciseKey={exerciseKey}
        isDisabledRetry={state.isDisabledRetry}
        isDisabledSubmit={state.isDisabledSubmit}
        hideBtnFooter={hideBtnFooter ?? false} //not answer
      />
    </Container>
  );
};

export default TB1;
