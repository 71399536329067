import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Option 7',
		id: 'SB7-O7-P117-E1',
		audio: 'Audios/3-28 Friends Plus 7.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page117/E1/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 9,
		checkDuplicated: true,
		titleQuestion: [
			{
				num: '1',
				title: 'Look at the pictures of three bands.',
				color: '#dd2d38',
			},
		],
		questions: [
			{
				title: `
            <div style='display:flex'>
                <img src='img/FriendsPlus/Page117/E1/1.jpg'/>
                <div style='margin-left: 100px'>
                  What type of music do they play? <br />
                  A: # <br />
                  B: # <br />
                  C: # <br />
                  <br />

                  What instruments can you see? <br />
                  # <br />
                  # <br />
                  # <br />
                  # <br />
                  # <br />
                  # <br />
                </div>
            </div>
            
            <div>
              <p>
            </div>
            `,
				answer: [
					'jazz',
					'pop / rock',
					'folk',
					'guitars / saxophone / drums / violins / clarinet / accordion',
					'guitars / saxophone / drums / violins / clarinet / accordion',
					'guitars / saxophone / drums / violins / clarinet / accordion',
					'guitars / saxophone / drums / violins / clarinet / accordion',
					'guitars / saxophone / drums / violins / clarinet / accordion',
					'guitars / saxophone / drums / violins / clarinet / accordion',
				],
			},
		],
	},

	2: {
		unit: 'Option 7',
		id: 'SB7-O7-P117-E2',
		audio: 'Audios/3-28 Friends Plus 7.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page117/E2/Key/answerKey.png',
		component: T6,
		inputSize: 250,
		maxLength: 6,
		titleQuestion: [
			{
				num: '2',
				title:
					"<audioimage name='3.28'></audioimage> Listen to the song. Which band in the pictures is the song about?",
				color: '#dd2d38',
			},
		],
		questions: [
			{
				title: `
            <div style='display:flex'>
                <img src='img/FriendsPlus/Page117/E1/1.jpg'/>
                <div style='margin-left: -120px'>
                     Which band in the pictures is the song about?<br>#
                </div>
            </div>
            `,
				answer: ['Band B / b'],
			},
		],
	},
	3: {
		unit: 'Option 7',
		id: 'SB7-O7-P117-E3',
		audio: 'Audios/3-28 Friends Plus 7.mp3',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page117/E3/Key/answerKey.png',
		inputSize: 110,
		maxLength: 9,
		stylesTextInput: { fontSize: 18 },
		// checkUppercase: true,
		titleQuestion: [
			{
				num: '3',
				title:
					"<audioimage name='3.28'></audioimage> Look at the song and correct the words in <span style='color: rgba(47, 182, 228, 1)'>blue</span>. Then listen again and check.",
				color: '#dd2d38',
			},
		],
		questions: [
			{
				title: `
          <div style='position: relative'>
            <div><img src='img/FriendsPlus/Page117/E3/1.jpg'/></div>
            <div style='position: absolute; top: 100px; left: 50px; font-size: 1rem'>
              <strong style='color:rgba(70, 138, 204, 1); font-size: 1.5rem'>When will you be famous?</strong> <br /> 
              <div style='font-size: 1rem'></div>
              I need a band with <span style='color: rgba(47, 182, 228, 1)'>piano</span> #, bass <br /> and drums <br />
              Vocals and guitar <br />
              I need confidence and ambition <br />
              Before I can go far. <br />
              I need to <span style='color: rgba(47, 182, 228, 1)'>work</span> # every day <br />
              And write some lyrics for a song <br />
              Find a place we can perform <br />
              And some <span style='color: rgba(47, 182, 228, 1)'>friends</span> # to sing along. <br />
              When are you going to be <span style='color: rgba(47, 182, 228, 1)'>successful</span> # ? <br />
              When will you be a success? <br />
              When you have 10,000 fans <br />
              And you’re better than the rest <br />
              Everybody will really love you <br />
              Your videos will have a <span style='color: rgba(47, 182, 228, 1)'>thousand</span> # views <br />
              When the DJs are playing your music <br />
              And everything you do is in the news. <br />
              If you like rock and pop, <br />
              You’ll love the music we play <br />
              It’s energetic and <span style='color: rgba(47, 182, 228, 1)'>fast</span> #. <br />
              We’re going to a concert on <span style='color: rgba(47, 182, 228, 1)'>Sunday</span> # <br />
              To hand out leaflets to the crowd. <br />
              It’ll be hard work but we can do that <br />
              We’ve got the talent and the <span style='color: rgba(47, 182, 228, 1)'>ambition</span> #. <br />
              We’ll be going to the top <br />
              We’re confident – we will. <br />
              Yes, you’re going to be famous <br />
              Yes, you’ll be a success <br />
              You’ll have 10,000 fans <br />
              And be better than the rest <br />
              Everybody will really love you <br />
              And your videos will have a million views <br />
              The DJs are playing your music <br />
              And you’re always in the news. <br />
            </div>
          </div>
          `,
				answer: [
					'keyboards',
					'practise / practice',
					'fans',
					'famous',
					'million',
					'loud',
					'saturday',
					'skill',
				],
			},
		],
	},
	4: {
		unit: 'Option 7',
		id: 'SB7-O7-P117-E4',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page117/E4/Key/answerKey.png',
		inputSize: 90,
		maxLength: 5,
		titleQuestion: [
			{
				num: '4',
				title:
					'Look at the sentences about the song. Write <i>True</i> or <i>False</i>. Correct the false sentences.',
				color: '#dd2d38',
			},
		],
		questions: [
			{
				title: `
          <div style='display:flex'>
              <img style='width: 80%' src='img/FriendsPlus/Page117/E4/1.jpg'/>
              <ol style='margin-left: 20px'>
                 <li>She needs talent and ambition to succeed. #</li>  
                 <li>She should write the music for a song. #</li>  
                 <li>They’ll have 10,000 fans when they’re
                 famous. #</li>  
                 <li>The fans are playing their music. #</li>  
                 <li>They’re confident they’ll be famous. #</li>
              </ol>
          </div>
          `,
				answer: ['false', 'false', 'true', 'false', 'true'],
			},
		],
	},

	5: {
		unit: 'Unit 7',
		id: 'SB7-U7-P117-E5',
		audio: '',
		video: '',
		component: T6,
		inputSize: 550,
		maxLength: 100,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

		titleQuestion: [
			{
				num: '5',
				title: 'Work in pairs. Answer the questions.',
				color: '#dd2d38',
				prefix: 'default',
			},
		],
		questions: [
			{
				title: `
            <div style="margin-top: 20px; width: 550px;">
              <ol>
                <li>Have you got confidence and ambition?</li>
                <li>What does a band need to get to the top?</li>
                <li>Would you like to be famous? Why / Why not?</li>
              </ol>

              <div style="margin-top: 20px;">
                <strong>Answer:</strong> <br/>
                # <br/> # <br/> # <br/> # <br/> # <br/> 
              </div>
            </div>
          `,
				answer: [],
			},
		],
	},
};

export default json;
