import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  12: {
    unit: "Unit 4",
    id: "SB7-U4-P57-E12",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 250,
    exerciseKey: "img/FriendsPlus/Page57/E12/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "12",
        title: "Write past continuous questions.",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
              <div style='margin-top :5mm'><b>1&emsp;</b>  #(your dad / smile)	in the holiday photo?</div>
              <div style='margin-top :5mm'><b>2&emsp;</b> Where #(they / go)	when I saw them yesterday?</div>
              <div style='margin-top :5mm'><b>3&emsp;</b>  #(it / rain) on the	first day of the trip?</div>
              <div style='margin-top :5mm'><b>4&emsp;</b>  #(we / visit) Rome	this time last week?</div>
              <div style='margin-top :5mm'><b>5&emsp;</b> Who #(you / sit)	next to on the plane?</div>
              <div style='margin-top :5mm'><b>6&emsp;</b> What #(she / say)	about the trip earlier?</div>
							`,
        answer: [
          "Was your dad smiling",
          "were they going ",
          "Was it raining",
          "Were we visiting ",
          "were you sitting",
          "was she saying",
        ],
      },
    ],
  },
  13: {
    unit: "Unit 4",
    id: "SB7-U4-P57-E13",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 180,
    exerciseKey: "img/FriendsPlus/Page57/E13/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "13",
        title:
          "Complete the sentences using the past	continuous or past simple form of the verbs.",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
								
										<div style='margin-top :5mm'><b>1.</b>  We #(eat) in a restaurant in Hollywood when we #(see) that famous film star</div>
										<div style='margin-top :5mm'><b>2.</b> Hoa #(lose) her camera while she #(travel) in South America.</div>
										<div style='margin-top :5mm'><b>3.</b>  It #(snow) when they #(arrive) in Paris.</div>
										<div style='margin-top :5mm'><b>4.</b> They #(stop) in Oxford while they #(drive) across the UK.</div>
										<div style='margin-top :5mm'><b>5.</b> He #(walk) through the museum when he #(meet) an old friend.</div>
										<div style='margin-top :5mm'><b>6.</b> You #(take) a wonderful photo while we #(stay) in Egypt</div>
								`,
        answer: [
          "were eating", "saw ",
          "lost", "was travelling ",
          "was snowing", "arrived ",
          "stopped", "were driving ",
          "was walking", "met ",
          "took", "were staying",
        ],
      },
    ],
  },
  14: {
    unit: "Unit 4",
    id: "SB7-U4-P57-E14",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 400,
    maxLength: 50,
    exerciseKey: "img/FriendsPlus/Page57/E14/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "14",
        title: "Complete the dialogue then practise with your partner. ",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='width:80%; margin: 10px 0 20px 0; border-radius: 30px; border: 2px solid  rgb(77 60 151)'>
              <div style='text-align:center'>That’s really kind of you.<br>What do you think of it?<br>I was standing on the beach<br>I’m impressed.<br>But what were those guys doing?	</div>
          </div>  
					<div style='display: flex;'>
					<div><b>Paul</b></div>
					<div style='margin-left: 51px;'>Did you take this photo, Vicky?</div>
				</div>
				<div style='display: flex;'>
					<div><b>Vicky</b></div>
					<div style='margin-left: 40px;'>Yes. <sup>1</sup>#.</div>
				</div>
				<div style='display: flex;'>
					<div><b>Paul</b></div>
					<div style='margin-left: 51px;'>It’s a fantastic photo.  <sup>2</sup>#</div>
				</div>
				<div style='display: flex;'>
					<div><b>Vicky</b></div>
					<div style='margin-left: 40px;'>They were jumping to the sea from a big rock.</div>
				</div>
				<div style='display: flex;'>
					<div><b>Paul</b></div>
					<div style='margin-left: 51px;'> Really? Where were you when you took it?</div>
				</div>
				
				<div style='display: flex;'>
					<div><b>Vicky</b></div>
					<div style='margin-left: 42px;'><sup>3</sup>#</div>
				</div>
				<div style='display: flex;'>
					<div><b>Paul</b></div>
					<div style='margin-left: 53px;'>Did you have a go?</div>
				</div>
				<div style='display: flex;'>
					<div><b>Vicky</b></div>
					<div style='margin-left: 42px;'>You’re kidding!</div>
				</div>
				<div style='display: flex;'>
					<div><b>Paul</b></div>
					<div style='margin-left: 53px;'>Well, it’s an amazing photo <sup>4</sup>#</div>
				</div>
				<div style='display: flex;'>
					<div><b>Vicky</b></div>
					<div style='margin-left: 42px;'><sup>4</sup>#. Thanks</div>
				</div>
          `,
        answer: [
          "What do you think of it?",
          "But what were those guys doing?",
          "I was standing on the beach.",
          "I'm impressed.",
          "That's really kind of you.",
        ],
      },
    ],
  },
  15: {
    unit: "Unit 4",
    id: "SB7-U4-P57-E15",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center", textTransform: 'uppercase' },
    inputSize: 40,
    maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page57/E15/Key/answerKey.png",
    titleQuestion: [
      {
        num: "15",
        title: "Put the paragraphs in the suitable order.",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
				<div style='display: flex;'>
							<table style='margin-inline: 30px; width: 500px ; border: 2px solid  rgb(77 60 151); font-size: 20px '>
           		 	<tr style=' background-color: rgb(184 174 210);border: 2px solid  rgb(77 60 151)'>
									<td style='padding: 10px '><b> A</b> <br>	After a few minutes, the horse started to eat	some food.</td>
								</tr>
								<tr style=' background-color: rgb(228 227 241);border: 2px solid  rgb(77 60 151)'>
									<td style='padding: 10px '><b>B	</b> <br>As soon as possible, some rescuers came and	helped the horse out of the river.</td>
            		</tr>
            		<tr style=' background-color: rgb(184 174 210);border: 2px solid  rgb(77 60 151)'>
              		<td style='padding: 10px '><b> C</b> <br>I took this interesting photo while I was	staying with my aunt last April. It was an	amazing rescue.</td>
            		</tr>
            		<tr style=' background-color: rgb(228 227 241);border: 2px solid  rgb(77 60 151)'>
              		<td style='padding: 10px '><b>D	</b> <br>They were looking at something in the water.	It was a horse. It wasn’t moving much, but it	was alive. It was standing in the river and it	couldn’t get out.</td>
            		</tr>
            		<tr style=' background-color: rgb(184 174 210);border: 2px solid  rgb(77 60 151)'>
									<td style='padding: 10px '><b>E </b> <br>It happened while we were visiting a small village. We were walking along near the river when we saw a group of people.</td>
            		</tr>
							</table>
              <div>
                <div style='margin-top :5mm'><b>1.</b>#</div>
                <div style='margin-top :5mm'><b>2.</b>#</div>
                <div style='margin-top :5mm'><b>3.</b>#</div>
                <div style='margin-top :5mm'><b>4.</b>#</div>
                <div style='margin-top :5mm'><b>5.</b>#</div>
              </div>
				<div>
								`,
        answer: ["c", "e", "d", "b", "a"],
      },
    ],
  },
};

export default json;
