import TB2 from "../../components/ExcerciseTypes/Table/TB2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB7-U2-P28-E1",
    audio: "Audios/1-24 Friends Plus 7.mp3",
    video: "",
    component: TB2,
    titleQuestion: [
      {
        num: "1",
        title:
          "<audioimage name='1.24'></audioimage> Read and listen to the text and complete the table.",
        color: "#1dbfd7",
      },
    ],
    questions: [],
    tb2Style: { width: 700 },
    exerciseKey: "img/FriendsPlus/Page28/E1/Key/answerKey.png",
    type: 1,
    data: [
      {
        title: "Rank in Asia",
        content: ["1", "#", "#"],
        answers: ["2", "3"],
        commonStyle: {
          background: "#b7e1df",
          borderColor: "#459fb2",
          fontSize: 22,
          color: "black",
        },
        titleStyle: {
          color: "white",
          borderColor: "#01a8b8",
          background: "#01a8b8",
        },
        contentStyle: { fontSize: 25 },
      },
      {
        title: "EPI score",
        content: ["#", "#", "473"],
        answers: ["611", "562"],
        commonStyle: {
          background: "#b7e1df",
          borderColor: "#3f9bae",
          fontSize: 22,
          color: "black",
        },
        titleStyle: {
          color: "white",
          borderColor: "#459fb2",
          background: "#01a8b8",
        },
        contentStyle: { fontSize: 25 },
      },
      {
        title: "Countries",
        content: ["#", "the Philippines", "#"],
        answers: ["Singapore", "Việt Nam"],
        commonStyle: {
          background: "#b7e1df",
          borderColor: "#3f9bae",
          fontSize: 22,
          color: "black",
        },
        titleStyle: {
          color: "white",
          borderColor: "#459fb2",
          background: "#01a8b8",
        },
        contentStyle: { fontSize: 25 },
      },
    ],
  },

  2: {
    unit: "Unit 2",
    id: "SB7-U2-P28-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { textAlign: "center" },
    inputSize: 100,
    maxLength: 5,
    exerciseKey: "img/FriendsPlus/Page28/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: "Read the text again and write <i>True</i> or <i>False</i>.",
        color: "#459fb2",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='width: 25cm'>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:20px'>
              More than a billion people speak English in the world.#
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>
              EF produced the first EPI in 2020.#
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:20px'>
              Recently, the EPI score of Việt Nam is higher than that of the Philippines.#
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:20px'>
              With an EPI score of 611, Singapore ranks
              the first in the world.#
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b>
            <div style='margin-left:20px'>
              More and more Vietnamese people enjoy
              learning English.#
            </div>
          </div>
        </div>
        `,
        answer: ["True", "False", "False", "False", "True"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB7-U2-P28-E3",
    audio: "",
    video: "",
    component: T6,
    inputSize: 600,
    hideBtnFooter: true,
    recorder: true,
    // checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page32/E10/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Work in groups. Ask and answer <br/>the questions with a partner.",
        color: "#1dbfd7",
        prefix: { icons: ["far fa-comment"], text: "YOUR CULTURE" },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
					<b>1</b>&emsp;When do you often speak English?<br>
					<b>2</b>&emsp;Do you speak English with your parents?<br>
					<b>3</b>&emsp;What do you use English for?<br>
					<b>4</b>&emsp;How well do you think you speak English?<br>
					<div>
            <b>5</b>&emsp;Do you intend to study abroad? Where?<br/>
            &emsp;&ensp;Do people there speak English?
          </div>        
      `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB7-U1-P28-E4",
    audio: "",
    video: "",
    component: T6,
    recorder: true,
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: "4",
        title:
          " Use your answers from exercise 3 to write a text (60–80 words) describing your use of english in daily life.",
        color: "#1dbfd7",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            
            <div>
                <b>1</b>&emsp;When do you often speak English?<br>
					      <b>2</b>&emsp;Do you speak English with your parents?<br>
					      <b>3</b>&emsp;What do you use English for?<br>
					      <b>4</b>&emsp;How well do you think you speak English?<br>
					      <div style='display: flex;width: 14cm'>
                  <b>5</b>
                  <div style='margin-left:24px'> Do you intend to study abroad? Where?
                  Do people there speak English?
                  </div>
                </div> 
            </div>
            <div><textarea id="0" rows="7" ></textarea><div>
          </div>
        `,
        answer: [
          "spending my time",
          "a lot of time",
          "also",
          "too",
          "don't enjoy",
          "really bothered",
          "about watching",
          "not a big",
        ],
      },
    ],
  },
};

export default json;
