import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	2: {
		unit: 'I Believe I Can Fly',
		id: 'SB7-I-P93-E2',
		exerciseKey: 'img/FriendsPlus/Page93/E2/Key/answerKey.png',
		audio: 'Audios/2-24 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 180,
		maxLength: 16,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
          <audioimage name='2.24'></audioimage>
          Find the name for each means of transport. Listen and check.
        `,
				color: '#006ac0',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div>
							<strong>Means of transport</strong>

							<div style="margin-top: 5px; display: flex;">
								<img src="img/FriendsPlus/Page92/E1/1.jpg" style="max-width: 100%;" />
								<img src="img/FriendsPlus/Page92/E1/2.jpg" style="max-width: 100%;" />
								<img src="img/FriendsPlus/Page92/E1/3.jpg" style="max-width: 100%;" />
							</div>
			
							<div style="display: flex;">
								<img src="img/FriendsPlus/Page92/E1/4.jpg" style="max-width: 100%;" />
								<img src="img/FriendsPlus/Page92/E1/5.jpg" style="max-width: 100%;" />
								<img src="img/FriendsPlus/Page92/E1/6.jpg" style="max-width: 100%;" />
							</div>
						</div>
					
						<div style="margin-top: 10px;">
							<strong>Answers:</strong> <br/>

							<ol type="A"">
								<li>#</li>
								<li>#</li>
								<li>#</li>
								<li>#</li>
								<li>#</li>
								<li>#</li>
							</ol>
						</div>
                        
					</div>
                `,
				answer: [
					'flying motorbike',
					'self-driving car',
					'flying car',
					'jetpack',
					'tunnel bus',
					'amphibious bus',
				],
			},
		],
	},

	3: {
		unit: 'I Believe I Can Fly',
		id: 'SB7-I-P93-E3',
		exerciseKey: 'img/FriendsPlus/Page93/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 160,
		maxLength: 20,
		titleQuestion: [
			{
				num: '3',
				title: `
          Read the description from exercise again. Choose the word in <span style="color: #29b2df">blue</span> <br/>with the correct definition.
        `,
				color: '#006ac0',
			},
		],

		questions: [
			{
				title: `
				<div>
						<img src="img/FriendsPlus/Page93/E3/1.jpg" style="width: 800px;" />

					<div style="margin-top: 20px; display: flex; ">
						<ol>
							<li>without drivers</li>
							<li>moving quickly</li>
							<li>suitable for your needs</li>
							<li>very large</li>
							<li>saving money</li>
							<li>extremely good</li>
						</ol>

						<div style="margin-left: 20px;margin-top: -5px;">#<br/> #<br/> #<br/> #<br/> #<br/> #<br/></div>
					</div>		
				  
				</div>
				`,

				answer: [
					'self-driving',
					'fast',
					'convenient',
					'spacious',
					'economical',
					'wonderful',
				],
			},
		],
	},

	4: {
		unit: 'I Believe I Can Fly',
		id: 'SB7-I-P93-E4',
		exerciseKey: 'img/FriendsPlus/Page93/E4/Key/answerKey.png',
		audio: 'Audios/2-25 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 130,
		maxLength: 10,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 620 },
		titleQuestion: [
			{
				num: '4',
				title: `
          <audioimage name='2.25'></audioimage>
          Listen to the dialogue. 
          What future means of transport are they talking about?
        `,
				color: '#006ac0',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; width: 600px;">
						Answer: #
					</div>
				`,
				answer: ['Flying car'],
			},
		],
	},

	5: {
		unit: 'I Believe I Can Fly',
		id: 'SB7-I-P93-E5',
		exerciseKey: 'img/FriendsPlus/Page93/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 620 },
		textareaStyle: { width: 740 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          Work in pairs. Talk about a future means of transport. Use the key phrases.
        `,
				color: '#006ac0',
				prefix: 'default',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px;">
            <div style="margin-top: 20px; width: 450px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgb(216,224,243);">
              <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(108,159,214); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
                Key Phrases
              </div>
              
              <div style="margin-top: 10px; padding: 0 20px">
                <div style="color: rgb(108,159,214); font-weight: bold; font-size: 1.6rem">
                  Talking about future vehicles
                </div>
            
                <div>
                  They say that …<br/>
                  I believe I can …<br/>
                  I think I can …<br/>
                  I can save enough money to afford …
                </div>
              </div>
            </div>
            <textarea id="8" rows="5"></textarea>
					</div>
				`,
				answer: [],
			},
		],
	},
	6: {
		unit: 'I Believe I Can Fly',
		id: 'SB7-I-P93-E6',
		exerciseKey: 'img/FriendsPlus/Page93/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 620 },
		finished: {
			text: `
        Write a paragraph (60 -80 words) describing your trip to the moon. 
        (How do you go? How do you feel? Do you like it? …)
      `,
		},
		textareaStyle: { width: 740 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
              <finished></finished>
            <textarea id="8" rows="5"></textarea>
				`,
				answer: [],
			},
		],
	},
};

export default json;
