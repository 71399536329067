import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB7-U2-P30-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page30/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "<b>Choose the best answer.</b>",
        color: "#4d3c97",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          fontSize: 30,
          margin: "0 50px",
        },
        selectWordStyle: {
          padding: 3,
          border: "solid 2px",
          borderColor: "#1dbfd7",
        },
        limitSelect: 1,
        listWords: [
          "a._on | b._in | c._under", //0
          "a._in | b._at | c._next_to", //1
          "a._at | b._in | c._on", //2
          "a._in | b._between | c._on", //3
          "a._on | b._with | c._at", //4
          "a._at | b._above | c._in", //5
        ],
        answers: ["0-6", "1-0", "2-12", "3-0", "4-12", "5-12"],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div><b>1</b> 'Where is Gemma?' 'She’s the ..... playground with her sister.'</div>
        <div><input id='0' type='Circle' /></div>
        <div><b>2</b> 'Where is Quang?' 'He’s ..... bed upstairs.'</div>
        <div><input id='1' type='Circle' /></div> 
        <div><b>3</b> 'Hello. Is your mum there?' 'Yes, she’s here but she’s ..... the phone to her sister.'</div>
        <div><input id='2' type='Circle' /></div> 
        <div><b>4</b> 'Has your brother got his friends ..... his room?' 'No, they aren’t here now.'</div>
        <div><input id='3' type='Circle' /></div> 
        <div><b>5</b> 'Dad is late? Where is he?' 'He’s ..... the shops in town.'</div>
        <div><input id='4' type='Circle' /></div> 
        <div><b>6</b> 'Where’s your home?' 'It’s the ..... countryside about 10 km from here.'</div>
        <div><input id='5' type='Circle' /></div> 
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "SB7-U2-P30-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page30/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: "<b>Choose the correct words.</b>",
        color: "#4d3c97",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 3,
          border: "solid 2px",
          borderColor: "#1dbfd7",
        },
        limitSelect: 1,
        listWords: [
          "ban / let / allow", //0
          "allows / bans / lets", //1
          "lets / bans / allows", //2
          "let / allow / ban", //3
          "lets / bans / allows", //4
        ],
        answers: ["0-4", "1-0", "2-4", "3-4", "4-0"],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div><b>1</b> My mum and dad only <input id='0' type='Circle' /> me play video games at the weekend. It’s not fair!</div>
        <div><b>2</b> Our dad <input id='1' type='Circle' />  us to watch TV in the evening.</div> 
        <div><b>3</b> My mum <input id='2' type='Circle' /> mobile phones at the dinner table. She hates them!</div> 
        <div><b>4</b> Our parents don’t <input id='3' type='Circle' /> TVs in our rooms. We watch TV together downstairs.</div> 
        <div><b>5</b> My sister <input id='4' type='Circle' /> me use her computer. She’s very kind. I really like her!</div> 
      `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "SB7-U2-P30-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 200,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page30/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "<b>Complete the sentences using the affirmative (✔) or negative (✘) form of the present simple.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page30/E3/1.jpg' />
          <div style='margin-top: 20px;'>
            <div><b>1</b> Sam # video games.</div>
            <div><b>2</b> Nick and Lucy # video games at home.</div>
            <div><b>3</b> Sam # German at school.</div>
            <div><b>4</b> Nick and Lucy # German.</div>
            <div><b>5</b> Sam # his homework.</div>
            <div><b>6</b> Nick and Lucy # their homework every night.</div>
          </div>
        `,
        answer: [
          "doesn't play",
          "play",
          "studies",
          "don't study",
          "finishes",
          "don't finish",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB7-U2-P30-E4",
    audio: "Audios/1-25 Friends Plus 7.mp3",
    video: "",
    component: T6,
    selectStyle: {
      color: "#4d3c97",
      width: 70,
      textAlign: "center",
      fontSize: 26,
      fontWeight: "bold",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✔", "✘"],
    exerciseKey: "img/FriendsPlus/Page30/E4/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='1.25'></audioimage><b>Listen and put a tick (✔) next to Eva’s hobbies.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div><u>Eva’s hobbies</u></div>
          <div>I really enjoy …</div>
          <div style='display: flex;'><b>1</b>&nbsp;<select id='0'></select>&nbsp;doing a lot of sport.</div>
          <div style='display: flex;'><b>2</b>&nbsp;<select id='1'></select>&nbsp;staying in bed very late.</div>
          <div style='display: flex;'><b>3</b>&nbsp;<select id='2'></select>&nbsp;blogging.</div>
          <div style='display: flex;'><b>4</b>&nbsp;<select id='3'></select>&nbsp;baking cakes with my friends.</div>
          <div style='display: flex;'><b>5</b>&nbsp;<select id='4'></select>&nbsp;collecting different things.</div>
          <div style='display: flex;'><b>6</b>&nbsp;<select id='5'></select>&nbsp;going to the cinema in town.</div>
          <div style='display: flex;'><b>7</b>&nbsp;<select id='6'></select>&nbsp;going dancing with my sister.</div>
          <div style='display: flex;'><b>8</b>&nbsp;<select id='7'></select>&nbsp;listening to music in bed.</div>
        `,
        answer: ["✓", "✗", "✓", "✓", "✗", "✓", "✗", "✓"],
      },
    ],
  },
};

export default json;
