import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB7-U3-P41-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 1000,
    exerciseKey: "img/FriendsPlus/Page41/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the text. Why did Jack's parents have a party? Who did not enjoy the party?",
        color: "#00a950",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img style="width: 74%" src='img/FriendsPlus/Page41/E1/1.jpg' />
          <div><b>1</b> Why did Jack's parents have a party?</div>
          <div>#</div>
          <div><b>2</b> Who did not enjoy the party?</div>
          <div>#</div>
        `,
        answer: [
          "They had a party because Jack's sister and her boyfriend decided to get married.",
          "The dog didn't enjoy the party.",
        ],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB7-U3-P41-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page41/E2/Key/answerKey.png",
    inputSize: 180,
    maxLength: 36,
    stylesTextInput: {
      borderBottom: "1px dotted",
      textAlign: "center"
    },
    titleQuestion: [
      {
        num: "2",
        title: "Complete the Key Phrases with words from the text.",
        color: "#12a15f",
      },
    ],
    questionImage: [],
    checkUppercase: true,
    questions: [
      {
        title: `
        <div><img style="width: 80%" src='img/FriendsPlus/Page41/E1/1.jpg' /><div>
        <div style='background: rgb(207 232 211); border-radius: 15px; max-width: 550px'>
          <div style='color:white;border-radius:15px;background:rgb(18 161 95);padding:10px; font-size: 27px'><b>KEY PHRASES</b></div>
          <div style='margin-right: 10px; padding: 10px 10px 20px'>
            <div style="margin-right: 10px; color: rgb(0, 168, 78); font-weight: bold">Describing an event</div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>I will never forget <sup>1</sup> <input id='0'/> .</div></div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>There were about <sup>2</sup> <input id='1'/> people.</div></div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'><sup>3</sup> <input id='2'/> had a good time.</div></div>
            <div style='display: flex;'><div style='margin-right: 10px; color: black'>It was a great <sup>4</sup> <input id='3'/> .</div></div>
          </div>
        </div>
          `,
        answer: [
          "last July ",
          "twenty",
          "We all ",
          "evening",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 3",
    id: "SB7-U3-P41-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page41/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Read the text again and find the sequencing words",
        color: "#00a950",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          border: "none",
          borderColor: "transparent",
          fontSize: 24
        },
        selectWordStyle: {
          padding: 3,
          border: "2px solid",
          borderColor: "#00a950",
        },
        limitSelect: 4,
        listWords: [
          "I'll | never | forget | last | July. | That's | when | my | big | sister | and | her | boyfriend | decided | to | get | married, | and | my | parents | had | a | fantastic | party | at | our | house | to | celebrate. <br/> First, | my | mum | and | I | went | shopping | for | food | and | drink. | At | six | o'clock, | my | sister's | friends | arrived | for | the | party. | At | seven | o'clock, | my | dad | started | the | barbecue | in | the | garden | and | after_that | we | cooked | potatoes | and | burgers. | It | was | a | very | warm | night. | We | ate | the | food | and | then | Dad | started | the | firework | display | – | that | was | a | great | surprise. | Finally, | at | about | eleven | o'clock, | everyone | went | home. <br/> There | were | about | twenty | people | and | we | all | had | a | good | time. | Only | our | dog | didn't | enjoy | the | party. | He | stayed | in | the | house | – | he | didn't | like | the | noise | of | the | fireworks! | It | was | a | great | evening | and | I | was | really | happy.", //2
        ],
        answers: ['0-172', '0-370', '0-472', '0-544'],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div style='border-width: 2px; border-style: solid; border-radius: 8px; border-color: rgb(0,169,80); text-align: center; padding: 0 10px; margin-top: 20px; width: 70%;'>
          first&emsp;&emsp;after that&emsp;&emsp;then&emsp;&emsp;finally
        </div>  
        <div style='margin-top: 20px;'>
          <div style='margin-top: 20px;'>
            <input id='0' type='Circle' />
          </div>
        </div>
      `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "SB7-U3-P41-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page41/E4/Key/answerKey.png",
    inputSize: 40,
    maxLength: 1,
    stylesTextInput: {
      border: "none",
      height: 30,
    },
    textAlign: "Left",
    titleQuestion: [
      {
        num: "4",
        title:
          "Look at the sequencing words in bold and put sentences A–E in the correct order. Which word describes the beginning of an event, and which word describes the end?",
        color: "#00a950",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <img src='img/FriendsPlus/Page41/E4/1.jpg' />
            <div style=" position: absolute; top: 179px; left: 704px; "><input id='0' /></div>
            <div style=" position: absolute; top: 221px; left: 704px; "><input id='1' /></div>
            <div style=" position: absolute; top: 263px; left: 704px; "><input id='2' /></div>
            <div style=" position: absolute; top: 305px; left: 704px; "><input id='3' /></div>
            <div style=" position: absolute; top: 347px; left: 704px; "><input id='4' /></div>
          </div>
        `,
        answer: ["C", "A", "D", "E", "B"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB7-U3-P41-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    titleQuestion: [
      {
        num: "5",
        title:
          "Follow the steps in the Writing Guide. Ask and answer the questions for part B with your partner.",
        color: "#00a950",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    textareaStyle: { width: 1000 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='text-align: center;'>
            <img style="width: 44%" src='img/FriendsPlus/Page41/E5/2.jpg' />
            <div><textarea id="0" rows="7" ></textarea></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
